<template>
  <!--加载组件，加载页面 -->
  <div :style="st" class="page-loader-wrapper prompt ">
    <div class="loader">
      <div class="Kan-box"><img src="../../assets/images/Kangaroo.svg" height="95" alt="SmartOM"></div>
      <h1><i class="fad fa-spinner-third fa-spin fa-5x"></i></h1>
      <p>{{$t('loading.pleaseWait')}}</p>
    </div>
  </div>
</template>
<script>
export default {
  data(){
    return{
      otimer:null,
      isload:false,
      st:{opacity:1}
    }
  },
  mounted(){
    this.init();
  },
  methods:{
    init(){
     
        this.otimer=setInterval(() => {
        this.st.opacity +=-0.1 ;
        if(this.st.opacity<0){
          this.st={"display": "none"};
          clearInterval(this.otimer);//停止
        }
         
      }, 100);
    }
  }
 
}
</script>
<style  scoped>
.prompt {
  transition: all 1s linear;
}
</style>