<template>
  <!-- 天气+快捷菜单组件 -->
  <nav id="navbar-content" class="navbar navbar-fixed-top" :class="{'nav-content-bigger':$store.state.mainBig}">
    <!-- 默认小菜单时候增加样式 nav-content-bigger -->
    <div class="container-fluid">
      <div class="navbar-left w-50">

        <!-- LOGO -->
        <div class="navbar-logo">
          <a href="home"><img src="../../assets/images/SmartOM.svg" alt="SmartOM Logo" class="img-fluid logo" /></a>
        </div>

        <!-- <div class="navbar-btn"><button type="button" class="btn-toggle-offcanvas"><i class="fal fa-bars"></i></button></div> -->

        <div class="weather" v-if="weatherFlag">
          <span>{{weather.cityName}} </span>
          <!-- 晴 -->
          <span v-show="weather.weatherCode == 1" class="fa-layers fa-fw fa-weather m-b--2 mr-1">
            <i class="fad fa-sun text-tomato"></i>
          </span>
          <!-- 多云 -->
          <span v-show="weather.weatherCode == 3" class="fa-layers fa-fw fa-weather mr-3">
            <i class="fad fa-sun text-tomato"></i>
            <i class="fad fa-smoke text-white" data-fa-transform="shrink-1.5 right-9.3"></i>
          </span>
          <!-- 雨 -->
          <span v-show="weather.weatherCode == 2" class="fa-layers fa-fw fa-weather mr-2">
            <i class="fad fa-smoke text-darkclouds" data-fa-transform="shrink-1.2 up-2.8"></i>
            <i class="fad fa-raindrops text-sea" data-fa-transform="shrink-8 down-6.5"></i>
          </span>
          <!-- 雪 -->
          <span v-show="weather.weatherCode == 4" class="fa-layers fa-fw fa-weather mr-2">
            <i class="fad fa-smoke text-darkclouds" data-fa-transform="shrink-1.2 up-2.8"></i>
            <i class="fad fa-snowflakes fa-flip-horizontal text-sea" data-fa-transform="shrink-7.5 down-6.2"></i>
          </span>
          <span class="weather-text">{{$t('weather.'+weather.weatherCode)}}<i class="fad fa-thermometer-half ml-2 mr-1"></i> <span class="oc">{{weather.temperature}}</span><i class="fad fa-wind ml-3 mr-1"></i><span class="m-per">{{ weather.windVelocity }}</span>
          </span>
          <!-- <span class="oc" >{{ weather.lowTemp }}</span>~<span class="oc">{{ weather.highTemp }}</span> -->
        </div>
      </div>
      <div class="navbar-right">
        <div id="navbar-menu">
          <ul class="nav navbar-nav">
            <!-- <li><a href="javascript:void(0);" class="right_toggle icon-menu" @click="setParent()" title="Right Menu"><i class="fad fa-search"></i></a></li> -->
            <!-- <li class="dropdown">
              <a href="javascript:void(0);" class="dropdown-toggle icon-menu" data-toggle="dropdown">
                <i class="fad fa-bell-on"></i>
                <span class="notification-dot info">3</span>             
              </a>
              <ul class="dropdown-menu feeds_widget mt-0 animation-li-delay">
                <li class="header theme-bg gradient mt-0 text-light">您有3条未读消息
                </li>
                <li>
                  <a href="javascript:void(0);">
                    <div class="mr-3"><i class="fad fa-coin text-success"></i></div>
                    <div class="feeds-body">
                      <h4 class="title text-success">昨日收益报告
                        <small class="float-right text-muted font-12">9:22</small>
                      </h4>
                      <small>昨日收益报告已出</small>
                    </div>
                  </a>
                </li>
                <li class="divider"></li>
                <li>
                  <a href="javascript:void(0);">
                    <div class="mr-3"><i class="fad fa-check text-red"></i></div>
                    <div class="feeds-body">
                      <h4 class="title text-danger">系统更新完毕
                        <small class="float-right text-muted font-12">9:10</small>
                      </h4>
                      <small>2021年10月13日已完成更新
                      </small>
                    </div>
                  </a>
                </li>
                <li>
                  <a href="javascript:void(0);">
                    <div class="mr-3"><i class="fad fa-alarm-exclamation text-warning"></i></div>
                    <div class="feeds-body">
                      <h4 class="title text-warning">系统维护通告
                        <small class="float-right text-muted font-12">9:17</small>
                      </h4>
                      <small>系统将于2021年10月15日23:00进行维护 预计用时30分钟</small>
                    </div>
                  </a>
                </li>
              </ul>
            </li> -->
            <!-- <li class="user-account">
              <span>{{ $t("sidebar.hello") }}，{{ userInfo.userName }}</span>
            </li> -->
           <el-button type="aurora" class="is-active" size="small" @click="NewWebsiteClick()">{{ $t('navs.website') }}</el-button>
            <li class="dropdown language-menu hidden-xs">
              <a href="javascript:void(0);" class="dropdown-toggle icon-menu" data-toggle="dropdown"><i class="fad fa-language"></i></a>
              <div class="dropdown-menu language_widget">
                <a class="dropdown-item" @click="changeLocale('ja')" href="javascript:void(0);"><img src="../../assets/images/flag/Japanese.svg" alt="日本語" />日本語</a>
                <a class="dropdown-item" @click="changeLocale('zh_CN')" href="javascript:void(0);"><img src="../../assets/images/flag/Chinese.svg" alt="中文" />中文</a>
                <a class="dropdown-item" @click="changeLocale('en')" href="javascript:void(0);"><img src="../../assets/images/flag/English.svg" alt="English" />English</a>
              </div>
            </li>
            <!-- <li class="user-account">
              <div class="dropdown">
                <a href="javascript:void(0);" class="dropdown-toggle icon-menu" data-toggle="dropdown">Mr. Kangaroo</a>
                <ul class="dropdown-menu mt-0">
                  <li><a href=""><i class="fad fa-cog"></i> 设置</a></li>
                  <li class="divider"></li>
                  <li><a href="login"><i class="fad fa-sign-out"></i> 退出登录</a></li>
                </ul>
              </div>
              <div class="user_div">
                <img src="../../assets/images/user.png" class="user-photo" alt="User Profile Picture">
              </div>
            </li> -->
            <!-- <li class="hidden-xs"><a href="javascript:void(0);" id="btnFullscreen" class="icon-menu"><i class="fad fa-expand-wide"></i></a></li> -->
            <li>
              <el-tooltip class="item" :content="$t('navs.logout')" placement="bottom" effect="light">
                <a @click="signOut" href="javascript:void(0)" class="icon-menu">
                  <i class="fad fa-sign-out"></i>
                </a>
              </el-tooltip>
            </li>
            <li>
              <!-- 移动端左菜单触发按钮 -->
              <div class="mobile-button" @click="MobilMenu()"><span></span></div>
            </li>
          </ul>

        </div>
      </div>
    </div>
  </nav>
</template>
<script>
import { setCookie, delCookie } from "../../utils/cookie";
import { getTitle } from "../../utils/locale";
// import WeatherState from "../Master/WeatherState.vue";
export default {
  components: {
    //WeatherState
  },
  data () {
    return {
      userInfo: {
        userName: ""
      },
      weather: {
        highTemp: null, //最高温度
        lowTemp: null, //最低温度
        humidity: null, //湿度
        totalrd: null, //日射量
        weatherCode: null, //天气编号
        weatherName: null, //天气名称
        temperature: null, //温度
        windVelocity: null, //风速
        windDirection: null //风向
      },
      weatherState: 0,
      weatherFlag: true
    };
  },
  created () {
    this.getWeather();
    this.weatherFlag = false;
  },
  methods: {
    NewWebsiteClick:function(){
       let url = "https://beta.smartom.com/autoLogin?userId="+ window.sessionStorage.getItem("userId") + "&userToken="+window.sessionStorage.getItem("token");
       window.open(url)
    },
    MobilMenu:function(){
        $('.sidebar').toggleClass('MenuOpen');
        $('.navbar-logo').toggleClass('Hide');
        $('.mobile-button').toggleClass('active');
        $('.sidebar-mini-btn').toggleClass('Hide');      
    },
    changeLocale: function (locale) {
      this.$i18n.locale = locale;
      this.$validator.locale = locale;
      this.$store.state.languageId=locale;

      setCookie("locale", locale);
      $("html").attr("lang", locale);
      document.title=getTitle(locale,this.$router.currentRoute.meta.title);
     
    },
    setParent: function () {
      this.$emit("changeParent", true);
    },

    signOut: function () {
      let sigoOutVue = this;
      this.$http
        .get(
          "/service-user/user/logout?userId=" +
          window.sessionStorage.getItem("userId"),
          {}
        )
        .then(function (ret) {
          let data = ret.data;
          if (data.status == 200) {
            window.sessionStorage.removeItem("token");
            window.sessionStorage.removeItem("userId");
            window.sessionStorage.removeItem("selPsId");
            
            delCookie("token");
            delCookie("userId");
            sigoOutVue.$router.push("/login");
           
          } else {
            swal(data.msg, "请返回重试", "warning");
          }
        })
        .catch(function () { });
    },
    getWeather: function () {
       var _this = this;
      if(_this.$store.state.psId != undefined && _this.$store.state.psId != null && _this.$store.state.psId != ""){
            this.weatherState = -1;
            this.$http
              .get(
                "/service-psinfo/psdata/getWeatherByPsId?psId=" +
                _this.$store.state.psId,
                {}
              )
            .then(function (ret) {
              let data = ret.data;
              if (data.status == 200) {
                _this.weather = ret.data.data;
                _this.weatherFlag = true;
              } else {
                _this.weatherFlag = false;
              }
            })
            .catch(function () {
              _this.weatherFlag = false;
            }); 
      }else{
         _this.weatherFlag = false;
      }
    },
    removePotion: function (ret) {
      if (ret == null) {
        return "-";
      } else {
        return ret.split(".")[0];
      }
    }
  },
  watch: {
    // "$store.state.psId": function() {
    //   //你需要执行的代码
    //   this.getWeather();
    // },
    "$store.state.psId": {
      handler (aa) {
        //你需要执行的代码
        this.getWeather();
      }
    }
  }
};
</script>
<style lang="less" scoped></style>


