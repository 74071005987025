<template>
  <div id="body" class="theme-cyan">
    <!-- Page Loader -->

    <Loading />
    <!-- Overlay For Sidebars -->
    <div @click="show(false, false, 1)" v-bind:class="{ open: isMenuParent }" class="overlay"></div>

    <div id="wrapper">
      <!-- Page top navbar -->
      <Navs @changeParent="getParent" />
      <!-- Main left sidebar menu -->
      <Sidebar ref="sidebar" @changeParent="getParent" @quickDeviceType="getQuickDeviceType" :bindShow="bindShow" @loadingPsInfo="handlerLoadingPsInfo" />
      <!-- modals -->

      <!-- 左菜单设置 -->
      <!-- 用户Email快捷弹窗 -->
      <!-- <div class="modal fade feed-post-modal" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-body">
              <div class="d-flex mb-3">
                <div class="icon bg-transparent">
                  <img
                    src="../assets/images/user.png"
                    class="rounded-circle mr-3 w40"
                    alt=""
                  />
                </div>
                <div>
                  <h6 class="mb-0">Mr. Kangaroo</h6>
                  <span>Admin</span>
                </div>
              </div>
              <div class="form-group c_form_group">
                <label>填写邮件</label>
                <textarea rows="4" class="form-control no-resize" placeholder="请输入邮件信息"></textarea>
              </div>
              <div class="align-right">
                <button class="btn btn-link">
                  <i class="fad fa-paperclip text-muted"></i>
                </button>
                <button class="btn btn-link">
                  <i class="fad fa-camera-retro text-muted"></i>
                </button>
                <button class="btn btn-link">
                  <i class="fad fa-map-marker-alt text-muted"></i>
                </button>
                <button class="btn btn-warning" data-dismiss="modal">
                  发送
                </button>
              </div>
            </div>
          </div>
        </div>
      </div> -->
      <!-- 用户设置弹框 -->
      <!-- <div class="modal fade bd-UserSetting-modal-lg" tabindex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-lg">
          <div class="modal-content">
            <div class="modal-header">
              <div class="d-flex">
                <div class="icon bg-transparent">
                  <img
                    src="../assets/images/user.png"
                    class="rounded-circle mr-3 w40"
                    alt=""
                  />
                </div>
                <div>
                  <h6 class="mb-0">Mr. Kangaroo</h6>
                  <span>Admin</span>
                </div>
              </div>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true"><i class="fal fa-times"></i></span>
              </button>
            </div>
            <div class="modal-body">
              <ul class="nav nav-tabs">
                <li class="nav-item">
                  <a
                    class="nav-link active show"
                    data-toggle="tab"
                    href="#Avatar"
                    >アバター</a
                  >
                </li>
                <li class="nav-item">
                  <a class="nav-link" data-toggle="tab" href="#UserName"
                    >ユーザー名</a
                  >
                </li>
                <li class="nav-item">
                  <a class="nav-link" data-toggle="tab" href="#Password"
                    >パスワード</a
                  >
                </li>
                <li class="nav-item">
                  <a class="nav-link" data-toggle="tab" href="#Information"
                    >基本情報</a
                  >
                </li>
              </ul>
              <div class="tab-content">
                <div class="tab-pane show vivify fadeIn active" id="Avatar">
                  <h6>
                    <i class="fad fa-image-polaroid"></i> アバターを変更する
                  </h6>
                  <p>どれを選びますか？</p>
                  <label class="fancy-radio custom-color-green"
                    ><input name="gender3" value="Male" type="radio" /><span
                      ><i></i>
                      <span class="icon bg-transparent">
                        <img
                          src="../assets/images/sm/avatarMale.jpg"
                          class="rounded-circle mr-3 w30"
                          alt=""
                        />
                      </span>
                    </span>
                  </label>
                  <label class="fancy-radio custom-color-green"
                    ><input name="gender3" value="Female" type="radio" /><span
                      ><i></i>
                      <span class="icon bg-transparent">
                        <img
                          src="../assets/images/sm/avatarFemale.jpg"
                          class="rounded-circle mr-3 w30"
                          alt=""
                        />
                      </span>
                    </span>
                  </label>
                  <label class="fancy-radio custom-color-green"
                    ><input
                      name="gender3"
                      value="MrStorage"
                      type="radio"
                    /><span
                      ><i></i>
                      <span class="icon bg-transparent">
                        <img
                          src="../assets/images/sm/avatar1.jpg"
                          class="rounded-circle mr-3 w30"
                          alt=""
                        />
                      </span>
                    </span>
                  </label>
                  <label class="fancy-radio custom-color-green"
                    ><input name="gender3" value="MrSolar" type="radio" /><span
                      ><i></i>
                      <span class="icon bg-transparent">
                        <img
                          src="../assets/images/sm/avatar2.jpg"
                          class="rounded-circle mr-3 w30"
                          alt=""
                        /> </span
                    ></span>
                  </label>
                  <label class="fancy-radio custom-color-green"
                    ><input name="gender3" value="MrWeb" type="radio" /><span
                      ><i></i>
                      <span class="icon bg-transparent">
                        <img
                          src="../assets/images/sm/avatar3.jpg"
                          class="rounded-circle mr-3 w30"
                          alt=""
                        /> </span
                    ></span>
                  </label>
                  <label class="fancy-radio custom-color-green"
                    ><input name="gender3" value="MrLoad" type="radio" /><span
                      ><i></i>
                      <span class="icon bg-transparent">
                        <img
                          src="../assets/images/sm/avatar4.jpg"
                          class="rounded-circle mr-3 w30"
                          alt=""
                        /> </span
                    ></span>
                  </label>
                  <hr />
                  <p>アバターをアップロードする</p>
                  <input
                    type="file"
                    id="dropify-event"
                    data-default-file="../assets/images/image-gallery/1.jpg"
                  />
                </div>
                <div class="tab-pane vivify fadeIn" id="UserName">
                  <h6><i class="fad fa-pen-nib"></i> ユーザー名を変更する</h6>
                  <div class="form-group c_form_group">
                    <label>新しいユーザーネーム</label>
                    <input
                      type="name"
                      class="form-control"
                      value="Mr. Kangroo"
                    />
                  </div>
                </div>
                <div class="tab-pane vivify fadeIn" id="Password">
                  <h6>
                    <i class="fad fa-key-skeleton"></i> パスワードを変更する
                  </h6>
                  <div class="form-group c_form_group">
                    <label>現在のパスワード</label>
                    <input
                      type="password"
                      class="form-control"
                      placeholder="******"
                    />
                  </div>
                  <div class="form-group c_form_group">
                    <label>新しいパスワード</label>
                    <input
                      type="password"
                      class="form-control"
                      placeholder="パスワードを入力ください"
                    />
                  </div>
                  <div class="form-group c_form_group">
                    <label>新しいパスワードを確認します</label>
                    <input
                      type="password"
                      class="form-control"
                      placeholder="パスワードを入力ください"
                    />
                  </div>
                </div>
                <div class="tab-pane vivify fadeIn" id="Information">
                  <h6>
                    <i class="fad fa-address-card"></i> 基本情報を変更する
                  </h6>
                  <form>
                    <div class="row">
                      <div class="col-md-4 col-sm-12">
                        <div class="form-group c_form_group">
                          <label
                            >プロジェクト名<span class="text-tomato"
                              >*</span
                            ></label
                          >
                          <input
                            class="form-control"
                            type="text"
                            value="渡邊和也様発電所"
                          />
                        </div>
                      </div>
                      <div class="col-md-4 col-sm-12">
                        <div class="form-group c_form_group">
                          <label>連絡窓口</label>
                          <input
                            class="form-control"
                            type="text"
                            value="渡邊和也"
                          />
                        </div>
                      </div>
                      <div class="col-md-4 col-sm-12">
                        <div class="form-group c_form_group">
                          <label
                            >連絡先番号<span class="text-tomato">*</span></label
                          >
                          <input
                            class="form-control"
                            type="text"
                            value="13925698428"
                          />
                        </div>
                      </div>
                      <div class="col-sm-12">
                        <div class="form-group c_form_group">
                          <label>住所</label>
                          <textarea
                            class="form-control"
                            placeholder="比企郡小川町大字青山字城北125"
                            aria-label="With textarea"
                          ></textarea>
                        </div>
                      </div>
                      <div class="col-md-6 col-sm-12">
                        <div class="form-group c_form_group">
                          <label>Email<span class="text-tomato">*</span></label>
                          <div class="input-group">
                            <div class="input-group-prepend">
                              <span class="input-group-text"
                                ><i class="icon-envelope"></i
                              ></span>
                            </div>
                            <input
                              type="text"
                              class="form-control"
                              value="MrKangaroo@example.com"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6 col-sm-12">
                        <div class="form-group c_form_group">
                          <label>Website Url</label>
                          <div class="input-group">
                            <div class="input-group-prepend">
                              <span class="input-group-text"
                                ><i class="icon-globe"></i
                              ></span>
                            </div>
                            <input
                              type="text"
                              class="form-control"
                              placeholder="http://"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-sm-6 col-md-6 col-lg-3">
                        <div class="form-group c_form_group">
                          <label>国家</label>
                          <select class="form-control">
                            <option value="JP">Japan</option>
                            <option value="CN">China</option>
                            <option value="SG">Singapore</option>
                          </select>
                        </div>
                      </div>
                      <div class="col-sm-6 col-md-6 col-lg-3">
                        <div class="form-group c_form_group">
                          <label>市</label>
                          <select class="form-control">
                            <option>1</option>
                            <option>2</option>
                            <option>3</option>
                          </select>
                        </div>
                      </div>
                      <div class="col-sm-6 col-md-6 col-lg-3">
                        <div class="form-group c_form_group">
                          <label>City</label>
                          <input class="form-control" value="123" type="text" />
                        </div>
                      </div>
                      <div class="col-sm-6 col-md-6 col-lg-3">
                        <div class="form-group c_form_group">
                          <label>Postal Code</label>
                          <input
                            class="form-control"
                            value="91403"
                            type="text"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-sm-6">
                        <div class="form-group c_form_group">
                          <label>Phone Number</label>
                          <input
                            class="form-control"
                            value="818-978-7102"
                            type="text"
                          />
                        </div>
                      </div>
                      <div class="col-sm-6">
                        <div class="form-group c_form_group">
                          <label>Fax</label>
                          <input
                            class="form-control"
                            value="818-978-7102"
                            type="text"
                          />
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-default" data-dismiss="modal">
                关闭
              </button>
              <button type="button" class="btn btn-primary theme-bg gradient">
                保存
              </button>
            </div>
          </div>
        </div>
      </div> -->

      <!-- 左菜单Mini-快捷图标设置弹框 -->
      <div id="ps_st3" class="modal fade bd-MiniIconsSetting-modal-lg" tabindex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-lg">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">
                <i class="fad fa-cog fa-spin fa-2x rightbar-title"></i>
                <!-- 快捷菜单设置 -->
                {{ $t("master.shortcutMenu") }}
              </h5>
              <!-- <h5 class="modal-title h4" id="myLargeModalLabel">
                <span class="fa-layers fa-fw fa-2x">
                  <i class="fad fa-ellipsis-v-alt"></i>
                  <i class="fad fa-cog fa-spin" data-fa-transform="shrink-6 right-10"></i>
                </span>
                <span>ショートカットアイコン</span>
              </h5> -->
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true"><i class="fal fa-times"></i></span>
              </button>
            </div>
            <div class="modal-body">
              <div class="col-lg-12 col-md-12">
                <!-- <p>主菜单</p>
                <label class="fancy-checkbox">
                  <input type="checkbox" name="checkbox" />
                  <span><i class="fad fa-cubes mr-2"></i> デバイス情報</span>
                </label>
                <label class="fancy-checkbox">
                  <input type="checkbox" name="checkbox" />
                  <span><i class="fad fa-check-double mr-2"></i> 設備比較</span>
                </label>
                <hr /> -->
                <!-- <p>4つのシステム</p>
                <label class="fancy-checkbox">
                  <input type="checkbox" name="checkbox" required data-parsley-errors-container="#error-checkbox">
                  <span><img src="../assets/images/F4/MrStorage.svg" class="w38 mr-2" alt="">蓄電池</span>
                </label>
                <label class="fancy-checkbox">
                  <input type="checkbox" name="checkbox" required data-parsley-errors-container="#error-checkbox">
                  <span><img src="../assets/images/F4/MrSolar.svg" class="w30 mr-2 mb-1" alt="">太陽光発電</span>
                </label>
                <label class="fancy-checkbox">
                  <input type="checkbox" name="checkbox" required data-parsley-errors-container="#error-checkbox">
                  <span><img src="../assets/images/F4/MrWeb.svg" class="w30 mr-2 mb-1" alt="">グリット</span>
                </label>
                <label class="fancy-checkbox">
                  <input type="checkbox" name="checkbox" required data-parsley-errors-container="#error-checkbox">
                  <span><img src="../assets/images/F4/MrLoad.svg" class="w35 mr-2" alt="">負荷</span>
                </label>
                <hr> -->
                <p>
                  <!-- 请选择需要显示的设备类型 -->
                  {{ $t("master.deviceTypeToBeDisplayed") }}
                </p>
                <template v-for="item in quickDeviceType">
                  <label class="fancy-checkbox" :key="item.deviceTypeId">
                    <input type="checkbox" v-model="item.checked" name="checkbox" />
                    <span><i :class="quickIcon[item.deviceTypeId]" class="fad fa-car-battery mr-2"></i>{{ $t(deviceTypeName[item.deviceTypeId]) || "-" }}</span>
                  </label>
                </template>
                <!-- <label class="fancy-checkbox">
                  <input type="checkbox" name="checkbox">
                  <span><i class="fad fa-car-battery mr-2"></i>蓄電池</span>
                </label>
                <label class="fancy-checkbox">
                  <input type="checkbox" name="checkbox">
                  <span><i class="fad fa-archive mr-2"></i>PCS</span>
                </label>
                <label class="fancy-checkbox">
                  <input type="checkbox" name="checkbox">
                  <span><i class="fad fa-tablet-rugged mr-2"></i>DCDC</span>
                </label>
                <label class="fancy-checkbox">
                  <input type="checkbox" name="checkbox">
                  <span><i class="fad fa-tachometer-alt-fast mr-2"></i>電力計</span>
                </label>
                <label class="fancy-checkbox">
                  <input type="checkbox" name="checkbox">
                  <span><i class="fad fa-snow-blowing mr-2"></i>エアコン</span>
                </label>
                <label class="fancy-checkbox">
                  <input type="checkbox" name="checkbox">
                  <span><i class="fad fa-fire-extinguisher mr-2"></i>消防システム</span>
                </label> -->
              </div>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-default" data-dismiss="modal">
                <!-- 关闭 -->
                {{ $t("master.close") }}
              </button>
              <button @click="saveQuick" type="button" class="btn btn-primary theme-bg gradient js-sweetalert" data-dismiss="modal">
                <!-- 保存 -->
                {{ $t("master.save") }}
              </button>
            </div>
          </div>
        </div>
      </div>

      <!-- 首页主板块设置 -->
      <!-- 首页滚动数据条设置 -->
      <div id="ps_st1" class="modal fade bd-HomeScrollSetting-modal-xl" tabindex="-1" role="dialog" aria-labelledby="myXLModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-xl">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">
                <i class="fad fa-cog fa-spin fa-2x rightbar-title"></i>
                <!-- 滚动数据设置 -->
                {{ $t("master.scrollDataSettings") }}
              </h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true"><i class="fal fa-times"></i></span>
              </button>
            </div>
            <div class="modal-body">
              <div class="col-lg-12 col-md-12">
                <!-- 请至少选择1组参数，至多选择4组参数。 -->
                <el-alert v-if="btnCensus" :title="$t('master.pleaseSelectAtLeast1group4')" type="success" show-icon :closable="false" class="mb-2" />
                <!-- 请至少选择1组参数，至多选择4组参数。 -->
                <el-alert v-if="!btnCensus" :title="$t('master.pleaseSelectAtLeast1group4')" type="error" show-icon :closable="false" class="mb-2" />
                <table id="default-table" class="table  table-striped table-sm">
                  <thead>
                    <tr ref="censusTitle">
                      <template v-for="(item, index) in census">
                        <th :key="item.name" :name="item['name']" v-if="!(typeof censusIcon[item.name] === 'undefined')">
                          <label class="switch left" @change="verifyCensusNum(index)">
                            <input type="checkbox" v-model="item.checked" />
                            <span class="slider"></span>
                          </label>
                          <span class="left ml-2"><strong>{{
                              typeof censusIcon[item.name] === "undefined"
                                ? ""
                                : $t(censusIcon[item.name]["name"])
                            }}</strong>
                            <i class="fad fa-hand-paper sindu_handle"></i></span>
                        </th>
                      </template>
                    </tr>
                  </thead>

                  <template v-for="item in censusItems">
                    <tr :key="item.row">
                      <template v-for="childItem in item.items">
                        <td :key="censuskey(childItem.name, childItem.groupName)">
                          <label class="fancy-checkbox m-b--10" v-if="childItem.name">
                            <input type="checkbox" v-model="childItem.checked" />
                            <span>{{
                              $t(censusItemsIcon[childItem.name + "Name"])
                            }}</span>
                          </label>
                        </td>
                      </template>
                    </tr>
                  </template>
                </table>

                <!-- <div class="ScrollSettingBox" >
                  <div class="row clearfix">
                    <template v-for="(item,index) in census" >
                    <div :key="item.name" class="col-lg-1-5 col-lg-auto" v-if="!(typeof censusIcon[item.name] === 'undefined')">
                      <ul>
                        <li>
                         <label class="switch left" @change="verifyCensusNum(index)">
                            <input type="checkbox" v-model="item.checked" />
                            <span class="slider"></span>
                          </label>
                          <span class="left ml-2"><strong>{{typeof censusIcon[item.name] === "undefined"? "": censusIcon[item.name]["name"]}}</strong>
                            <i class="fad fa-hand-paper sindu_handle"></i></span>
                        </li>
                         <template v-for="childItem in item.items">   
                         <li :key="censuskey(childItem.name, childItem.groupName)">
                               <label class="fancy-checkbox m-b--10" v-if="childItem.name">
                                <input type="checkbox" v-model="childItem.checked" />
                                <span>{{
                                    censusItemsIcon[childItem.name + "Name"]
                                }}</span>
                              </label>
                         </li>
                         </template>
                      </ul>
                    </div>
                    </template>
                  </div>
                </div> -->
              </div>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-default" data-dismiss="modal">
                <!-- 关闭 -->
                {{ $t("master.close") }}
              </button>
              <button type="button" :disabled="!btnCensus" @click="savet1" data-dismiss="modal" class="btn btn-primary theme-bg gradient js-sweetalert" data-type="success">
                <!-- 保存 -->
                {{ $t("master.save") }}
              </button>
            </div>
          </div>
        </div>
      </div>

      <!-- 首页F4板块设置 -->
      <div id="ps_st2" class="modal fade bd-F4Setting-modal-xl" tabindex="-1" role="dialog" aria-labelledby="myXLModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-xl">
          <div class="modal-content" style="min-width:600px">
            <div class="modal-header">
              <h5 class="modal-title">
                <i class="fad fa-cog fa-spin fa-2x rightbar-title"></i>
                <!-- 参数设置 -->
                {{ $t("master.parameterSetting") }}
              </h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true"><i class="fal fa-times"></i></span>
              </button>
            </div>
            <div class="modal-body">
              <div class="col-lg-12 col-md-12">
                <!-- 请至少选择1组参数，至多选择4组参数。 -->
                <el-alert v-if="btnSoip" :title="$t('master.pleaseSelectAtLeast1group4')" type="success" show-icon :closable="false" class="mb-2" />
                <!-- 请至少选择1组参数，至多选择4组参数。 -->
                <el-alert v-if="!btnSoip" :title="$t('master.pleaseSelectAtLeast1group4')" type="error" show-icon :closable="false" class="mb-2" />
                <!-- <div v-if="btnSoip" class="alert alert-success" role="alert">
                  <i class="fad fa-comment-smile"></i>
                  {{ $t("master.pleaseSelectAtLeast1group4") }}
                </div> -->
                <!-- <div v-if="!btnSoip" class="alert alert-danger" role="alert">
                  <i class="fad fa-comment-exclamation"></i>
                  {{ $t("master.pleaseSelectAtLeast1group4") }}
                </div> -->
                <table id="only-bodytable" class="table table-striped table-sm" >
                  <thead>
                    <tr ref="soipTitle">
                      <template v-for="(item, index) in soipSetting">
                        <th :key="item.name" :name="item['name']" v-if="!(typeof soipTitle[item.name] === 'undefined')">
                          <label class="switch left" @change="verifySoipNum(index)">
                            <input type="checkbox" v-model="item.checked" />
                            <span class="slider"></span>
                          </label>
                          <span class="left ml-2"><strong>{{
                              typeof soipTitle[item.name] === "undefined"
                                ? ""
                                : $t(soipTitle[item.name]["name"])
                            }}</strong>
                            <i class="fad fa-hand-paper sindu_handle"></i></span>
                        </th>
                      </template>
                    </tr>
                  </thead>

                  <template v-for="item in soipItems">
                    <tr :key="item.row">
                      <template v-for="childItem in item.items">
                        <!-- childItem['name']+childItem['groupName'] -->
                        <td :key="soipkey(childItem['name'])">
                          <label class="fancy-checkbox m-b--10" v-if="childItem.name">
                            <input type="checkbox" v-model="childItem.checked" />
                            <span>{{
                              $t(soipItemsTitle[childItem.groupName + "_" + childItem.name])
                            }}</span>
                          </label>
                        </td>
                      </template>
                    </tr>
                  </template>
                </table>
              </div>
            </div>
            <div class="modal-footer">
              <!-- btnSoip -->
              <button type="button" class="btn btn-default" data-dismiss="modal">
                <!-- 关闭 -->
                {{ $t("master.close") }}
              </button>
              <button type="button" :disabled="!btnSoip" @click="saveSoipSetting" data-dismiss="modal" class="btn btn-primary theme-bg gradient js-sweetalert" data-type="success">
                <!-- 保存 -->
                {{ $t("master.save") }}
              </button>
            </div>
          </div>
        </div>
      </div>

      <!-- 6组设备的设置弹框 -->
      <!-- 6设备设置弹框 -->
      <div class="modal fade bd-Setting-modal-xl" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-xl">
          <!-- PCS -->
          <visible-setting :style="{ display: checkedDeviceTypeId == 1 ? 'block' : 'none' }" :max="6" :min="1" img="fad fa-archive" :title="$t('deviceIcon.pcs')" :params="PcsVisibleSetting" :keyName="ParamsLanguage" @saveClick="savePcsVisible" @setDetault="pcsSetDefault"></visible-setting>
          <!-- /.PCS -->
          <!-- DCDC -->
          <visible-setting :style="{ display: checkedDeviceTypeId == 2 ? 'block' : 'none' }" :max="6" :min="1" img="fad fa-tablet-rugged" :title="$t('deviceIcon.dcdc')" :params="DcdcVisibleSetting" :keyName="ParamsLanguage" @saveClick="saveDcdcVisible" @setDetault="dcdcSetDefault"></visible-setting>
          <!-- /.DCDC -->
          <!-- 蓄电池 -->
          <visible-setting :style="{ display: checkedDeviceTypeId == 3 ? 'block' : 'none' }" :max="6" :min="1" img="fad fa-car-battery" :title="$t('deviceIcon.bms')" :params="BmsVisibleSetting" :keyName="ParamsLanguage" @saveClick="saveBmsVisible" @setDetault="bmsSetDefault"></visible-setting>
          <!-- /.蓄电池 -->

          <!-- 电力计 -->
          <div class="modal-content" style="display:none">
            <div class="modal-header">
              <h5 class="modal-title h4" id="myLargeModalLabel">
                <span class="fa-layers fa-fw fa-2x">
                  <i class="fad fa-tachometer-alt-fast"></i>
                  <i class="fad fa-cog fa-spin" data-fa-transform="shrink-6 right-10"></i>
                </span>
                <span>
                  <!-- 电表参数设置 -->
                  {{ $t("master.ammeterParameterSetting") }}
                </span>
              </h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true"><i class="fal fa-times"></i></span>
              </button>
            </div>
            <div class="modal-body">
              <div class="col-lg-12 col-md-12 text-center">
                <!-- 请至少选择1个参数，至多选择5个参数。 -->
                <el-alert v-if="btnCensus" :title="$t('master.pleaseSelectAtLeast1group5')" type="success" show-icon :closable="false" class="mb-2" />
                <!-- 请至少选择1个参数，至多选择5个参数。 -->
                <el-alert v-if="!btnCensus" :title="$t('master.pleaseSelectAtLeast1group5')" type="error" show-icon :closable="false" class="mb-2" />
                <!-- <div v-if="btnCensus" class="alert alert-success" role="alert">
                  <i class="fad fa-comment-smile"></i>
                  {{ $t("master.pleaseSelectAtLeast1group5") }}
                </div>
                <div v-if="!btnCensus" class="alert alert-danger" role="alert">
                  <i class="fad fa-comment-exclamation"></i>
                  {{ $t("master.pleaseSelectAtLeast1group5") }}
                </div> -->
                <label class="fancy-checkbox">
                  <input type="checkbox" name="checkbox" checked />
                  <span>、
                    <!-- 状态 -->
                    {{ $t("master.state") }}
                  </span>
                </label>
                <label class="fancy-checkbox">
                  <input type="checkbox" name="checkbox" checked />
                  <span>
                    <!-- 买电量 -->
                    {{ $t("master.gridEngery") }}
                  </span>
                </label>
                <label class="fancy-checkbox">
                  <input type="checkbox" name="checkbox" checked />
                  <span>
                    <!-- 卖电量 -->
                    {{ $t("master.sendEngery") }}
                  </span>
                </label>
                <label class="fancy-checkbox">
                  <input type="checkbox" name="checkbox" checked />
                  <span>
                    <!-- 买卖电功率 -->
                    {{ $t("master.gridSendPower") }}
                  </span>
                </label>
                <label class="fancy-checkbox">
                  <input type="checkbox" name="checkbox" checked />
                  <span>
                    <!-- 刷新时间 -->
                    {{ $t("master.refreshTime") }}
                  </span>
                </label>
              </div>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-default" data-dismiss="modal">
                <!-- 恢复默认设置 -->
                {{ $t("master.restoreDefaultSettings") }}
              </button>
              <button type="button" class="btn btn-primary theme-bg gradient js-sweetalert" data-type="success">
                <!-- 保存 -->
                {{ $t("master.close") }}
              </button>
            </div>
          </div>
          <!-- /.电力计 -->
          <!-- 空调 -->
          <div class="modal-content" style="display:none">
            <div class="modal-header">
              <h5 class="modal-title h4" id="myLargeModalLabel">
                <span class="fa-layers fa-fw fa-2x">
                  <i class="fad fa-snow-blowing"></i>
                  <i class="fad fa-cog fa-spin" data-fa-transform="shrink-6 right-10"></i>
                </span>
                <span>
                  <!-- 空调设置 -->
                  {{ $t("master.airSettings") }}
                </span>
              </h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true"><i class="fal fa-times"></i></span>
              </button>
            </div>
            <div class="modal-body">
              <div class="col-lg-12 col-md-12 text-center">
                <!-- 请至少选择1个参数，至多选择3个参数。 -->
                <el-alert v-if="btnCensus" :title="$t('master.pleaseSelectAtLeast1group3')" type="success" show-icon :closable="false" class="mb-2" />
                <!-- 请至少选择1个参数，至多选择3个参数。 -->
                <el-alert v-if="!btnCensus" :title="$t('master.pleaseSelectAtLeast1group3')" type="error" show-icon :closable="false" class="mb-2" />
                <!-- <div v-if="btnCensus" class="alert alert-success" role="alert">
                  <i class="fad fa-comment-smile"></i>
                  {{ $t("master.pleaseSelectAtLeast1group3") }}
                </div>
                <div v-if="!btnCensus" class="alert alert-danger" role="alert">
                  <i class="fad fa-comment-exclamation"></i>
                  {{ $t("master.pleaseSelectAtLeast1group3") }}
                </div> -->
                <label class="fancy-checkbox">
                  <input type="checkbox" name="checkbox" checked />
                  <span>
                    <!-- 状态 -->
                    {{ $t("master.state") }}
                  </span>
                </label>
                <label class="fancy-checkbox">
                  <input type="checkbox" name="checkbox" checked />
                  <span>
                    <!-- 室内温度 -->
                    {{ $t("master.indoorTemp") }}
                  </span>
                </label>
                <label class="fancy-checkbox">
                  <input type="checkbox" name="checkbox" checked />
                  <span>
                    <!-- 刷新时间 -->
                    {{ $t("master.refreshTime") }}
                  </span>
                </label>
              </div>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-default" data-dismiss="modal">
                <!-- 恢复默认设置 -->
                {{ $t("master.restoreDefaultSettings") }}
              </button>
              <button type="button" class="btn btn-primary theme-bg gradient js-sweetalert" data-type="success">
                <!-- 保存 -->
                {{ $t("master.save") }}
              </button>
            </div>
          </div>
          <!-- /.空调 -->
          <!-- 消防 -->
          <div class="modal-content" style="display:none">
            <div class="modal-header">
              <h5 class="modal-title h4" id="myLargeModalLabel">
                <span class="fa-layers fa-fw fa-2x">
                  <i class="fad fa-fire-extinguisher"></i>
                  <i class="fad fa-cog fa-spin" data-fa-transform="shrink-6 right-10"></i>
                </span>
                <span>
                  <!-- 消防系统设置 -->
                  {{ $t("master.fireSetting") }}
                </span>
              </h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true"><i class="fal fa-times"></i></span>
              </button>
            </div>
            <div class="modal-body">
              <div class="col-lg-12 col-md-12 text-center">
                <!-- 请至少选择1个参数，至多选择2个参数。 -->
                <el-alert v-if="btnCensus" :title="$t('master.pleaseSelectAtLeast1group2')" type="success" show-icon :closable="false" class="mb-2" />
                <!-- 请至少选择1个参数，至多选择2个参数。 -->
                <el-alert v-if="!btnCensus" :title="$t('master.pleaseSelectAtLeast1group2')" type="error" show-icon :closable="false" class="mb-2" />
                <!-- <div v-if="btnCensus" class="alert alert-success" role="alert">
                  <i class="fad fa-comment-smile"></i>
                  {{ $t("master.pleaseSelectAtLeast1group2") }}
                </div>
                <div v-if="!btnCensus" class="alert alert-danger" role="alert">
                  <i class="fad fa-comment-exclamation"></i>
                  {{ $t("master.pleaseSelectAtLeast1group2") }}
                </div> -->
                <label class="fancy-checkbox">
                  <input type="checkbox" name="checkbox" checked />
                  <span>
                    <!-- 状态 -->
                    {{ $t("master.state") }}
                  </span>
                </label>
                <label class="fancy-checkbox">
                  <input type="checkbox" name="checkbox" checked />
                  <span>
                    <!-- 刷新时间 -->
                    {{ $t("master.refreshTime") }}
                  </span>
                </label>
              </div>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-default" data-dismiss="modal">
                <!-- 恢复默认设置 -->
                {{ $t("master.restoreDefaultSettings") }}
              </button>
              <button type="button" class="btn btn-primary theme-bg gradient js-sweetalert" data-type="success">
                <!-- 保存 -->
                {{ $t("master.save") }}
              </button>
            </div>
          </div>
          <!-- /.消防 -->
        </div>
      </div>
      <!-- 6设备右抽屉 -->
      <div v-bind:class="{ open: isMenuParent }" id="rightbar" v-loading="loadingRight" class="rightbar">
        <!-- 蓄电池 -->
        <div class="body" :style="{ display: checkedDeviceTypeId == 3 ? 'block' : 'none' }">
          <div class="header">
            <h5>
              <i class="fad fa-car-battery fa-2x rightbar-title"></i>
              <!-- 蓄電池 -->
              {{ $t("deviceIcon.bms") }}
            </h5>
            <div class="buttons">
              <!-- 点击查看设备对比 -->
              <el-tooltip class="item" :content="$t('master.viewDeviceComparison')" placement="bottom-end" effect="dark">
                <router-link @click.native="JumpDeviceId" :to="{path:'/deviceAnalyse',name:'deviceAnalyse',params:{'deviceTypeId':checkedDeviceTypeId}}">
                  <el-button size="small" class="samll-round-btn" icon="fad fa-chart-bar" circle><span class="sr-only">
                      <!-- 设备对比 -->
                      {{ $t("master.deviceComparison") }}
                    </span></el-button>
                </router-link>
              </el-tooltip>
              <!-- 点击设置蓄电池参数 -->
              <el-tooltip class="item" :content="$t('master.setBatteryParameters')" placement="bottom-end" effect="dark">
                <a href="javascript:void(0);" @click="show(false, false, 3)" data-toggle="modal" data-target=".bd-Setting-modal-xl">
                  <el-button size="small" class="samll-round-btn" icon="fad fa-cog" circle><span class="sr-only">
                      <!-- 设置 -->
                      {{ $t("master.setUp") }}
                    </span></el-button>
                </a>
              </el-tooltip>
              <!-- 点击刷新获取最新数据 -->
              <el-tooltip class="item" :content="$t('master.clickRefresh')" placement="bottom-end" effect="dark">
                <a href="javascript:void(0);" @click="detailRefresh()">
                  <el-button type="aurora" size="small" icon="fad fa-sync-alt fa-spin" circle class="samll-round-btn is-active"><span class="sr-only">
                      <!-- 刷新 -->
                      {{ $t("master.refresh") }}
                    </span></el-button>
                </a>
              </el-tooltip>
            </div>
          </div>
          <div class="table-responsive">
            <table class="table table-hover">
              <thead class="bg-dark">
                <tr>
                  <th width="80">
                    <!-- 设备名称 -->
                    {{ $t("master.deviceName") }}
                  </th>
                  <th v-if="BmsVisibleSetting.deviceFlag" class="text-center">
                    <!-- 状态 -->
                    {{ $t("master.state") }}
                  </th>
                  <th v-if="BmsVisibleSetting.soc">
                    <!-- SOC  -->
                    {{ $t("master.soc") }}
                    <span class="percent small"></span>
                  </th>
                  <th v-if="BmsVisibleSetting.bcp">
                    <!-- 充/放电功率 -->
                    {{ $t("master.inOutPower") }}
                    <span class="kw small"></span>
                  </th>
                  <th v-if="BmsVisibleSetting.outTotal && $store.state.psTypeId!=2">
                    <!-- 放电量  -->
                    {{ $t("master.outTotalPac") }}
                    <span class="kwh small"></span>
                  </th>
                  <th v-if="BmsVisibleSetting.inTotal  && $store.state.psTypeId!=2">
                    <!-- 充电量  -->
                    {{ $t("master.inTotalPac") }}
                    <span class="kwh small"></span>
                  </th>

                  <th v-if="BmsVisibleSetting.bv">
                    <!-- 电压  -->
                    {{ $t("master.voltage") }}
                    <span class="V small"></span>
                  </th>
                  <th v-if="BmsVisibleSetting.bcc">
                    <!-- 电流  -->
                    {{ $t("master.current") }}
                    <span class="A small"></span>
                  </th>
                  <th v-if="BmsVisibleSetting.btmax">
                    <!-- 单体最高温度 -->
                    {{ $t("master.maxTemp") }}
                    <span class="oc small"></span>
                  </th>
                  <th v-if="BmsVisibleSetting.btmin">
                    <!-- 单体最低温度 -->
                    {{ $t("master.minTemp") }}
                    <span class="oc small"></span>
                  </th>
                  <th v-if="BmsVisibleSetting.getdatetime">
                    <!-- 采集时间 -->
                    {{ $t("master.getdatetime") }}
                  </th>
                  <th class="text-center">
                    <!-- 历史 -->
                    {{ $t("master.history") }}
                  </th>
                  <th class="text-center ChangeShow">
                    <!-- 实时数据 -->
                    {{ $t("master.realtimeData") }}
                  </th>
                </tr>
              </thead>
              <!-- <tfoot>
                <tr>
                  <th v-if="BmsVisibleSetting.deviceName" width="80">设备名</th>
                  <th v-if="BmsVisibleSetting.deviceFlag" class="text-center">
                    状态
                  </th>
                  <th v-if="BmsVisibleSetting.soc">
                    SOC <span class="percent small"></span>
                  </th>
                  <th v-if="BmsVisibleSetting.bcp">
                    充放电功率 <span class="kw small"></span>
                  </th>
                  <th v-if="BmsVisibleSetting.outTotal">
                    放电量 <span class="kwh small text-tomato"></span>
                  </th>
                  <th v-if="BmsVisibleSetting.inTotal">
                    充电量 <span class="kwh small text-grass"></span>
                  </th>
                  <th v-if="BmsVisibleSetting.bv">
                    电压 <span class="V small"></span>
                  </th>
                  <th v-if="BmsVisibleSetting.bcc">
                    电流 <span class="A small"></span>
                  </th>
                  <th v-if="BmsVisibleSetting.btmin">
                    单体最高温度 <span class="oc small"></span>
                  </th>
                  <th v-if="BmsVisibleSetting.btmin">
                    单体最低温度 <span class="oc small"></span>
                  </th>
                  <th v-if="BmsVisibleSetting.getdatetime">刷新时间</th>
                  <th class="text-center">历史</th>
                  <th class="text-center">实时数据</th>
                </tr>
              </tfoot> -->
              <tbody>
                <tr v-for="(bms, index) in BmsDetals" :key="index">
                  <td>
                    <span>{{ bms.deviceName || "-" }}</span>
                    <!-- <p class="mb-0"><small>12345678</small></p> -->
                  </td>
                  <td v-if="BmsVisibleSetting.deviceFlag" class="text-center">
                    <device-state :device-state="bms.deviceFlag"></device-state>
                  </td>
                  <td v-if="BmsVisibleSetting.soc">{{ bms.soc || "-" }}</td>
                  <td v-if="BmsVisibleSetting.bcp">{{ bms.bcp || "-" }}</td>
                  <td v-if="BmsVisibleSetting.outTotal && $store.state.psTypeId!=2">
                    {{ bms.outTotal || "-" }}
                  </td>
                  <td v-if="BmsVisibleSetting.inTotal && $store.state.psTypeId!=2 ">
                    {{ bms.inTotal || "-" }}
                  </td>

                  <td v-if="BmsVisibleSetting.bv">{{ bms.bv || "-" }}</td>
                  <td v-if="BmsVisibleSetting.bcc">{{ bms.bcc || "-" }}</td>
                  <td v-if="BmsVisibleSetting.btmax">{{ bms.btmax || "-" }}</td>
                  <td v-if="BmsVisibleSetting.btmin">{{ bms.btmin || "-" }}</td>
                  <td v-if="BmsVisibleSetting.getdatetime">
                    {{ bms.getdatetime || "-" }}
                  </td>
                  <!-- 设备历史查询 -->
                  <td class="text-center">
                    <el-tooltip class="item" :content="$t('master.historyQuery')" placement="bottom">
                      <router-link @click.native="JumpDeviceId" :to="{path:'/deviceReport',name:'DeviceReport',params:{'deviceId':bms.deviceId,'deviceTypeId':checkedDeviceTypeId}}"><i class="fad fa-history"></i></router-link>
                      <!-- <a href="history" @click="JumpDeviceId(bms,'bms')" target="_blank"><i class="fad fa-history"></i></a> -->
                    </el-tooltip>
                  </td>
                  <!-- 设备明细数据 -->
                  <td class="text-center">
                    <el-tooltip class="item" :content="$t('master.detailData')" placement="bottom">
                      <a href="javascript:void(0);" @click="show(!isMenuSon, true, 1, index)" class="right-son_toggle"><i class="fad fa-file-contract"></i></a>
                    </el-tooltip>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <!-- /.蓄电池 -->

        <!-- PCS -->
        <div class="body" :style="{ display: checkedDeviceTypeId == 1 ? 'block' : 'none' }">
          <div class="header">
            <!-- PCS -->
            <h4>
              <i class="fad fa-archive fa-2x rightbar-title"></i>{{ $t("deviceIcon.pcs") }}
            </h4>
            <div class="buttons">
              <!-- 点击查看设备对比 -->
              <el-tooltip class="item" :content="$t('master.viewDeviceComparison')" placement="bottom-end" effect="dark">
                <router-link @click.native="JumpDeviceId" :to="{path:'/deviceAnalyse',name:'deviceAnalyse',params:{'deviceTypeId':checkedDeviceTypeId}}">
                  <el-button size="small" class="samll-round-btn" icon="fad fa-chart-bar" circle><span class="sr-only">
                      <!-- 设备对比 -->
                      {{ $t("master.deviceComparison") }}
                    </span></el-button>
                </router-link>
              </el-tooltip>
              <!-- 点击设置PCS参数 -->
              <el-tooltip class="item" :content="$t('master.setPcsParameters')" placement="bottom-end" effect="dark">
                <a href="javascript:void(0);" @click="show(false, false, 3)" data-toggle="modal" data-target=".bd-Setting-modal-xl">
                  <el-button size="small" class="samll-round-btn" icon="fad fa-cog" circle><span class="sr-only">
                      <!-- 设置 -->
                      {{ $t("master.setUp") }}
                    </span></el-button>
                </a>
              </el-tooltip>
              <!-- 点击刷新获取最新数据 -->
              <el-tooltip class="item" :content="$t('master.clickRefresh')" placement="bottom-end" effect="dark">
                <a href="javascript:void(0);" @click="detailRefresh()">
                  <el-button type="aurora" size="small" icon="fad fa-sync-alt fa-spin" circle class="samll-round-btn is-active"><span class="sr-only">
                      <!-- 刷新 -->
                      {{ $t("master.setUp") }}
                    </span></el-button>
                </a>
              </el-tooltip>
            </div>
          </div>
          <div class="table-responsive">
            <table class="table table-hover">
              <thead class="bg-dark">
                <tr>
                  <th width="80" class="text-center">
                    <!-- 设备名称 -->
                    {{ $t("master.deviceName") }}
                  </th>
                  <th v-if="PcsVisibleSetting.deviceFlag" class="text-center">
                    <!-- 状态 -->
                    {{ $t("master.state") }}
                  </th>
                  <th v-if="PcsVisibleSetting.pac" class="text-center">
                    AC <span class="kw small"></span>
                  </th>
                  <th v-if="PcsVisibleSetting.dayPac" class="text-center">
                    <!-- 今日发电量  -->
                    {{ $t("master.dayTotalPac") }}
                    <span class="kwh small"></span>
                  </th>
                  <th v-if="PcsVisibleSetting.dayInTotal" class="text-center">
                    <!-- 今日充电量  -->
                    {{ $t("master.dayInTotalPac") }}
                    <span class="kwh small"></span>
                  </th>
                  <th v-if="PcsVisibleSetting.dayOutTotal" class="text-center">
                    <!-- 今日放电量  -->
                    {{ $t("master.dayOutTotalPac") }}
                    <span class="kwh small"></span>
                  </th>
                  <th v-if="PcsVisibleSetting.totalPac" class="text-center">
                    <!-- 累计发电量  -->
                    {{ $t("master.totalPac") }}
                    <span class="kwh small"></span>
                  </th>
                  <th v-if="PcsVisibleSetting.inTotal" class="text-center">
                    <!-- 累计充电量  -->
                    {{ $t("master.inTotal") }}
                    <span class="kwh small"></span>
                  </th>
                  <th v-if="PcsVisibleSetting.outTotal" class="text-center">
                    <!-- 累计放电量  -->
                    {{ $t("master.outTotal") }}
                    <span class="kwh small"></span>
                  </th>
                  <th v-if="PcsVisibleSetting.itemp" class="text-center">
                    <!-- 模块温度 -->
                    {{ $t("master.itemp") }}
                    <span class="oc small"></span>
                  </th>
                  <th v-if="PcsVisibleSetting.temp" class="text-center">
                    <!-- 模块温度 -->
                    {{ $t("master.temp") }}
                    <span class="oc small"></span>
                  </th>
                  <th v-if="PcsVisibleSetting.rfac" class="text-center">
                    <!-- 频率 -->
                    {{ $t("master.frequency") }}
                    <span class="hz small"></span>
                  </th>
                  <th v-if="PcsVisibleSetting.getdatetime" class="text-center">
                    <!-- 采集时间 -->
                    {{ $t("master.getdatetime") }}
                  </th>
                  <th class="text-center">
                    <!-- 历史 -->
                    {{ $t("master.history") }}
                  </th>
                  <th class="text-center">
                    <!-- 实时数据 -->
                    {{ $t("master.realtimeData") }}
                  </th>
                </tr>
              </thead>
              <!-- <tfoot>
                <tr>
                  <th width="80">设备名</th>
                  <th v-if="PcsVisibleSetting.deviceFlag" class="text-center">
                    状态
                  </th>
                  <th v-if="PcsVisibleSetting.pac">
                    AC <span class="kw small"></span>
                  </th>
                  <th v-if="PcsVisibleSetting.dayPac">
                    今日发电量 <span class="kwh small"></span>
                  </th>
                  <th v-if="PcsVisibleSetting.dayInTotal">
                    今日充电量 <span class="kwh small text-grass"></span>
                  </th>
                  <th v-if="PcsVisibleSetting.dayOutTotal">
                    今日放电量 <span class="kwh small text-tomato"></span>
                  </th>
                  <th v-if="PcsVisibleSetting.totalPac">
                    累计发电量 <span class="kwh small"></span>
                  </th>
                  <th v-if="PcsVisibleSetting.inTotal">
                    累计充电量 <span class="kwh small text-grass"></span>
                  </th>
                  <th v-if="PcsVisibleSetting.outTotal">
                    累计放电量 <span class="kwh small text-tomato"></span>
                  </th>
                  <th v-if="PcsVisibleSetting.itemp">
                    温度 <span class="oc small"></span>
                  </th>
                  <th v-if="PcsVisibleSetting.rfac">
                    频率 <span class="hz small"></span>
                  </th>
                  <th v-if="PcsVisibleSetting.getdatetime">刷新时间</th>
                  <th class="text-center">历史</th>
                  <th class="text-center">实时数据</th>
                </tr>
              </tfoot> -->
              <tbody>
                <tr v-for="(pcs, index) in PcsDetals" :key="index">
                  <td>
                    <span v-html="pcs.deviceName">PCS-1</span>
                    <!-- <p class="mb-0"><small>12345678</small></p> -->
                  </td>
                  <td class="text-center" v-if="PcsVisibleSetting.deviceFlag">
                    <device-state :device-state="pcs.deviceFlag"></device-state>
                  </td>
                  <td class="text-center" v-if="PcsVisibleSetting.pac" v-html="pcs.pac"></td>
                  <td class="text-center" v-if="PcsVisibleSetting.dayPac" v-html="pcs.dayPac">
                    <!-- 今日发电量 -->
                    {{ $t("master.dayTotalPac") }}
                  </td>
                  <td class="text-center" v-if="PcsVisibleSetting.dayInTotal" v-html="pcs.dayInTotal">
                    <!-- 今日充电量 -->
                    {{ $t("master.dayInTotalPac") }}
                  </td>
                  <td class="text-center" v-if="PcsVisibleSetting.dayOutTotal" v-html="pcs.dayOutTotal">
                    <!-- 今日放电量 -->
                    {{ $t("master.dayOutTotalPac") }}
                  </td>
                  <td class="text-center" v-if="PcsVisibleSetting.totalPac" v-html="pcs.totalPac">
                    <!-- 累计发电量 -->
                    {{ $t("master.totalPac") }}
                  </td>
                  <td class="text-center" v-if="PcsVisibleSetting.inTotal" v-html="pcs.inTotal">
                    <!-- 累计充电量 -->
                    {{ $t("master.inTotal") }}
                  </td>
                  <td class="text-center" v-if="PcsVisibleSetting.outTotal" v-html="pcs.outTotal">
                    <!-- 累计放电量 -->
                    {{ $t("master.outTotal") }}
                  </td>
                  <td class="text-center" v-if="PcsVisibleSetting.itemp" v-html="pcs.itemp">
                    <!-- 模块温度 -->
                    {{ $t("master.itemp") }}
                  </td>
                  <td class="text-center" v-if="PcsVisibleSetting.temp" v-html="pcs.temp">
                    <!-- 环境温度 -->
                    {{ $t("master.temp") }}
                  </td>
                  <td class="text-center" v-if="PcsVisibleSetting.rfac" v-html="pcs.rfac">
                    <!-- 频率 -->
                    {{ $t("master.frequency") }}
                  </td>
                  <th class="text-center" v-if="PcsVisibleSetting.getdatetime" v-html="pcs.getdatetime">
                    <!-- getdatetime -->
                    {{ $t("master.getdatetime") }}
                  </th>
                  <td class="text-center">
                    <!-- 设备历史查询 -->
                    <el-tooltip class="item" :content="$t('master.historyQuery')" placement="bottom">
                      <router-link @click.native="JumpDeviceId" :to="{path:'/deviceReport',name:'DeviceReport',params:{'deviceId':pcs.deviceId,'deviceTypeId':checkedDeviceTypeId}}"><i class="fad fa-history"></i></router-link>
                      <!-- <a href="history" @click="JumpDeviceId(pcs, checkedDeviceTypeId)" target="_blank"><i class="fad fa-history"></i></a> -->
                    </el-tooltip>
                  </td>
                  <td class="text-center">
                    <!-- 设备明细数据 -->
                    <el-tooltip class="item" :content="$t('master.detailData')" placement="bottom">
                      <a href="javascript:void(0);" @click="show(!isMenuSon, true, 1, index)" class="right-son_toggle"><i class="fad fa-file-contract"></i></a>
                    </el-tooltip>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <!-- /.PCS -->

        <!-- DCDC -->
        <div class="body" :style="{ display: checkedDeviceTypeId == 2 ? 'block' : 'none' }">
          <div class="header">
            <h4>
              <i class="fad fa-tablet-rugged fa-2x rightbar-title"></i>
              <!-- DCDC -->
              {{ $t("deviceIcon.dcdc") }}
            </h4>
            <div class="buttons">
              <!-- 点击查看设备对比 -->
              <el-tooltip class="item" :content="$t('master.viewDeviceComparison')" placement="bottom-end" effect="dark">
                <router-link @click.native="JumpDeviceId" :to="{path:'/deviceAnalyse',name:'deviceAnalyse',params:{'deviceTypeId':checkedDeviceTypeId}}">
                  <el-button size="small" class="samll-round-btn" icon="fad fa-chart-bar" circle><span class="sr-only">
                      <!-- 设备对比 -->
                      {{ $t("master.deviceComparison") }}
                    </span></el-button>
                </router-link>
              </el-tooltip>
              <!-- 点击设置DCDC参数 -->
              <el-tooltip class="item" :content="$t('master.setDcdcParameters')" placement="bottom-end" effect="dark">
                <a href="javascript:void(0);" @click="show(false, false, 3)" data-toggle="modal" data-target=".bd-Setting-modal-xl">
                  <el-button size="small" class="samll-round-btn" icon="fad fa-cog" circle><span class="sr-only">
                      <!-- 设置 -->
                      {{ $t("master.setUp") }}
                    </span></el-button>
                </a>
              </el-tooltip>
              <!-- 点击刷新获取最新数据 -->
              <el-tooltip class="item" :content="$t('master.clickRefresh')" placement="bottom-end" effect="dark">
                <a href="javascript:void(0);" @click="detailRefresh()">
                  <el-button type="aurora" size="small" icon="fad fa-sync-alt fa-spin" circle class="samll-round-btn is-active"><span class="sr-only">
                      <!-- 刷新 -->
                      {{ $t("master.refresh") }}
                    </span></el-button>
                </a>
              </el-tooltip>
            </div>
          </div>
          <div class="table-responsive">
            <table class="table table-hover">
              <thead class="bg-dark">
                <tr>
                  <!-- <template v-for="(value, key, index) in DcdcVisibleSetting">
                    <th width="80" v-if="value" :key="index">
                      <span v-html="getLanguage(key)"></span
                      ><span class="kwh small"></span>
                    </th>
                  </template> -->
                  <th class="text-center">
                    <!-- 设备名称 -->
                    {{ $t("master.deviceName") }}
                  </th>
                  <th v-if="DcdcVisibleSetting.deviceFlag" class="text-center">
                    <!-- 状态 -->
                    {{ $t("master.state") }}
                  </th>
                  <th class="text-center" v-if="DcdcVisibleSetting.bcp">
                    <!-- 光伏发电功率  -->
                    {{ $t("master.pvPower") }}
                    <span class="kw small"></span>
                  </th>
                  <th class="text-center" v-if="DcdcVisibleSetting.dayPVTotal">
                    <!-- 今日发电量 -->
                    {{ $t("master.dayTotalPac") }}
                    <span class="kwh small"></span>
                  </th>
                  <th class="text-center" v-if="DcdcVisibleSetting.ppv">
                    <!-- 输入功率 -->
                    {{ $t("master.inPower") }}
                    <span class="kw small"></span>
                  </th>
                  <th class="text-center" v-if="DcdcVisibleSetting.pvTotal">
                    <!-- 累计发电量 -->
                    {{ $t("master.totalPac") }}
                    <span class="kwh small"></span>
                  </th>
                  <th class="text-center" v-if="DcdcVisibleSetting.ipv">
                    <!-- 输入电流 -->
                    {{ $t("master.inCurrent") }}
                    <span class="A small"></span>
                  </th>
                  <th class="text-center" v-if="DcdcVisibleSetting.vpv">
                    <!-- 输入电压 -->
                    {{ $t("master.inVoltage") }}
                    <span class="V small"></span>
                  </th>
                  <th class="text-center" v-if="DcdcVisibleSetting.itemp">
                    <!-- 模块温度 -->
                    {{ $t("master.itemp") }}
                    <span class="°C small"></span>
                  </th>
                  <th class="text-center" v-if="DcdcVisibleSetting.temp">
                    <!-- 环境温度 -->
                    {{ $t("master.temp") }}
                    <span class="°C small"></span>
                  </th>
                  <th class="text-center" v-if="DcdcVisibleSetting.getdatetime">
                    <!-- 刷新时间 -->
                    {{ $t("master.refreshTime") }}
                  </th>
                  <th class="text-center">
                    <!-- 历史 -->
                    {{ $t("master.history") }}
                  </th>
                  <th class="text-center">
                    <!-- 实时数据 -->
                    {{ $t("master.realtimeData") }}
                  </th>
                </tr>
              </thead>
              <!-- <tfoot>
                <tr>
                  <th v-if="DcdcVisibleSetting.deviceName" class="text-center">
                    设备名
                  </th>
                  <th v-if="DcdcVisibleSetting.deviceFlag" class="text-center">
                    状态
                  </th>
                  <th v-if="DcdcVisibleSetting.bcp">
                    光伏发电功率 <span class="kw small"></span>
                  </th>
                  <th v-if="DcdcVisibleSetting.dayPVTotal">
                    本日发电量 <span class="kwh small"></span>
                  </th>
                  <th v-if="DcdcVisibleSetting.ppv">
                    输入功率 <span class="kw small"></span>
                  </th>
                  <th v-if="DcdcVisibleSetting.pvTotal">
                    总累计发电量 <span class="kwh small"></span>
                  </th>
                  <th v-if="DcdcVisibleSetting.ipv">
                    输入电流 <span class="A small"></span>
                  </th>
                  <th v-if="DcdcVisibleSetting.vpv">
                    输入电压 <span class="V small"></span>
                  </th>
                  <th v-if="DcdcVisibleSetting.getdatetime">刷新时间</th>

                  <th class="text-center">历史</th>
                  <th class="text-center">实时数据</th>
                </tr>
              </tfoot> -->
              <tbody>
                <tr v-for="(dcdc, index) in DcdcDetals" :key="index">
                  <!-- <template v-for="(value, key, index) in DcdcVisibleSetting">
                    <td v-if="value" :key="index">
                      <span v-html="dcdc[key]"></span>
                    </td>
                  </template> -->

                  <td class="text-center">
                    <span>{{ dcdc.deviceName || "-" }}</span>
                    <!-- <p class="mb-0"><small>12345678</small></p> -->
                  </td>
                  <td v-if="DcdcVisibleSetting.deviceFlag" class="text-center">
                    <device-state :deviceState="dcdc.deviceFlag"></device-state>
                  </td>
                  <td class="text-center" v-if="DcdcVisibleSetting.bcp">{{ dcdc.bcp || "-" }}</td>
                  <td class="text-center" v-if="DcdcVisibleSetting.dayPVTotal">
                    {{ dcdc.dayPVTotal || "-" }}
                  </td>
                  <td class="text-center" v-if="DcdcVisibleSetting.ppv">{{ dcdc.ppv || "-" }}</td>
                  <td class="text-center" v-if="DcdcVisibleSetting.pvTotal">
                    {{ dcdc.pvTotal || "-" }}
                  </td>
                  <td class="text-center" v-if="DcdcVisibleSetting.ipv">{{ dcdc.ipv || "-" }}</td>
                  <td class="text-center" v-if="DcdcVisibleSetting.vpv">{{ dcdc.vpv || "-" }}</td>
                  <td class="text-center" v-if="DcdcVisibleSetting.getdatetime">
                    {{ dcdc.getdatetime || "-" }}
                  </td>
                  <td class="text-center" v-if="DcdcVisibleSetting.itemp">{{ dcdc.itemp || "-" }}</td>
                  <td class="text-center" v-if="DcdcVisibleSetting.temp">{{ dcdc.temp || "-" }}</td>
                  <td class="text-center">
                    <!-- 设备历史查询 -->
                    <el-tooltip class="item" :content="$t('master.historyQuery')" placement="bottom">
                      <router-link @click.native="JumpDeviceId" :to="{path:'/deviceReport',name:'DeviceReport',params:{'deviceId':dcdc.deviceId,'deviceTypeId':checkedDeviceTypeId}}"><i class="fad fa-history"></i></router-link>
                      <!-- <a href="javascript:void(0);" @click="JumpDeviceId(dcdc, checkedDeviceTypeId)"  target="_blank"><i class="fad fa-history"></i></a> -->
                    </el-tooltip>
                  </td>
                  <td class="text-center">
                    <!-- 设备明细数据 -->
                    <el-tooltip class="item" :content="$t('master.detailData')" placement="bottom">
                      <a href="javascript:void(0);" @click="show(!isMenuSon, true, 1, index)" class="right-son_toggle"><i class="fad fa-file-contract"></i></a>
                    </el-tooltip>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <!-- /.DCDC -->

        <!-- 电力计 -->
        <div class="body" :style="{ display: checkedDeviceTypeId == 5 ? 'block' : 'none' }">
          <div class="header">
            <h4>
              <i class="fad fa-tachometer-alt-fast fa-2x rightbar-title"></i>
              <!-- 电力计 -->
              {{ $t("deviceIcon.ammeter") }}
            </h4>
            <div class="buttons">
              <!-- 点击查看设备对比 -->
              <el-tooltip class="item" :content="$t('master.viewDeviceComparison')" placement="bottom-end" effect="dark">
                <router-link @click.native="JumpDeviceId" to="/deviceAnalyse">
                  <el-button size="small" class="samll-round-btn" icon="fad fa-chart-bar" circle><span class="sr-only">
                      {{ $t("master.deviceComparison") }}
                    </span></el-button>
                </router-link>
                <!-- <router-link @click.native="JumpDeviceId" :to="{path:'/deviceAnalyse',name:'deviceAnalyse',params:{'deviceTypeId':checkedDeviceTypeId}}"> <el-button size="small" class="samll-round-btn" icon="fad fa-chart-bar" circle><span class="sr-only">
                      {{ $t("master.deviceComparison") }}
                    </span></el-button></router-link> -->
              </el-tooltip>
              <!-- <el-tooltip class="item" content="点击设置电力计参数" placement="bottom-end" effect="dark">
                <a href="javascript:void(0);" @click="show(false, false, 3)" data-toggle="modal" data-target=".bd-Setting-modal-xl">
                  <el-button size="small" class="samll-round-btn" icon="fad fa-cog" circle><span class="sr-only">设置</span></el-button>
                </a>
              </el-tooltip> -->
              <!-- 点击刷新获取最新数据 -->
              <el-tooltip class="item" :content="$t('master.clickRefresh')" placement="bottom-end" effect="dark">
                <a href="javascript:void(0);" @click="detailRefresh()">
                  <el-button type="aurora" size="small" icon="fad fa-sync-alt fa-spin" circle class="samll-round-btn is-active"><span class="sr-only">
                      <!-- 刷新 -->
                      {{ $t("master.refresh") }}
                    </span></el-button>
                </a>
              </el-tooltip>
            </div>
          </div>
          <div class="table-responsive">
            <table class="table table-hover">
              <thead class="bg-dark">
                <tr>
                  <th width="80" class="text-center">
                    <!-- 设备名称 -->
                    {{ $t("master.deviceName") }}
                  </th>
                  <th class="text-center">
                    <!-- 状态 -->
                    {{ $t("master.state") }}
                  </th>
                  <th class="text-center">
                    <!-- 买电量  -->
                    {{ $t("master.gridEngery") }}
                    <span class="kwh small"></span>
                  </th>
                  <th class="text-center">
                    <!-- 卖电量  -->
                    {{ $t("master.sendEngery") }}
                    <span class="kwh small"></span>
                  </th>
                  <th class="text-center">
                    <!-- 买/卖电功率 -->
                    {{ $t("master.gridSendPower") }}
                    <span class="kw small"></span>
                  </th>
                  <th class="text-center">
                    <!-- 采集时间 -->
                    {{ $t("master.getdatetime") }}
                  </th>
                  <th class="text-center">
                    <!-- 历史 -->
                    {{ $t("master.history") }}
                  </th>
                </tr>
              </thead>
              <!-- <tfoot>
                <tr>
                  <th width="80">设备名</th>
                  <th class="text-center">状态</th>
                  <th>买电量 <span class="kwh small"></span></th>
                  <th>卖电量 <span class="kwh small"></span></th>
                  <th>买卖电功率 <span class="kw small"></span></th>
                  <th>刷新时间</th>
                  <th class="text-center">历史</th>
                </tr>
              </tfoot> -->
              <tbody>
                <tr v-for="(ammeter, index) in AmmeterDetails" :key="index">
                  <td class="text-center">
                    <span>{{ ammeter.deviceName || "-" }}</span>
                    <!-- <p class="mb-0"><small>12345678</small></p> -->
                  </td>
                  <td class="text-center">
                    <device-state :deviceState="ammeter.deviceFlag"></device-state>
                  </td>
                  <td class="text-center">{{ ammeter.dayGridEngery || "-" }}</td>
                  <td class="text-center">{{ ammeter.daySendEngery || "-" }}</td>
                  <td class="text-center">{{ ammeter.pac || "-" }}</td>
                  <td class="text-center">{{ ammeter.getdatetime || "-" }}</td>
                  <td class="text-center">
                    <!-- 设备历史查询 -->
                    <el-tooltip class="item" :content="$t('master.historyQuery')" placement="bottom">
                      <router-link @click.native="JumpDeviceId" :to="{path:'/deviceReport',name:'DeviceReport',params:{'deviceId':ammeter.deviceId,'deviceTypeId':checkedDeviceTypeId}}"><i class="fad fa-history"></i></router-link>
                      <!-- <a href="javascript:void(0)"><i class="fad fa-history"></i></a> -->
                    </el-tooltip>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <!-- /.电力计 -->

        <!-- 空调 -->
        <div class="body" :style="{ display: checkedDeviceTypeId == 4 ? 'block' : 'none' }">
          <div class="header">
            <h4>
              <i class="fad fa-snow-blowing fa-2x rightbar-title"></i>
              <!-- エアコン -->
              {{ $t("deviceIcon.ari") }}
            </h4>
            <div class="buttons">
              <!-- 点击查看设备对比 -->
              <el-tooltip class="item" :content="$t('master.viewDeviceComparison')" placement="bottom-end" effect="dark">
                <router-link @click.native="JumpDeviceId" :to="{path:'/deviceAnalyse',name:'deviceAnalyse',params:{'deviceTypeId':checkedDeviceTypeId}}">
                  <el-button size="small" class="samll-round-btn" icon="fad fa-chart-bar" circle><span class="sr-only">
                      <!-- 设备对比 -->
                      {{ $t("master.deviceComparison") }}
                    </span></el-button>
                </router-link>
              </el-tooltip>
              <!-- <el-tooltip class="item" content="点击设置空调参数" placement="bottom-end" effect="dark">
                <a href="javascript:void(0);" @click="show(false, false, 3)" data-toggle="modal" data-target=".bd-Setting-modal-xl">
                  <el-button size="small" class="samll-round-btn" icon="fad fa-cog" circle><span class="sr-only">设置</span></el-button>
                </a>
              </el-tooltip> -->
              <!-- 点击刷新获取最新数据 -->
              <el-tooltip class="item" :content="$t('master.')" placement="bottom-end" effect="dark">
                <a href="javascript:void(0);" @click="detailRefresh()">
                  <el-button type="aurora" size="small" icon="fad fa-sync-alt fa-spin" circle class="samll-round-btn is-active"><span class="sr-only">
                      <!-- 刷新 -->
                      {{ $t("master.refresh") }}
                    </span></el-button>
                </a>
              </el-tooltip>
            </div>
          </div>
          <div class="table-responsive">
            <table class="table table-hover">
              <thead class="bg-dark">
                <tr>
                  <th class="text-center" width="80">
                    <!-- 设备名称 -->
                    {{ $t("master.deviceName") }}
                  </th>
                  <th class="text-center">
                    <!-- 状态 -->
                    {{ $t("master.state") }}
                  </th>
                  <th class="text-center">
                    <!-- 室内温度 -->
                    {{ $t("master.indoorTemp") }}
                    <span class="oc small"></span>
                  </th>
                  <th class="text-center">
                    <!-- 刷新时间 -->
                    {{ $t("master.refreshTime") }}
                  </th>
                  <th class="text-center">
                    <!-- 历史 -->
                    {{ $t("master.history") }}
                  </th>
                </tr>
              </thead>
              <!-- <tfoot>
                <tr>
                  <th width="80">设备名</th>
                  <th class="text-center">状态</th>
                  <th>室温 <span class="oc small"></span></th>
                  <th>刷新时间</th>
                  <th class="text-center">历史</th>
                </tr>
              </tfoot> -->
              <tbody>
                <tr v-for="(air, index) in AirDetails" :key="index">
                  <td class="text-center">
                    <span>{{ air.deviceName || "-" }}</span>
                  </td>
                  <td class="text-center">
                    <device-state :deviceState="air.deviceFlag"></device-state>
                  </td>
                  <td class="text-center">{{ air.indoorTemp || "-" }}</td>
                  <td class="text-center">{{ air.getdatetime || "-" }}</td>
                  <td class="text-center">
                    <!-- 设备历史查询 -->
                    <el-tooltip class="item" :content="$t('master.historyQuery')" placement="bottom">
                      <router-link @click.native="JumpDeviceId" :to="{path:'/deviceReport',name:'DeviceReport',params:{'deviceId':air.device_id,'deviceTypeId':checkedDeviceTypeId}}"><i class="fad fa-history"></i></router-link>
                      <!-- <a href="history" @click="JumpDeviceId(air,checkedDeviceTypeId)" target="_blank"><i class="fad fa-history"></i></a> -->
                    </el-tooltip>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <!-- /.空调 -->

        <!-- 消防 -->
        <div class="body" :style="{ display: checkedDeviceTypeId == 6 ? 'block' : 'none' }">
          <div class="header">
            <h4>
              <i class="fad fa-fire-extinguisher fa-2x rightbar-title"></i>
              <!-- 消防系统 -->
              {{ $t("deviceIcon.fire") }}
            </h4>
            <div class="buttons">
              <!-- 点击查看设备对比 -->
              <el-tooltip class="item" :content="$t('master.viewDeviceComparison')" placement="bottom-end" effect="dark">
                <router-link @click.native="JumpDeviceId" :to="{path:'/deviceAnalyse',name:'deviceAnalyse',params:{'deviceTypeId':checkedDeviceTypeId}}">
                  <el-button size="small" class="samll-round-btn" icon="fad fa-chart-bar" circle><span class="sr-only">
                      <!-- 设备对比 -->
                      {{ $t("master.deviceComparison") }}
                    </span></el-button>
                </router-link>
              </el-tooltip>
              <!-- <el-tooltip class="item" content="点击设置消防参数" placement="bottom-end" effect="dark">
                <a href="javascript:void(0);" @click="show(false, false, 3)" data-toggle="modal" data-target=".bd-Setting-modal-xl">
                  <el-button size="small" icon="fad fa-cog" circle><span class="sr-only">设置</span></el-button>
                </a>
              </el-tooltip> -->
              <!-- 点击刷新获取最新数据 -->
              <el-tooltip class="item" :content="$t('master.clickRefresh')" placement="bottom-end" effect="dark">
                <a href="javascript:void(0);" @click="detailRefresh()">
                  <el-button type="aurora" size="small" icon="fad fa-sync-alt fa-spin" circle class="samll-round-btn is-active"><span class="sr-only">
                      <!-- 刷新 -->
                      {{ $t("master.refresh") }}
                    </span></el-button>
                </a>
              </el-tooltip>
            </div>
          </div>
          <div class="table-responsive">
            <table class="table table-hover">
              <thead class="bg-dark">
                <tr>
                  <th width="80" class="text-center">
                    <!-- 设备名称 -->
                    {{ $t("master.deviceName") }}
                  </th>
                  <th class="text-center">
                    <!-- 状态 -->
                    {{ $t("master.state") }}
                  </th>
                  <th class="text-center">
                    <!-- 采集时间 -->
                    {{ $t("master.getdatetime") }}
                  </th>
                  <th class="text-center">
                    <!-- 历史 -->
                    {{ $t("master.history") }}
                  </th>
                </tr>
              </thead>
              <!-- <tfoot>
                <tr>
                  <th width="80">设备名</th>
                  <th class="text-center">状态</th>
                  <th>刷新时间</th>
                  <th class="text-center">历史</th>
                </tr>
              </tfoot> -->
              <tbody>
                <tr>
                  <td class="text-center">
                    <span>
                      <!-- 消防 -->
                      {{ $t("deviceIcon.fire") }}
                    </span>
                  </td>
                  <td class="text-center">
                    <device-state :deviceState="FireDetails.fireStatus"></device-state>
                  </td>
                  <td>{{ FireDetails.getdatetime || "-" }}</td>
                  <td class="text-center">
                    <!-- 设备历史查询 -->
                    <el-tooltip class="item" :content="$t('master.historyQuery')" placement="bottom">
                      <router-link @click.native="JumpDeviceId" :to="{path:'/deviceReport',name:'DeviceReport',params:{'deviceId':FireDetails.deviceId,'deviceTypeId':checkedDeviceTypeId}}"><i class="fad fa-history"></i></router-link>
                      <!-- <a href="history" @click="JumpDeviceId(FireDetails, checkedDeviceTypeId)" target="_blank"><i class="fad fa-history"></i></a> -->
                    </el-tooltip>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <!-- /.消防 -->
      </div>

      <!-- 6设备实时数据子抽屉 子抽屉中的参数 不可设置-->
      <div v-bind:class="{ open: isMenuSon }" v-loading="loadingRight" id="rightbar-son" class="rightbar-son">
        <!-- 蓄电池 -->
        <div class="body" v-if="checkedDeviceTypeId == 3 && BmsDetals.length > 0">
          <!-- BMS总数据 -->
          <div class="son-header row">
            <div class="header-title col-5">
              <h5>
                <span class="fa-layers fa-fw fa-2x">
                  <i class="fad fa-circle-notch fa-spin"></i>
                  <i class="fad fa-car-battery" data-fa-transform="shrink-8 up-0.5"></i>
                </span>
                <span class="header-text">{{
                  BmsDetals[RefreshIndex].deviceName || "-"
                }}</span>
              </h5>
            </div>
            <div class="header-time col-7 text-right">
              <span class="mr-2">
                <!-- 刷新时间 -->
                {{ $t("master.refreshTime") }}
                ：{{ BmsDetals[RefreshIndex].getdatetime || "-" }}
              </span>
              <!-- content="点击刷新获取最新数据" -->
              <el-tooltip class="item" :content="$t('master.clickRefresh')" placement="bottom-end" effect="dark">
                <a href="javascript:void(0);" @click="detailRefresh()">
                  <el-button type="aurora" size="small" icon="fad fa-sync-alt fa-spin" circle class="samll-round-btn is-active"><span class="sr-only">
                      <!-- 刷新 -->
                      {{ $t("master.refresh") }}
                    </span></el-button>
                </a>
              </el-tooltip>
            </div>
          </div>
          <div class="son-body">
            <div class="table-responsive text-center">
              <table class="table">
                <thead class="bg-cyan">
                  <tr>
                    <th>
                      <!-- 状态 -->
                      {{ $t("master.state") }}
                    </th>
                    <th>
                      <!-- SOC  -->
                      {{ $t("master.soc") }}
                      <span class="percent small"></span>
                    </th>
                    <th>
                      <!-- 功率 -->
                      {{ $t("master.power") }}
                      <span class="kw small"></span>
                    </th>
                    <th v-if="this.$store.state.psTypeId!=2">
                      <!-- 放电量 -->
                      {{ $t("master.outTotalPac") }}
                      <span class="kwh small"></span>
                    </th>
                    <th v-if="this.$store.state.psTypeId!=2">
                      <!-- 充电量 -->
                      {{ $t("master.inTotalPac") }}
                      <span class="kwh small"></span>
                    </th>

                    <th v-if="this.$store.state.psTypeId==2&&1!=1">
                      <!-- 剩余电量 -->
                      {{ $t("home.remainingPower") }}
                      <span class="kwh small"></span>
                    </th>
                    <th>
                      <!-- 电压 -->
                      {{ $t("master.voltage") }}
                      <span class="V small"></span>
                    </th>
                    <th>
                      <!-- 电流 -->
                      {{ $t("master.current") }}
                      <span class="A small"></span>
                    </th>
                    <th>
                      <!-- 单体最高温度 -->
                      {{ $t("master.maxTemp") }}
                      <span class="oc small"></span>
                    </th>
                    <th>
                      <!-- 单体最低温度 -->
                      {{ $t("master.minTemp") }}
                      <span class="oc small"></span>
                    </th>
                  </tr>
                </thead>
                <!-- <tfoot>
                  <tr>
                    <th>状态</th>
                    <th>SOC <span class="percent small"></span></th>
                    <th>功率 <span class="kw small"></span></th>
                    <th>放电量 <span class="kwh small text-tomato"></span></th>
                    <th>充电量 <span class="kwh small text-grass"></span></th>
                    <th>电压 <span class="V small"></span></th>
                    <th>电流 <span class="A small"></span></th>
                    <th>
                      单体最高温度 <span class="oc small text-tomato"></span>
                    </th>
                    <th>
                      单体最低温度 <span class="oc small text-grass"></span>
                    </th>
                  </tr>
                </tfoot> -->
                <tbody>
                  <tr>
                    <td>
                      <device-state :deviceState="BmsDetals[RefreshIndex].deviceFlag"></device-state>
                    </td>
                    <td>{{ BmsDetals[RefreshIndex].soc || "-" }}</td>
                    <td>{{ BmsDetals[RefreshIndex].bcp || "-" }}</td>
                    <td v-if="this.$store.state.psTypeId!=2">{{ BmsDetals[RefreshIndex].outTotal || "-" }}</td>
                    <td v-if="this.$store.state.psTypeId!=2">{{ BmsDetals[RefreshIndex].inTotal || "-" }}</td>

                    <td v-if="this.$store.state.psTypeId==2 && 1!=1">{{ BmsDetals[RefreshIndex].rDischarge  }}</td>
                    <td>{{ BmsDetals[RefreshIndex].bv || "-" }}</td>
                    <td>{{ BmsDetals[RefreshIndex].bcc || "-" }}</td>
                    <td>{{ BmsDetals[RefreshIndex].btmax || "-" }}</td>
                    <td>{{ BmsDetals[RefreshIndex].btmin || "-" }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <!--/.BMS总数据 -->
          <!-- Rack数据 -->
          <div class="son-body mt-5">
            <el-row>
              <el-col :span="24">
                <div class="table-responsive text-left table-sm">
                  <el-table :data="RackData" border height="388" style="width: 100%">
                    <el-table-column fixed prop="Name" :label="$t('master.deviceName')" width="100">
                    </el-table-column>
                    <el-table-column prop="State" :label="$t('master.state')">
                    </el-table-column>
                    <el-table-column prop="Soc" label="" :render-header="Soc">
                    </el-table-column>
                    <el-table-column prop="Power" label="" :render-header="Power" width="90">
                    </el-table-column>
                    <el-table-column prop="TotalDischarge" v-if="this.$store.state.psTypeId!=2" label="" :render-header="TotalDischarge" width="120">
                    </el-table-column>
                    <el-table-column prop="TotalCharge" v-if="this.$store.state.psTypeId!=2" label="" :render-header="TotalCharge" width="120">
                    </el-table-column>
                    <el-table-column prop="Current" label="" :render-header="Current">
                    </el-table-column>
                    <el-table-column prop="Voltage" label="" :render-header="Voltage">
                    </el-table-column>
                    <el-table-column prop="Temperature" label="" :render-header="Temperature">
                    </el-table-column>
                    <el-table-column prop="HighTemperature" label="" :render-header="HighTemperature" width="135">
                    </el-table-column>
                    <el-table-column prop="LowTemperature" label="" :render-header="LowTemperature" width="135">
                    </el-table-column>
                    <el-table-column prop="HighVoltage" label="" :render-header="HighVoltage" width="130">
                    </el-table-column>
                    <el-table-column prop="LowVoltage" label="" :render-header="LowVoltage" width="130">
                    </el-table-column>
                  </el-table>
                </div>
              </el-col>
            </el-row>
          </div>
          <!-- /.Rack数据 -->
        </div>
        <!-- /.蓄电池组 -->

        <!-- PCS -->
        <div class="body" v-if="checkedDeviceTypeId == 1 && PcsDetals.length > 0">
          <div class="son-header row">
            <div class="header-title col-5">
              <h5>
                <span class="fa-layers fa-fw fa-2x">
                  <i class="fad fa-circle-notch fa-spin"></i>
                  <i class="fad fa-archive" data-fa-transform="shrink-8.5"></i>
                </span>
                <span class="header-text">{{
                  PcsDetals[RefreshIndex].deviceName || "-"
                }}</span>
              </h5>
            </div>
            <div class="header-time col-7 text-right">
              <span class="mr-2">
                <!-- 刷新时间 -->
                {{ $t("master.refreshTime") }}
                ：{{ PcsDetals[RefreshIndex].getdatetime || "-" }}
              </span>
              <!-- 点击刷新获取最新数据 -->
              <el-tooltip class="item" :content="$t('master.clickRefresh')" placement="bottom-end" effect="dark">
                <a href="javascript:void(0);" @click="detailRefresh()">
                  <el-button type="aurora" size="small" icon="fad fa-sync-alt fa-spin" circle class="samll-round-btn is-active"><span class="sr-only">
                      <!-- 刷新 -->
                      {{ $t("master.refresh") }}
                    </span></el-button>
                </a>
              </el-tooltip>
            </div>
          </div>
          <!-- PCS总数据 -->
          <div class="son-body">
            <div class="table-responsive text-center">
              <table class="table">
                <thead class="bg-cyan">
                  <tr>
                    <th>
                      <!-- 状态 -->
                      {{ $t("master.state") }}
                    </th>
                    <th>
                      <!-- 模块温度 -->
                      {{ $t("master.itemp") }}
                      <span class="oc small"></span>
                    </th>
                    <th>
                      <!-- 组件温度 -->
                      {{ $t("master.temp") }}
                      <span class="oc small"></span>
                    </th>
                    <th>
                      <!-- 交流输出功率 -->
                      {{ $t("master.ACOutputPower") }}
                      <span class="kw small"></span>
                    </th>
                    <th>
                      <!-- 频率 -->
                      {{ $t("master.frequency") }}
                      <span class="hz small"></span>
                    </th>
                  </tr>
                </thead>
                <!-- <tfoot>
                  <tr>
                    <th>状态</th>
                    <th>温度 <span class="oc small"></span></th>
                    <th>交流输出功率 <span class="kw small"></span></th>
                    <th>频率 <span class="hz small"></span></th>
                  </tr>
                </tfoot> -->
                <tbody>
                  <tr>
                    <td>
                      <device-state :device-state="PcsDetals[RefreshIndex].deviceFlag"></device-state>
                    </td>
                    <td>{{ PcsDetals[RefreshIndex].itemp || "-" }}</td>
                    <td>{{ PcsDetals[RefreshIndex].temp || "-" }}</td>
                    <td>{{ PcsDetals[RefreshIndex].pac || "-" }}</td>
                    <td>{{ PcsDetals[RefreshIndex].rfac || "-" }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <!-- /.PCS总数据 -->

          <div class="son-body mt-2">
            <div class="row clearfix">
              <!-- 发电量 -->
              <div class="col-lg-4 cool-md-4 col-sm-12">
                <div class="card">
                  <ul class="text-center body">
                    <li class="pb-2">
                      <el-tooltip placement="bottom" effect="dark">
                        <div slot="content">
                          <span>
                            <!-- 发电量 -->
                            {{ $t("master.electricity") }}
                          </span>
                        </div>
                        <div>
                          <span class="fa-layers fa-fw fa-4x text-lemon">
                            <i class="fad fa-spinner-third fa-spin"></i>
                            <i class="fad fa-bolt" data-fa-transform="shrink-9"></i>
                          </span>
                          <span class="sr-only">
                            <!-- 发电量 -->
                            {{ $t("master.electricity") }}
                          </span>
                        </div>
                      </el-tooltip>
                    </li>
                    <div class="dropdown-divider"></div>
                    <li class="mt-3">
                      <h5>
                        <span class="text-dark">{{
                          PcsDetals[RefreshIndex].dayPac || "-"
                        }}</span>
                        <span class="kwh small"></span>
                      </h5>
                      <span>
                        <!-- 今日发电量 -->
                        {{ $t("master.dayTotalPac") }}
                      </span>
                    </li>
                    <li class="mt-3">
                      <h5>
                        <span class="text-dark">{{ PcsDetals[RefreshIndex].totalPac || "-"
                        }}</span><span class="kwh small"></span>
                      </h5>
                      <span>
                        <!-- 累计发电量 -->
                        {{ $t("master.totalPac") }}
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
              <!-- /.发电量-->
              <!-- 充电量 -->
              <div class="col-lg-4 cool-md-4 col-sm-12">
                <div class="card">
                  <ul class="text-center body">
                    <li class="pb-2">
                      <el-tooltip placement="bottom" effect="dark">
                        <div slot="content">
                          <span>
                            <!-- 充电量 -->
                            {{ $t("master.inTotalPac") }}
                          </span>
                        </div>
                        <div>
                          <span class="fa-layers fa-fw fa-4x text-grass">
                            <i class="fad fa-spinner-third fa-spin"></i>
                            <i class="fad fa-bolt" data-fa-transform="shrink-9"></i>
                          </span>
                          <span class="sr-only">
                            <!-- 充电量 -->
                            {{ $t("master.inTotalPac") }}
                          </span>
                        </div>
                      </el-tooltip>
                    </li>
                    <div class="dropdown-divider"></div>
                    <li class="mt-3">
                      <h5>
                        <span class="text-dark">{{
                          PcsDetals[RefreshIndex].dayInTotal || "-"
                        }}</span>
                        <span class="kwh small"></span>
                      </h5>
                      <span>
                        <!-- 今日充电量 -->
                        {{ $t("master.dayInTotalPac") }}
                      </span>
                    </li>
                    <li class="mt-3">
                      <h5> <span class="text-dark">
                          {{ PcsDetals[RefreshIndex].inTotal || "-" }}</span>
                        <span class="kwh small"></span>
                      </h5>
                      <span>
                        <!-- 累计充电量 -->
                        {{ $t("master.inTotal") }}
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
              <!-- /.充电量-->
              <!-- 放电量 -->
              <div class="col-lg-4 cool-md-4 col-sm-12">
                <div class="card">
                  <ul class="text-center body">
                    <li class="pb-2">
                      <el-tooltip placement="bottom" effect="dark">
                        <div slot="content">
                          <span>
                            <!-- 放电量 -->
                            {{ $t("master.outTotalPac") }}
                          </span>
                        </div>
                        <div>
                          <span class="fa-layers fa-fw fa-4x text-tomato">
                            <i class="fad fa-spinner-third fa-spin"></i>
                            <i class="fad fa-bolt" data-fa-transform="shrink-9 "></i>
                          </span>
                          <span class="sr-only">
                            <!-- 放电量 -->
                            {{ $t("master.outTotalPac") }}
                          </span>
                        </div>
                      </el-tooltip>
                    </li>
                    <div class="dropdown-divider"></div>
                    <li class="mt-3">
                      <h5>
                        <span class="text-dark">{{
                          PcsDetals[RefreshIndex].dayOutTotal || "-"
                        }}</span>
                        <span class="kwh small"></span>
                      </h5>
                      <span>
                        <!-- 今日放电量 -->
                        {{ $t("master.dayOutTotalPac") }}
                      </span>
                    </li>
                    <li class="mt-3">
                      <h5><span class="text-dark">
                          {{ PcsDetals[RefreshIndex].outTotal || "-" }}</span>
                        <span class="kwh small"></span>
                      </h5>
                      <span>
                        <!-- 累计放电量 -->
                        {{ $t("master.outTotal") }}
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
              <!-- /.放电量-->
            </div>
          </div>

          <div class="son-body mt-4">
            <el-row :gutter="5">
              <!-- AC数据 显示所有电路信息 -->
              <el-col :span="9">
                <div class="table-responsive table-sm">
                  <el-table :data="PcsDataAC" border height="152">
                    <el-table-column label="AC">
                      <el-table-column prop="Name" label="/" width="50">
                      </el-table-column>
                      <el-table-column prop="Voltage" label="" :render-header="Voltage">
                      </el-table-column>
                      <el-table-column prop="Current" label="" :render-header="Current">
                      </el-table-column>
                    </el-table-column>
                  </el-table>
                </div>
              </el-col>
              <!-- /.AC数据 -->
              <!-- DC数据 显示所有电路信息 -->
              <el-col :span="15">
                <div class="table-responsive table-sm">
                  <el-table :data="PcsDataDC" border height="152">
                    <el-table-column label="DC">
                      <el-table-column prop="Name" label="/"> </el-table-column>
                      <el-table-column prop="Voltage" label="" :render-header="Voltage">
                      </el-table-column>
                      <el-table-column prop="Current" label="" :render-header="Current">
                      </el-table-column>
                      <el-table-column prop="Power" label="" :render-header="Power">
                      </el-table-column>
                    </el-table-column>
                  </el-table>
                </div>
              </el-col>
              <!-- /.DC数据 -->
            </el-row>
          </div>
        </div>
        <!-- /.PCS -->

        <!-- DCDC -->
        <div class="body" v-if="checkedDeviceTypeId == 2 && DcdcDetals.length > 0">
          <!-- DCDC总数据 -->
          <div class="son-header row">
            <div class="header-title col-5">
              <h5>
                <span class="fa-layers fa-fw fa-2x">
                  <i class="fad fa-circle-notch fa-spin"></i>
                  <i class="fad fa-tablet-rugged" data-fa-transform="shrink-8"></i>
                </span>
                <span class="header-text">{{
                  DcdcDetals[RefreshIndex].deviceName || "-"
                }}</span>
              </h5>
            </div>
            <div class="header-time col-7 text-right">
              <span class="mr-2">
                <!-- 刷新时间 -->
                {{ $t("master.refreshTime") }}
                ：{{ DcdcDetals[RefreshIndex].getdatetime || "-" }}
              </span>
              <el-tooltip class="item" content="点击刷新获取最新数据" placement="bottom-end" effect="dark">
                <a href="javascript:void(0);" @click="detailRefresh()">
                  <el-button type="aurora" size="small" icon="fad fa-sync-alt fa-spin" circle class="samll-round-btn is-active"><span class="sr-only">刷新</span></el-button>
                </a>
              </el-tooltip>
            </div>
          </div>
          <div class="son-body">
            <div class="table-responsive text-center">
              <table class="table">
                <thead class="bg-cyan">
                  <tr>
                    <th>
                      <!-- 状态 -->
                      {{ $t("master.state") }}
                    </th>
                    <th>
                      <!-- 模块温度 -->
                      {{ $t("master.itemp") }}
                      <span class="oc small"></span>
                    </th>
                    <th>
                      <!-- 组件温度 -->
                      {{ $t("master.temp") }}
                      <span class="oc small"></span>
                    </th>
                    <th>
                      <!-- 光伏发电功率 -->
                      {{ $t("master.pvPower") }}
                      <span class="kw small"></span>
                    </th>
                    <th>
                      <!-- 本日发电量 -->
                      {{ $t("master.dayTotalPac") }}
                      <span class="kwh small"></span>
                    </th>
                    <th>
                      <!-- 累计发电量 -->
                      {{ $t("master.totalPac") }}
                      <span class="kwh small"></span>
                    </th>
                    <th>
                      <!-- 输入功率 -->
                      {{ $t("master.inPower") }}
                      <span class="kw small"></span>
                    </th>
                    <th>
                      <!-- 输入电流 -->
                      {{ $t("master.inCurrent") }} <span class="A small"></span>
                    </th>
                    <th>
                      <!-- 输入电压 -->
                      {{ $t("master.inVoltage") }} <span class="V small"></span>
                    </th>
                  </tr>
                </thead>
                <!-- <tfoot>
                  <tr>
                    <th>状态</th>
                    <th>光伏发电功率 <span class="kw small"></span></th>
                    <th>本日发电量 <span class="kwh small"></span></th>
                    <th>总累计发电量 <span class="kwh small"></span></th>
                    <th>总输入功率 <span class="kw small"></span></th>
                    <th>输入电流 <span class="A small"></span></th>
                    <th>输入电压 <span class="V small"></span></th>
                  </tr>
                </tfoot> -->
                <tbody>
                  <tr>
                    <td>
                      <device-state :deviceState="DcdcDetals[RefreshIndex].deviceFlag"></device-state>
                    </td>
                    <td>{{ DcdcDetals[RefreshIndex].itemp || "-" }}</td>
                    <td>{{ DcdcDetals[RefreshIndex].temp || "-" }}</td>
                    <td>{{ DcdcDetals[RefreshIndex].bcp || "-" }}</td>
                    <td>{{ DcdcDetals[RefreshIndex].dayPVTotal || "-" }}</td>
                    <td>{{ DcdcDetals[RefreshIndex].pvTotal || "-" }}</td>
                    <td>{{ DcdcDetals[RefreshIndex].ppv || "-" }}</td>
                    <td>{{ DcdcDetals[RefreshIndex].ipv || "-" }}</td>
                    <td>{{ DcdcDetals[RefreshIndex].vpv || "-" }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <!--/.DCDC总数据 -->
          <!-- PV数据 -->
          <div class="son-body mt-5">
            <el-row>
              <el-col :span="24">
                <div class="table-responsive text-left table-sm">
                  <el-table :data="PvData" border height="388px" style="width: 100%">
                    <el-table-column prop="Name" label="" :render-header="DeviceName" width="100">
                    </el-table-column>
                    <el-table-column prop="Power" label="" :render-header="Power" width="90">
                    </el-table-column>
                    <el-table-column prop="Current" label="" :render-header="Current">
                    </el-table-column>
                    <el-table-column prop="Voltage" label="" :render-header="Voltage">
                    </el-table-column>
                  </el-table>
                </div>
              </el-col>
            </el-row>
          </div>
          <!-- /.PV数据 -->
        </div>
        <!-- /.DCDC -->

        <!-- 电力计 暂无子抽屉-->
        <!-- <div class="body" style="display:none">
          <div class="header">
            <h2>Rack 1</h2>
          </div>
        </div> -->
        <!-- /.电力计 -->

        <!-- 空调 暂无子抽屉-->
        <!-- <div class="body" style="display:none">
          <div class="header">
            <h2>Rack 1</h2>
          </div>
        </div> -->
        <!-- /.空调 -->

        <!-- 消防 暂无子抽屉-->
        <!-- <div class="body" style="display:none">
          <div class="header">
            <h2>Rack 1</h2>
          </div>
        </div> -->
        <!-- /.消防 -->
      </div>

      <!-- 月报表弹框 -->
      <!-- 故障 -->
      <!-- <div class="modal fade bd-SirenListMonth-modal-lg" tabindex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-lg">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title h4" id="myLargeModalLabel">
                <span class="fa-layers fa-fw fa-2x">
                  <i class="fad fa-tools text-tomato"></i>
                </span>
                <span>2021 <i class="fad fa-scrubber xx-small"></i>
                  <span class="xx-large">12</span>
                  <i class="fad fa-scrubber xx-small"></i>
                  <span class="xx-large">1</span></span>
              </h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true"><i class="fal fa-times"></i></span>
              </button>
            </div>
            <div class="modal-body">
              123
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-default" data-dismiss="modal">
                关闭
              </button>
            </div>
          </div>
        </div>
      </div> -->
      <!-- 年报表弹框 -->
      <!-- <div class="modal fade bd-SirenListYear-modal-lg" tabindex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-lg">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title h4" id="myLargeModalLabel">
                <span>2021 <i class="fad fa-scrubber xx-small"></i>
                  <span class="xx-large">12</span>
                  <i class="fad fa-scrubber xx-small"></i>
                  <span class="xx-large">1</span></span>
              </h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true"><i class="fal fa-times"></i></span>
              </button>
            </div>
            <div class="modal-body">
              123
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-default" data-dismiss="modal">
                关闭
              </button>
            </div>
          </div>
        </div>
      </div> -->

      <!-- Main body part  -->
      <router-view ref="home" @bindPsInfo="handleBindPsInfo" @parentPsId="getPsId" @changeParent="getParent" @census="getCensus" @soip="getSoipSetting"></router-view>
    </div>
  </div>
</template>

<script>
// 拖动配置表
/* tableDragger(document.querySelector("#default-table")); */
</script>

<script>
import Loading from "./children/Loading.vue";
import Navs from "./children/Navs.vue";
import Sidebar from "./children/Sidebar.vue";
import DeviceState from "./Master/DeviceState.vue";
import VisibleSetting from "./Master/VisibleSetting.vue";
import GroupSirenType from "../components/AlarmView/GroupSirenType.vue";
import SelectPsIdView from "../components/HistoryView/SelectPsId.vue";

export default {
  name: "Master",
  components: {  
    Loading,
    Navs,
    Sidebar,
    DeviceState,
    VisibleSetting,
    GroupSirenType,
    SelectPsIdView
  },
  data() {
    return {
      oldIndex:null,
      loadData:"",
      aa:false,
      loadingRight: false,
      loadingPsInfo: false,
      bindShow: false,
      pickerOptions: {
        disabledDate(time) {
          let buildDate = window.sessionStorage.getItem("buildDate");
          console.log(buildDate);
          let dts = buildDate.split("-");
          let dt = new Date(
            parseInt(dts[0]),
            parseInt(dts[1]) - 1,
            parseInt(dts[2]) - 1
          );
          return time.getTime() >= Date.now() || time.getTime() <= dt.getTime();
        }
      },

      //表格PV数据
      PvData: [],

      //表格Rack数据
      RackData: [
        {
          Name: "-",
          State: "-",
          Soc: "-",
          Power: "-",
          TotalDischarge: "-",
          TotalCharge: "-",
          Current: "-",
          Voltage: "-",
          Temperature: "-",
          HighTemperature: "-",
          LowTemperature: "-",
          HighVoltage: "-",
          LowVoltage: "-"
        }
      ],

      //表格AC数据
      PcsDataAC: [
        {
          Name: "A",
          Voltage: null,
          Current: null
        },
        {
          Name: "B",
          Voltage: null,
          Current: null
        },
        {
          Name: "C",
          Voltage: null,
          Current: null
        }
      ],

      //表格DC数据
      PcsDataDC: [
        {
          Name: null,
          Voltage: null,
          Current: null,
          Power: null
        }
      ],

      //PCS明细数据
      PcsDetals: [
        {
          deviceName: null,
          dayInTotal: null,
          dayOutTotal: null,
          dayPac: null,
          deviceFlag: null,
          getdatetime: null,
          inTotal: null,
          itemp: null,
          temp: null,
          outTotal: null,
          pac: null,
          rfac: null,
          totalPac: null,
          riac: null,
          rvac: null,
          siac: null,
          svac: null,
          tiac: null,
          tvac: null
        }
      ],
      //DCDC明细数据
      DcdcDetals: [
        {
          deviceName: null,
          deviceFlag: null,
          bcp: null,
          dayPVTotal: null,
          pvTotal: null,
          vpv: null,
          ipv: null,
          ppv: null,
          getdatetime: null,
          ppvs: [],
          ipvs: [],
          vpvs: [],
          itemp:null,
          temp:null
        }
      ],
      //Bms明细数据
      BmsDetals: [
        {
          deviceName: null,
          deviceFlag: null,
          soc: null,
          bcp: null,
          outTotal: null,
          inTotal: null,
          bv: null,
          bcc: null,
          btmax: null,
          btmin: null,
          getdatetime: null,
          mInTotals: [],
          mOutTotals: [],
          mSocs: [],
          mcps: [],
          mcs: [],
          mtMaxs: [],
          mtMins: [],
          mts: [],
          mvMaxs: [],
          mvMins: [],
          mvs: []
        }
      ],

      //电表明细数据
      AmmeterDetails: [
        {
          deviceName: null,
          deviceFlag: null,
          pac: null,
          dayGridEngery: null,
          daySendEngery: null,
          getdatetime: null
        }
      ],
      //空调明细数据
      AirDetails: [
        {
          deviceName: null,
          deviceFlag: null,
          indoorTemp: null,
          humidity: null,
          getdatetime: null
        }
      ],
      //消防明细数据
      FireDetails: {
        fireStatus: null,
        getdatetime: null
      },
      //PCS数据显示配置设置
      PcsVisibleSetting: {
        deviceName: true,
        dayInTotal: true,
        dayOutTotal: true,
        dayPac: true,
        deviceFlag: true,
        getdatetime: false,
        inTotal: false,
        itemp: false,
        outTotal: false,
        pac: true,
        rfac: false,
        totalPac: false
      },
      //DCDC数据显示配置设置
      DcdcVisibleSetting: {
        deviceName: true,
        deviceFlag: true,
        bcp: true,
        dayPVTotal: true,
        pvTotal: true,
        vpv: false,
        ipv: false,
        ppv: false,
        getdatetime: false
      },
      //Bms数据显示配置设置
      BmsVisibleSetting: {
        deviceName: true,
        deviceFlag: true,
        soc: true,
        bcp: true,
        outTotal: true,
        inTotal: false,
        bv: false,
        bcc: false,
        btmax: false,
        btmin: false,
        getdatetime: false
      },
      ParamsLanguage: {
        deviceName: "master.deviceName",//this.$t("master.deviceName"), //"设备名称",
        dayInTotal: "master.dayInTotalPac",//this.$t("master.dayInTotalPac"), //"今日充电",
        dayOutTotal: "master.dayOutTotalPac",//this.$t("master.dayOutTotalPac"), //"今日放电",
        dayPac: "master.dayTotalPac",//this.$t("master.dayTotalPac"), //"今日发电",
        deviceFlag: "master.state",//this.$t("master.state"), //"状态",
        getdatetime: "master.refreshTime",//this.$t("master.refreshTime"), //"刷新时间",
        inTotal: "master.inTotalPac",//this.$t("master.inTotalPac"), //"充电量",
        itemp: "master.itemp",//this.$t("master.temp"), //"模块温度",
        temp: "master.temp",//this.$t("master.temp"), //"环境温度",
        outTotal: "master.outTotalPac",//this.$t("master.outTotalPac"), //"放电量",
        pac: "AC",
        rfac: "master.frequency",//this.$t("master.frequency"), //"频率",
        totalPac: "master.totalPac",//this.$t("master.totalPac"), //"累计发电量",
        bcp: "master.pvPower",//this.$t("master.pvPower"), //"光伏发电功率",
        dayPVTotal: "master.dayTotalPac",//this.$t("master.dayTotalPac"), //"本日发电量",
        pvTotal: "master.totalPac",//this.$t("master.totalPac"), //"总累计发电量",
        vpv: "master.inVoltage",//this.$t("master.inVoltage"), //"输入电压",
        ipv: "master.inCurrent",//this.$t("master.inCurrent"), //"输入电流",
        ppv: "master.inPower",//this.$t("master.inPower"), //"输入功率",
        soc: "master.soc",//this.$t("master.soc"), //"SOC",
        bcp: "master.inOutPower",//this.$t("master.inOutPower"), //"充放电功率",
        bv: "master.voltage",//this.$t("master.voltage"), //"电压",
        bcc: "master.current",//this.$t("master.current"), //"电流",
        btmax: "master.maxTempa",//this.$t("master.maxTempa"), //"最高温度",
        btmin: "master.minTempa",//this.$t("master.minTempa") //"最低温度"
      },
      //选中的数据（点击刷新数据时，底下列表数据需要）
      RefreshIndex: 0,
      isMenuSon: false,
      isMenuParent: false,
      quickDeviceType: [],
      quickIcon: {
        "1": "fad fa-archive", //PCS
        "3": "fad fa-car-battery", //蓄電池
        "2": "fad fad fa-tablet-rugged", //DCDC
        "5": "fad fad fa-tachometer-alt-fast", //电表
        "4": "fad fa-snow-blowing", //空调
        "6": "fad fa-fire-extinguisher" //消防
      },
      deviceTypeName: {
        "1": "deviceIcon.pcs",
        "2": "deviceIcon.dcdc",
        "3": "deviceIcon.bms",
        "4": "deviceIcon.ari",
        "5":"deviceIcon.ammeter",
        "6":"deviceIcon.bms"
      },
      census: [],
      censusItemsIcon: {
        failureNumName: "home.fault", //this.$t("home.fault"), //"报警 | 故障",
        offlineNumName: "home.offLine",//this.$t("home.offLine"), //"报警 | 离线",
        stopNumName: "home.shutDown",//this.$t("home.shutDown"), //"报警 | 停机",
        abnormalNumName:"home.abnormal",//this.$t("home.inOutPower") //"功率 | 充放电功率"
        dayInTotalName:"home.dayInPower",//this.$t("home.dayInPower"), //"电力量 | 日充电量",
        dayOutTotalName: "home.dayOutPower",//this.$t("home.dayOutPower"), //"电力量 | 日放电量",
        monthInTotalName: "home.monthInPower",//this.$t("home.monthInPower"), //"电力量 | 月充电量",
        monthOutTotalName:"home.monthOutPower",//this.$t("home.monthOutPower"), //"电力量 | 月放电量",
        dayTotalName: "home.dayTotalPac",//this.$t("home.dayTotalPac"), //"PV发电量 | 日发电量",
        monthTotalName:"home.monthTotalPac",//this.$t("home.monthTotalPac"), //"PV发电量 | 月发电量",
        yearTotalName: "home.yearTotalPac",//this.$t("home.yearTotalPac"), //"PV发电量 | 年发电量",
        totalName: "home.totalPac",//this.$t("home.totalPac"), //"PV发电量 | 总发电量",
        dayCo2Name:"home.dayReduce",//this.$t("home.dayReduce"), //"节能减排 | 本日减排",
        monthCo2Name:"home.monthReduce",//this.$t("home.monthReduce"), //"节能减排 | 本月减排",
        yearCo2Name:"home.yearReduce",//this.$t("home.yearReduce"), //"节能减排 | 今年减排",
        totalCo2Name:"home.totalReduce",//this.$t("home.totalReduce"), //"节能减排 | 总减排",
        dcdcPacName:"home.pvPower",//this.$t("home.pvPower"), //"功率 | PV功率",
        pcsPacName:"home.inOutPower",//this.$t("home.inOutPower") //"功率 | 充放电功率"
       

        // failureNumName: this.$t("home.warning") + " | " + this.$t("home.fault"), //"报警 | 故障",
        // offlineNumName:
        //   this.$t("home.warning") + " | " + this.$t("home.offLine"), //"报警 | 离线",
        // stopNumName: this.$t("home.warning") + " | " + this.$t("home.shutDown"), //"报警 | 停机",
        // dayInTotalName:
        //   this.$t("home.electricity") + " | " + this.$t("home.dayInPower"), //"电力量 | 日充电量",
        // dayOutTotalName:
        //   this.$t("home.electricity") + " | " + this.$t("home.dayOutPower"), //"电力量 | 日放电量",
        // monthInTotalName:
        //   this.$t("home.electricity") + " | " + this.$t("home.monthInPower"), //"电力量 | 月充电量",
        // monthOutTotalName:
        //   this.$t("home.electricity") + " | " + this.$t("home.monthOutPower"), //"电力量 | 月放电量",
        // dayTotalName:
        //   this.$t("home.pvTotalPac") + " | " + this.$t("home.dayTotalPac"), //"PV发电量 | 日发电量",
        // monthTotalName:
        //   this.$t("home.pvTotalPac") + " | " + this.$t("home.monthTotalPac"), //"PV发电量 | 月发电量",
        // yearTotalName:
        //   this.$t("home.pvTotalPac") + " | " + this.$t("home.yearTotalPac"), //"PV发电量 | 年发电量",
        // totalName:
        //   this.$t("home.pvTotalPac") + " | " + this.$t("home.totalPac"), //"PV发电量 | 总发电量",
        // dayCo2Name: this.$t("home.reduce") + " | " + this.$t("home.dayReduce"), //"节能减排 | 本日减排",
        // monthCo2Name:
        //   this.$t("home.reduce") + " | " + this.$t("home.monthReduce"), //"节能减排 | 本月减排",
        // yearCo2Name:
        //   this.$t("home.reduce") + " | " + this.$t("home.yearReduce"), //"节能减排 | 今年减排",
        // totalCo2Name:
        //   this.$t("home.reduce") + " | " + this.$t("home.totalReduce"), //"节能减排 | 总减排",
        // dcdcPacName: this.$t("home.power") + " | " + this.$t("home.pvPower"), //"功率 | PV功率",
        // pcsPacName: this.$t("home.power") + " | " + this.$t("home.inOutPower") //"功率 | 充放电功率"
      },
      censusIcon: {
        alarm: {
          name: "home.warning"//this.$t("home.warning") //"报警"
          //"item": {"failureNumName": "故障","offlineNumName": "离线","stopNumName": "停机"}
        },
        pvDcdc: {
          name: "home.pvTotalPac"//this.$t("home.pvTotalPac") //"PV发电量"
          //"item": {"dayTotalName": "発電 | 本日の発電量","monthTotalName": "発電 | 本月の発電量","yearTotalName": "発電 | 本年の発電量","totalName": "発電 | 発電所の収益"}
        },
        gridPcs: {
          name: "home.electricity"//this.$t("home.electricity") //"电力量"
          //"item": {"dayProfitName": "収益 | 本日の収益","monthProfitName": "収益 | 本月の収益","yearProfitName": "収益 | 今年の収益","totalProfitName": "収益 | 累計の収益"}
        },
        co2: {
          name: "home.reduce"//this.$t("home.reduce") //"节能减排"
          //"item":{"dayCo2Name": "CO2排出削減 | 本日の削減","monthCo2Name": "CO2排出削減 | 本月の削減","yearCo2Name": "CO2排出削減 | 本年の削減","totalCo2Name": "CO2排出削減 | 累計の削減"}
        },

        power: {
          name: "home.power"//this.$t("home.power") //, "功率"
          //"item": {"todayName": "今日功率","yesterdayName": "昨日功率"}
        }
      },

      soipSetting: [],
      soipTitle: {
        storage: {
          name: "home.energyStorage"//this.$t("home.energyStorage") //"蓄能"
        },
        outPut: {
          name: "home.electricPower"//this.$t("home.electricPower") //"发电"
        },
        inPut: {
          name: "home.load"//this.$t("home.load") //"负载"
        },
        pGrid: {
          name: "home.powerGrid"//this.$t("home.powerGrid") //"电网"
        },
        ammeter: {
          name: "home.userAmmeter.title"//this.$t("home.powerGrid") //"用户电表"
        }
      },

      soipItemsTitle: {
        storage_soc: "master.soc",//this.$t("master.soc"), //"soc",
        storage_bcp: "master.inOutPower",//this.$t("master.inOutPower"), //"充/放电功率",
        storage_discharge: "home.remainingPower",//this.$t("home.remainingPower"), //"剩余电量",
        storage_devicePower: "home.batteryCapacity",//this.$t("home.batteryCapacity"), //"电池容量",
        outPut_pac: "home.realtimePower",//this.$t("home.realtimePower"), //"实时功率",
        outPut_devicePower: "home.dcdcCapacity",//this.$t("home.dcdcCapacity"), //"DC/DC容量",
        outPut_dayPVTotal: "home.dayPVTotal",//this.$t("home.realtimePower"), //"实时功率",
        inPut_pac: "home.realtimePowerUseUp",//this.$t("home.realtimePowerUseUp"), //"实时消耗功率",
        inPut_dayTotal: "home.dayPowerUseUp",//this.$t("home.dayPowerUseUp"), //"今日消耗电量",
        pGrid_pac: "home.realtimePower",//this.$t("home.realtimePower"), //"买/卖电功率",
        pGrid_inOutStatus: "home.realtimePower",//this.$t("home.gridSendState") //"买/卖电状态"
        pGrid_dayGridEngery: "home.dayGridEngery",//this.$t("home.gridSendState") //"买电量
        pGrid_daySendEngery: "home.daySendEngery",//this.$t("home.gridSendState") //"卖电量
        ammeter_power:"home.userAmmeter.power",//功率
        ammeter_dayGridEngery:"home.userAmmeter.dayGridEngery",//电表日买电量
        ammeter_daySendEngery:"home.userAmmeter.daySendEngery"//电表日卖电量
      },
      checkedDeviceTypeId: 0,
      //统计参数所有模块中参数
      censusItems: [],
      //统计参数所有模块中参数最多的一个
      maxLen: 0,

      soipMaxLen: 0,
      soipItems: [],
      checkedMax: 4,
      //统计参数所有模块中参数最多的一个
      btnSoip: true,
      //统计参数所有模块中参数最多的一个
      btnCensus: true,
      t1: 1
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    handlerLoadingPsInfo() {
      this.loadingPsInfo = true;
    },
    handleBindPsInfo(bindShow) {
      this.bindShow = bindShow;
    },
    //获取故障历史弹框值
    handleErrMasterClick(day, errType) {
      console.info("day", day);
      console.info("errType", errType);
    },
    //DC表格表头样式自定义
    Soc(h) {
      return (
        //SOC字段名样式
        <span>
          <span>{this.$t("master.soc")}</span>{" "}
          <span class="percent small"></span>
        </span>
      );
    },
    DeviceName(h) {
      return (
        //设备名称
        <span>
          <span>{this.$t("master.deviceName")}</span> <span class="V small"></span>
        </span>
      );
    },
    Voltage(h) {
      return (
        //电压字段名样式
        <span>
          <span>{this.$t("master.voltage")}</span> <span class="V small"></span>
        </span>
      );
    },
    Current(h) {
      return (
        //电流字段名样式
        <span>
          <span>{this.$t("master.current")}</span> <span class="A small"></span>
        </span>
      );
    },
    Power(h) {
      return (
        //功率字段名样式
        <span>
          <span>{this.$t("master.power")}</span> <span class="kw small"></span>
        </span>
      );
    },
    TotalDischarge(h) {
      return (
        //总放电量字段名样式
        <span>
          <span>{this.$t("master.outTotal")}</span>{" "}
          <span class="kwh small text-tomato"></span>
        </span>
      );
    },
    TotalCharge(h) {
      return (
        //总充电量字段名样式
        <span>
          <span>{this.$t("master.inTotal")}</span>{" "}
          <span class="kwh small text-grass"></span>
        </span>
      );
    },
    Temperature(h) {
      return (
        //温度字段名样式
        <span>
          <span>{this.$t("master.temp")}</span> <span class="oc small"></span>
        </span>
      );
    },
    HighTemperature(h) {
      return (
        //单体最高温度字段名样式
        <span>
          <span>{this.$t("master.maxTemp")}</span>{" "}
          <span class="oc small text-tomato"></span>
        </span>
      );
    },
    LowTemperature(h) {
      return (
        //单体最低温度字段名样式
        <span>
          <span>{this.$t("master.minTemp")}</span>{" "}
          <span class="oc small text-grass"></span>
        </span>
      );
    },
    HighVoltage(h) {
      return (
        //单体最大电压字段名样式
        <span>
          <span>{this.$t("master.maxVoltage")}</span>{" "}
          <span class="V small text-tomato"></span>
        </span>
      );
    },
    LowVoltage(h) {
      return (
        //单体最小电压字段名样式
        <span>
          <span>{this.$t("master.mimVoltage")}</span>{" "}
          <span class="V small text-grass"></span>
        </span>
      );
    },
    SearchStation(h) {
      return (
        //告警弹框电站搜索
        <span>-</span>
      );
    },

    clickMe() {
      console.log(JSON.stringify(this.censusItems));
    },
    getPsId() {
      this.$refs.sidebar.getQuickDeviceType();
    },
    censuskey(name, group) {
      //随机生成六位数字
      var randomNum = ("000000" + Math.floor(Math.random() * 999999)).slice(-6);
      return name + "_" + group + "_" + randomNum;
    },
    soipkey(name) {
      //随机生成六位数字
      var randomNum = ("000000" + Math.floor(Math.random() * 999999)).slice(-6);
      return name + "_" + randomNum;
    },

    init: function() {
      //tableDragger(document.querySelector("#default-table"));
      // Only Body
      //tableDragger(document.querySelector("#only-bodytable"));
    },

    /**弹出对应设备类型实时明细数据 */
    getParent(deviceTypeId) {
      /**显示指定类型的模板 */
      this.checkedDeviceTypeId = deviceTypeId;
      this.isMenuSon = false;
      this.isMenuParent = true;
      this.loadingRight = true;
      //加载设备类型信息00001
      switch (deviceTypeId) {
        case 1:
          this.getPcsDetails();
          this.getPcsVisible();
          break;
        case 2:
          this.getDcdcDetails();
          this.getDcdcVisible();
          break;
        case 3:
          this.getBmsDetails();
          this.getBmsVisible();
          break;
        case 4:
          this.getAirDetails();
          break;
        case 5:
          this.getAmmeterDetails();

          break;
        case 6:
          this.getFireDetails();
          break;
        default:
          break;
      }
    },
    // 从Sidebar子模块中获取快捷设备类型设置
    getQuickDeviceType(quick) {
      this.quickDeviceType = quick;
      for (let i = 0; i < this.quickDeviceType.length; i++) {
        this.quickDeviceType[i]["checked"] =
          this.quickDeviceType[i]["isVisible"] == 1 ? true : false;
      }
    },
    // 从Home子模块中获取统计模块设置信息
    getCensus(census) {
      console.log("第几次:" + this.t1);
      this.censusItems = [];
      this.census = census;
      let censusTemp = [];
      if (this.census != null) {
        for (let i = 0; i < this.census.length; i++) {
          this.census[i]["checked"] =
            this.census[i]["isVisible"] == 1 ? true : false;

          let censusItem = {};
          censusItem["index"] = this.census[i]["index"];
          censusItem["checked"] = this.census[i]["checked"];
          censusItem["interval"] = this.census[i]["interval"];
          censusItem["name"] = this.census[i]["name"];
          censusItem["items"] = [];
          censusTemp.push(censusItem);

          let items = this.census[i]["items"];
          if (this.maxLen < items.length) {
            this.maxLen = items.length;
          }
          for (let j = 0; j < items.length; j++) {
            items[j]["checked"] = items[j]["isVisible"] == 1 ? true : false;
          }
        }

        for (let j = 0; j < this.maxLen; j++) {
          let temp = { row: j + 1 };
          temp["items"] = [];
          for (let i = 0; i < censusTemp.length; i++) {
            let items = this.census[i]["items"];
            let groupName = this.census[i]["name"];
            if (typeof items[j] != "undefined") {
              items[j]["groupName"] = groupName;
              temp["items"].push(items[j]);
            } else {
              temp["items"].push({});
            }
          }
          this.censusItems.push(temp);
        }
        let censusItemsTemp = this.censusItems;
        if (this.t1 > 1) {
          console.log("重新加载数据....");
          this.censusItems = [];
          //let row={"row":1,"items":[{"name":"failureNum1","isVisible":"1","active":"active","checked":true,"groupName":"alarm"},{"name":"dayTotal1","isVisible":"1","active":"active","checked":true,"groupName":"pvDcdc"}]}
          this.censusItems = censusItemsTemp;
          this.$forceUpdate();
        } else this.censusItems = Object.assign({}, this.censusItems);
        console.log(JSON.stringify(this.censusItems));
        this.t1++;
      }
    },

    // 从Home子模块中获取储、发、并、负设置信息
    getSoipSetting(soip) {
      this.soipSetting = soip;
     
      //  this.censusItems=[];
      // this.census=census;
      let soipSettingTemp = [];
      if (this.soipSetting != null) {
        for (let i = 0; i < this.soipSetting.length; i++) {
          this.soipSetting[i]["checked"] =
            this.soipSetting[i]["isVisible"] == 1 ? true : false;

          let soipSettingItem = {};
          soipSettingItem["index"] = this.soipSetting[i]["index"];
          soipSettingItem["checked"] = this.soipSetting[i]["checked"];
          soipSettingItem["name"] = this.soipSetting[i]["name"];
          soipSettingItem["items"] = [];
          soipSettingTemp.push(soipSettingItem);

          let items = this.soipSetting[i]["items"];
          if (this.soipMaxLen < items.length) {
            this.soipMaxLen = items.length;
          }
          for (let j = 0; j < items.length; j++) {
            items[j]["checked"] = items[j]["isVisible"] == 1 ? true : false;
          }
        }

        this.soipItems = [];
        for (let j = 0; j < this.soipMaxLen; j++) {
          let temp = { row: j + 1 };
          temp["items"] = [];
          for (let i = 0; i < soipSettingTemp.length; i++) {
            let items = this.soipSetting[i]["items"];
            let groupName = this.soipSetting[i]["name"];
            if (typeof items[j] != "undefined") {
              items[j]["groupName"] = groupName;
              temp["items"].push(items[j]);
            } else {
              temp["items"].push({});
            }
          }
          this.soipItems.push(temp);
        }
      }
      console.log("soipItems:" + JSON.stringify(this.soipItems));
      console.log("soipSettingTemp数据:" + JSON.stringify(this.soipItems));
    },
    //验证统计模块选中的可显示模块数量
    verifyCensusNum(index) {
      // this.census[index]["checked"]=false;
      //   for(let j=0;j<this.census.length;j++){
      //     this.census[j]["checked"]=false;
      //   }

      let checked = this.census[index]["checked"];
      let gName = this.census[index]["name"];
      for (let key in this.censusItems) {
        let items = this.censusItems[key]["items"];
        for (let j = 0; j < items.length; j++) {
          if (items[j]["groupName"] == gName) {
            //items[j]["checked"] = checked;
            //console.log("22222",items);
          }
        }
      }

      //item["checked"]=false;
      let checkedNum = 0;
      //item["checked"]=false;
      for (let i = 0; i < this.census.length; i++) {
        //this.census[i]["checked"]=false;
        if (this.census[i]["checked"]) {
          checkedNum++;
        }
      }

      //console.log(checkedNum);
      if (this.checkedMax < checkedNum || checkedNum == 0) {
        console.log("超过4个后");
        this.btnCensus = false;
      } else {
        this.btnCensus = true;
      }

      //console.log("11111",this.census[index]);
    },
    //验证储发并负 选中的可显示模块数量
    verifySoipNum(index) {
      console.log(this.soipSetting[index]["checked"]);
      //item["checked"]=false;
      let checkedNum = 0;
      //item["checked"]=false;
      for (let i = 0; i < this.soipSetting.length; i++) {
        //this.census[i]["checked"]=false;
        if (this.soipSetting[i]["checked"]) {
          checkedNum++;
        }
      }

      console.log(checkedNum);
      if (this.checkedMax < checkedNum || checkedNum == 0) {
        console.log("超过4个后或小于一个");
        this.btnSoip = false;
      } else {
        this.btnSoip = true;
      }

      console.log(this.soipSetting[index]);
    },
    //点击设置滚动项下的复选框设置
    scrollChildSet(index,checked){
      console.info("index",index);
      console.info("checked",checked);

     //console.info("item",childItem);
     //console.info("childItemchecked",checked);
      // for(var i=0;i<this.census.length;i++){
      //   if(childItem.groupName == this.census[i]["name"]){
      //       let items = this.census[i]["items"];
      //       for(var j=0;j<items.length;j++){
      //           if(childItem.name == items[j]["name"]){
      //              this.census[i]["items"][j]["isVisible"]= (checked ? 1 : 0);
      //              this.census[i]["items"][j]["checked"]=checked;
      //           }
      //       }
      //   }
      // }
      // console.info("census",this.census);
    },
    savet1() {
      let children = this.$refs.censusTitle.children;
      for (let i = 0; i < children.length; i++) {
        let name = children[i].getAttribute("name");
        for (let j = 0; j < this.census.length; j++) {
          if (this.census[j]["name"] == name) {
            this.census[j]["index"] = i + 1;
            continue;
          }
        }
      }
       //console.info("census1",this.census)
      for (let i = 0; i < this.census.length; i++) {

        this.census[i]["isVisible"] = this.census[i]["checked"] == true ? 1 : 0;
        let items = this.census[i]["items"];
        for (let j = 0; j < items.length; j++) {
          items[j]["isVisible"] = items[j]["checked"] == true ? 1 : 0;
        }
      }
      //console.info("census2",this.census)
      if (this.census != null && this.census.length == 0) {
        swal({
          //提示的标题
          title: censuskVue.$t("master.failue"), //"保存失败",
          //提示的文本
          text: "", //'',
          //类型：警告，还有success,error和info
          type: "warning",
          //显示取消按钮
          showCancelButton: false,
          //确认按钮内文本
          confirmButtonText: censuskVue.$t("master.determine") //'确定'
        });
        return false;
      }

      let censuskVue = this;
      this.$http
        .post(
          "/service-psinfo/psdata/savePsTotalIsVisible?psId=" +
            this.$store.state.psId,
          this.census
        )
        .then(function(ret) {
          let data = ret.data;
          if (data.status == 200) {
            //swal(data.msg, "保存成功", "success");
            swal({
              //提示的标题
              title: censuskVue.$t("master.success"), //"保存成功",
              //提示的文本
              text: "", //'',
              //类型：警告，还有success,error和info
              type: "success",
              //显示取消按钮
              showCancelButton: false,
              //确认按钮内文本
              confirmButtonText: censuskVue.$t("master.determine") //'确定'
            });
            //quickVue.$refs.sidebar.getQuickDeviceType();
            censuskVue.$refs.home.getPsDataSetting();
          } else {
            //swal(data.msg, "保存失败", "warning");
            swal({
              //提示的标题
              title: censuskVue.$t("master.failue"), //"保存失败",
              //提示的文本
              text: "", //'',
              //类型：警告，还有success,error和info
              type: "warning",
              //显示取消按钮
              showCancelButton: false,
              //确认按钮内文本
              confirmButtonText: censuskVue.$t("master.determine") //'确定'
            });
          }
        })
        .catch(function() {});
      console.log(this.census);
    },
    saveSoipSetting() {
      let children = this.$refs.soipTitle.children;
      for (let i = 0; i < children.length; i++) {
        let name = children[i].getAttribute("name");
        for (let j = 0; j < this.soipSetting.length; j++) {
          if (this.soipSetting[j]["name"] == name) {
            this.soipSetting[j]["index"] = i + 1;
            continue;
          }
        }
      }

      for (let i = 0; i < this.soipSetting.length; i++) {
        this.soipSetting[i]["isVisible"] =
          this.soipSetting[i]["checked"] == true ? 1 : 0;
        let items = this.soipSetting[i]["items"];
        for (let j = 0; j < items.length; j++) {
          items[j]["isVisible"] = items[j]["checked"] == true ? 1 : 0;
        }
      }

      if (this.soipSetting != null && this.soipSetting.length == 0) {
        //swal(data.msg, "保存数据错误", "warning");
        swal({
          //提示的标题
          title: censuskVue.$t("master.failue"), //"保存失败",
          //提示的文本
          text: "", //'',
          //类型：警告，还有success,error和info
          type: "warning",
          //显示取消按钮
          showCancelButton: false,
          //确认按钮内文本
          confirmButtonText: censuskVue.$t("master.determine") //'确定'
        });
        return false;
      }

      let censuskVue = this;
      this.$http
        .post(
          "/service-psinfo/psdata/savePsSoipSettings?psId=" +
            this.$store.state.psId,
          this.soipSetting
        )
        .then(function(ret) {
          let data = ret.data;
          if (data.status == 200) {
            swal({
              //提示的标题
              title: censuskVue.$t("master.success"), //"保存成功",
              //提示的文本
              text: "", //'',
              //类型：警告，还有success,error和info
              type: "success",
              //显示取消按钮
              showCancelButton: false,
              //确认按钮内文本
              confirmButtonText: censuskVue.$t("master.determine") //'确定'
            });
            //quickVue.$refs.sidebar.getQuickDeviceType();
            censuskVue.$refs.home.getPsSoipSettings();
          } else {
            //swal(data.msg, "保存失败", "warning");
            swal({
              //提示的标题
              title: censuskVue.$t("master.failue"), //"保存失败",
              //提示的文本
              text: "", //'',
              //类型：警告，还有success,error和info
              type: "warning",
              //显示取消按钮
              showCancelButton: false,
              //确认按钮内文本
              confirmButtonText: censuskVue.$t("master.determine") //'确定'
            });
          }
        })
        .catch(function() {});
      console.log(this.soipSetting);
    },
    //保存快捷设备类型设置信息
    saveQuick() {
      let quickInfo = [];
      for (let i = 0; i < this.quickDeviceType.length; i++) {
        //deviceTypeName
        let itemQuick = {};
        itemQuick["deviceTypeId"] = this.quickDeviceType[i]["deviceTypeId"];
        itemQuick["deviceTypeName"] = this.quickDeviceType[i]["deviceTypeName"];
        itemQuick["isVisible"] =
          this.quickDeviceType[i]["checked"] == true ? 1 : 0;
        quickInfo.push(itemQuick);
      }

      let quickVue = this;
      this.$http
        .post("/service-psinfo/psinfo/saveDeviceTypeById", {
          deviceTypes: quickInfo,
          psId: this.$store.state.psId
        })
        .then(function(ret) {
          let data = ret.data;
          if (data.status == 200) {
            //swal(data.msg, "保存成功", "success");
            swal({
              //提示的标题
              title: quickVue.$t("master.success"), //"保存成功",
              //提示的文本
              text: "", //'',
              //类型：警告，还有success,error和info
              type: "success",
              //显示取消按钮
              showCancelButton: false,
              //确认按钮内文本
              confirmButtonText: quickVue.$t("master.determine") //'确定'
            });
            quickVue.$refs.sidebar.getQuickDeviceType();
          } else {
            //swal(data.msg, "保存失败", "warning");
            swal({
              //提示的标题
              title: quickVue.$t("master.failue"), //"保存失败",
              //提示的文本
              text: "", //'',
              //类型：警告，还有success,error和info
              type: "warning",
              //显示取消按钮
              showCancelButton: false,
              //确认按钮内文本
              confirmButtonText: quickVue.$t("master.determine") //'确定'
            });
          }
        })
        .catch(function() {});
    },
    getBmsInfo() {
      let censuskVue = this;
      this.$http
        .get(
          "/service-device/rack/getRackDetailsByPsid?psId=" +
            this.$store.state.psId,
          {}
        )
        .then(function(ret) {
          let data = ret.data;
          if (data.status == 200) {
          } else {
            swal({
              //提示的标题
              title: censuskVue.$t("master.goBack"), //"请返回重试",
              //提示的文本
              text: "", //'',
              //类型：警告，还有success,error和info
              type: "warning",
              //显示取消按钮
              showCancelButton: false,
              //确认按钮内文本
              confirmButtonText: censuskVue.$t("master.determine") //'确定'
            });
            //swal(data.msg, "请返回重试", "warning");
          }
        })
        .catch(function() {});
    },
    show: function(isMenuSon, isMenuParent, source, index) {
      /** 从蓄電池下点击进入 */
      if (isMenuParent && source == 0) {
        this.isMenuParent = isMenuParent;
      }
      /**当子弹框出现时，第一次点击关闭子弹框，第二次点击关闭父弹框 */
      if (!isMenuParent && !this.isMenuSon && source == 1) {
        this.isMenuParent = isMenuParent;
        if((!isMenuParent) || index == undefined){
            this.oldIndex = null//重置设备列表参数
        }
      }
      /** 当子弹框处于弹框情况下，并且从蓄電池下点击进入，将不关闭子弹框 */
      if (!(this.isMenuSon == true && source == 0)) {
        this.isMenuSon = isMenuSon;
      }
      /** 当子弹框出现时加载数据 */
      if (this.isMenuSon) {
        this.RefreshIndex = index;
      }
       /** 当子弹框切换设备时不做收进弹出 */
      if(index!= undefined && this.oldIndex != null &&this.oldIndex != index && isMenuSon == false){
         this.isMenuSon = true;
         this.RefreshIndex = index;
      }
      
       this.oldIndex = index //设备第几个参数
    },
    //获取PCS明细数据
    getPcsDetails() {
      var _this = this;
      _this.PcsDetals = [];
      this.$http
        .get(
          "/service-device/pcs/getPcsDetailsByPsid?psId=" +
            this.$store.state.psId,
          {}
        )
        .then(function(ret) {
          let data = ret.data;
          if (data.status == 200) {
            _this.PcsDetals = data.data;
          } else {
            //swal(data.msg, "请返回重试", "warning");
            swal({
              //提示的标题
              title: _this.$t("master.goBack"), //"请返回重试",
              //提示的文本
              text: "", //'',
              //类型：警告，还有success,error和info
              type: "warning",
              //显示取消按钮
              showCancelButton: false,
              //确认按钮内文本
              confirmButtonText: _this.$t("master.determine") //'确定'
            });
          }
          _this.loadingRight = false;
        })
        .catch(function() {
          _this.loadingRight = false;
        });
    },
    //绑定PCS下每路数据
    setPcsDataAC() {
      var _this = this;
      if (this.PcsDetals[this.RefreshIndex] == null) {
        return;
      }
      this.PcsDataAC = [
        {
          Name: "A",
          Voltage: this.PcsDetals[this.RefreshIndex].rvac,
          Current: this.PcsDetals[this.RefreshIndex].riac
        },
        {
          Name: "B",
          Voltage: this.PcsDetals[this.RefreshIndex].svac,
          Current: this.PcsDetals[this.RefreshIndex].siac
        },
        {
          Name: "C",
          Voltage: this.PcsDetals[this.RefreshIndex].tvac,
          Current: this.PcsDetals[this.RefreshIndex].tiac
        }
      ];
      var ipvs = this.PcsDetals[this.RefreshIndex].ipvs;
      var vpvs = this.PcsDetals[this.RefreshIndex].vpvs;
      var ppvs = this.PcsDetals[this.RefreshIndex].ppvs;
      var name = "MPPT-";
      _this.PcsDataDC = [];
      $(ipvs).each(function(i, val) {
        var dc = {
          Name: name + (i + 1),
          Voltage: vpvs[i],
          Current: ipvs[i],
          Power: ppvs[i]
        };
        _this.PcsDataDC.push(dc);
      });
    },

    //获取Dcdc明细数据
    getDcdcDetails() {
      var _this = this;
      //_this.loadingRight = true;
      _this.DcdcDetals = [];
      this.$http
        .get(
          "/service-device/dcdc/getDcdcDetailsByPsid?psId=" +
            this.$store.state.psId,
          {}
        )
        .then(function(ret) {
          let data = ret.data;
          if (data.status == 200) {
            _this.DcdcDetals = data.data;
          } else {
            //swal(data.msg, "请返回重试", "warning");
            swal({
              //提示的标题
              title: _this.$t("master.goBack"), //"请返回重试",
              //提示的文本
              text: "", //'',
              //类型：警告，还有success,error和info
              type: "warning",
              //显示取消按钮
              showCancelButton: false,
              //确认按钮内文本
              confirmButtonText: _this.$t("master.determine") //'确定'
            });
          }
          _this.loadingRight = false;
        })
        .catch(function() {
          _this.loadingRight = false;
        });
    },
    //绑定DcDC下的PvData数据
    setPvData() {
      var _this = this;
     
      var data = _this.DcdcDetals[this.RefreshIndex];
      var name = "PV-";
      _this.PvData = [];
      if (data != null) {
        $(data.ipvs).each(function(i, val) {
          if(data.device_module !=null && i < data.device_module){
              var pv = {
              Name: name + (i + 1),
              Power: data.ppvs[i],
              Current: data.ipvs[i],
              Voltage: data.vpvs[i]
            };
            _this.PvData.push(pv);
          }
         
        });
      }
    },

    //绑定Bms下的PvData数据
    setBmsData() {
      var _this = this;
      var data = _this.BmsDetals[this.RefreshIndex];
      var name = "Rack-";
      _this.RackData = [];
      if (data != null) {
        $(data.mcs).each(function(i, val) {
          var state = _this.$t("master.standby"); // "待机";
          //电流>0放电   <0 充电  =待机
          if (data.mcs[i] > 0) {
            state = _this.$t("master.discharge"); //"放电";
          } else if (data.mcs[i] < 0) {
            state = _this.$t("master.charge"); // "充电";
          } else {
            state = _this.$t("master.standby"); //"待机";
          }
          var rack = {
            Name: name + (i + 1),
            State: state,
            Soc: data.mSocs[i],
            Power: data.mcps[i],
            TotalDischarge: data.mOutTotals[i],
            TotalCharge: data.mInTotals[i],
            Current: data.mcs[i],
            Voltage: data.mvs[i],
            Temperature: data.mts[i],
            HighTemperature: data.mtMaxs[i],
            LowTemperature: data.mtMins[i],
            HighVoltage: data.mvMaxs[i],
            LowVoltage: data.mvMins[i]
          };
          _this.RackData.push(rack);
        });
      }
    },

    //获取Bms明细数据
    getBmsDetails() {
      var _this = this;
      _this.BmsDetals = [];
      this.$http
        .get(
          "/service-device/rack/getRackDetailsByPsid?psId=" +
            this.$store.state.psId,
          {}
        )
        .then(function(ret) {
          let data = ret.data;
          if (data.status == 200) {
            _this.BmsDetals = data.data;
          } else {
            //swal(data.msg, "请返回重试", "warning");
            swal({
              //提示的标题
              title: _this.$t("master.goBack"), //"请返回重试",
              //提示的文本
              text: "", //'',
              //类型：警告，还有success,error和info
              type: "warning",
              //显示取消按钮
              showCancelButton: false,
              //确认按钮内文本
              confirmButtonText: _this.$t("master.determine") //'确定'
            });
          }
          _this.loadingRight = false;
        })
        .catch(function() {
          _this.loadingRight = false;
        });
    },

    //获取电表明细数据
    getAmmeterDetails() {
      var _this = this;
      _this.AmmeterDetails = [];
      this.$http
        .get(
          "/service-device/ammeter/getAmmeterDetailsByPsid?psId=" +
            this.$store.state.psId,
          {}
        )
        .then(function(ret) {
          let data = ret.data;
          if (data.status == 200) {
            _this.AmmeterDetails = data.data;
          } else {
            ///swal(data.msg, "请返回重试", "warning");
            swal({
              //提示的标题
              title: _this.$t("master.goBack"), //"请返回重试",
              //提示的文本
              text: "", //'',
              //类型：警告，还有success,error和info
              type: "warning",
              //显示取消按钮
              showCancelButton: false,
              //确认按钮内文本
              confirmButtonText: _this.$t("master.determine") //'确定'
            });
          }
          _this.loadingRight = false;
        })
        .catch(function() {
          _this.loadingRight = false;
        });
    },

    //获取空调明细数据
    getAirDetails() {
      var _this = this;
      _this.AirDetails = [];

      this.$http
        .get(
          "/service-device/air/getAirDetailsByPsid?psId=" +
            this.$store.state.psId,
          {}
        )
        .then(function(ret) {
          let data = ret.data;
          if (data.status == 200) {
            _this.AirDetails = data.data;
          } else {
            //swal(data.msg, "请返回重试", "warning");
            swal({
              //提示的标题
              title: _this.$t("master.goBack"), //"请返回重试",
              //提示的文本
              text: "", //'',
              //类型：警告，还有success,error和info
              type: "warning",
              //显示取消按钮
              showCancelButton: false,
              //确认按钮内文本
              confirmButtonText: _this.$t("master.determine") //'确定'
            });
          }
          _this.loadingRight = false;
        })
        .catch(function() {
          _this.loadingRight = false;
        });
    },
    //获取消防明细数据
    getFireDetails() {
      var _this = this;
      _this.FireDetails = [];
      this.$http
        .get(
          "/service-device/pssys/getFireStatus?psId=" + this.$store.state.psId,
          {}
        )
        .then(function(ret) {
          let data = ret.data;
          if (data.status == 200) {
            _this.FireDetails = data.data;
          } else {
            //swal(data.msg, "请返回重试", "warning");
            swal({
              //提示的标题
              title: _this.$t("master.goBack"), //"请返回重试",
              //提示的文本
              text: "", //'',
              //类型：警告，还有success,error和info
              type: "warning",
              //显示取消按钮
              showCancelButton: false,
              //确认按钮内文本
              confirmButtonText: _this.$t("master.determine") //'确定'
            });
          }
          _this.loadingRight = false;
        })
        .catch(function() {
          _this.loadingRight = false;
        });
    },

    //设备明细数据数据刷新
    detailRefresh() {
      this.loadingRight = true;
      switch (this.checkedDeviceTypeId) {
        case 1:
        case "1":
          //刷新PCS数据
          //debugger;
          this.getPcsDetails();
          break;
        case 2:
        case "2":
          //刷新DCDC数据
          //debugger;
          this.getDcdcDetails();
          break;
        case "3":
        case 3:
          //刷新Bms数据
          //debugger;
          this.getBmsDetails();
          break;
        case "4":
        case 4:
          //刷新空调数据
          //debugger;
          this.getAirDetails();
          break;
        case "5":
        case 5:
          //刷新电表数据
          //debugger;
          this.getAmmeterDetails();
          break;
        case "6":
        case 6:
          //刷新空调数据
          //debugger;
          this.getFireDetails();
          break;
        default:
          break;
      }
    },
    //获取pcs默认设置
    getPcsVisibleDefault() {
      this.$http
        .get(
          "/service-psinfo/psinfo/getPcsVisibleSettingsDefalut?psId=" +
            this.$store.state.psId,
          {}
        )
        .then(function(ret) {
          let data = ret.data;
          if (data.status == 200) {
          } else {
            //swal(data.msg, "请返回重试", "warning");
            swal({
              //提示的标题
              title: _this.$t("master.goBack"), //"请返回重试",
              //提示的文本
              text: "", //'',
              //类型：警告，还有success,error和info
              type: "warning",
              //显示取消按钮
              showCancelButton: false,
              //确认按钮内文本
              confirmButtonText: _this.$t("master.determine") //'确定'
            });
          }
        })
        .catch(function() {});
    },
    //获取pcs设置
    getPcsVisible() {
      var _this = this;
      this.$http
        .get(
          "/service-psinfo/psinfo/getPcsVisibleSettings?psId=" +
            this.$store.state.psId,
          {}
        )
        .then(function(ret) {
          let data = ret.data;
          if (data.status == 200) {
            _this.PcsVisibleSetting = data.data;
          } else {
            //swal(data.msg, "请返回重试", "warning");
            swal({
              //提示的标题
              title: _this.$t("master.goBack"), //"请返回重试",
              //提示的文本
              text: "", //'',
              //类型：警告，还有success,error和info
              type: "warning",
              //显示取消按钮
              showCancelButton: false,
              //确认按钮内文本
              confirmButtonText: _this.$t("master.determine") //'确定'
            });
          }
        })
        .catch(function() {});
    },
    //保存pcs设置
    savePcsVisible(setting) {
      let _this = this;
      //setting 存入 redis
      this.PcsVisibleSetting = setting;
      this.$http
        .post(
          "/service-psinfo/psinfo/savePcsVisibleSettings?psId=" +
            this.$store.state.psId,
          setting
        )
        .then(function(ret) {
          let data = ret.data;
          if (data.status == 200) {
          } else {
            //swal(data.msg, "保存失败", "warning");
            swal({
              //提示的标题
              title: _this.$t("master.failue"), //"保存失败",
              //提示的文本
              text: "", //'',
              //类型：警告，还有success,error和info
              type: "warning",
              //显示取消按钮
              showCancelButton: false,
              //确认按钮内文本
              confirmButtonText: _this.$t("master.determine") //'确定'
            });
          }
        })
        .catch(function() {});
    },
    //pcs默认设置
    pcsSetDefault() {
      var _this = this;
      this.$http
        .get(
          "/service-psinfo/psinfo/setPcsVisibleSettingsDefalut?psId=" +
            this.$store.state.psId,
          {}
        )
        .then(function(ret) {
          let data = ret.data;
          if (data.status == 200) {
            _this.PcsVisibleSetting = data.data;
          } else {
            //swal(data.msg, "请返回重试", "warning");
            swal({
              //提示的标题
              title: _this.$t("master.failue"), //"保存失败",
              //提示的文本
              text: "", //'',
              //类型：警告，还有success,error和info
              type: "warning",
              //显示取消按钮
              showCancelButton: false,
              //确认按钮内文本
              confirmButtonText: _this.$t("master.determine") //'确定'
            });
          }
        })
        .catch(function() {});
    },

    //获取dcdc设置
    getDcdcVisible() {
      var _this = this;
      this.$http
        .get(
          "/service-psinfo/psinfo/getDcdcVisibleSettings?psId=" +
            this.$store.state.psId,
          {}
        )
        .then(function(ret) {
          let data = ret.data;
          if (data.status == 200) {
            _this.DcdcVisibleSetting = data.data;
          } else {
            //swal(data.msg, "请返回重试", "warning");
            swal({
              //提示的标题
              title: _this.$t("master.failue"), //"保存失败",
              //提示的文本
              text: "", //'',
              //类型：警告，还有success,error和info
              type: "warning",
              //显示取消按钮
              showCancelButton: false,
              //确认按钮内文本
              confirmButtonText: _this.$t("master.determine") //'确定'
            });
          }
        })
        .catch(function() {});
    },
    //保存dcdc设置
    saveDcdcVisible(setting) {
      let _this = this;
      //setting 存入 redis
      this.DcdcVisibleSetting = setting;
      this.$http
        .post(
          "/service-psinfo/psinfo/saveDcdcVisibleSettings?psId=" +
            this.$store.state.psId,
          setting
        )
        .then(function(ret) {
          let data = ret.data;
          if (data.status == 200) {
          } else {
            //swal(data.msg, "保存失败", "warning");
            swal({
              //提示的标题
              title: _this.$t("master.failue"), //"保存失败",
              //提示的文本
              text: "", //'',
              //类型：警告，还有success,error和info
              type: "warning",
              //显示取消按钮
              showCancelButton: false,
              //确认按钮内文本
              confirmButtonText: _this.$t("master.determine") //'确定'
            });
          }
        })
        .catch(function() {});
    },
    //dcdc默认设置
    dcdcSetDefault() {
      var _this = this;
      this.$http
        .get(
          "/service-psinfo/psinfo/setDcdcVisibleSettingsDefalut?psId=" +
            this.$store.state.psId,
          {}
        )
        .then(function(ret) {
          let data = ret.data;
          if (data.status == 200) {
            _this.DcdcVisibleSetting = data.data;
          } else {
            //swal(data.msg, "请返回重试", "warning");
            swal({
              //提示的标题
              title: _this.$t("master.failue"), //"保存失败",
              //提示的文本
              text: "", //'',
              //类型：警告，还有success,error和info
              type: "warning",
              //显示取消按钮
              showCancelButton: false,
              //确认按钮内文本
              confirmButtonText: _this.$t("master.determine") //'确定'
            });
          }
        })
        .catch(function() {});
    },

    //获取bms设置
    getBmsVisible() {
      var _this = this;
      this.$http
        .get(
          "/service-psinfo/psinfo/getBmsVisibleSettings?psId=" +
            this.$store.state.psId,
          {}
        )
        .then(function(ret) {
          let data = ret.data;
          if (data.status == 200) {
            _this.BmsVisibleSetting = data.data;
            //判断是否过积载电站 不显示充放电量
            if(_this.$store.state.psTypeId==2){
              delete _this.BmsVisibleSetting.inTotal
              delete _this.BmsVisibleSetting.outTotal
            }

          } else {
            //swal(data.msg, "请返回重试", "warning");
            swal({
              //提示的标题
              title: _this.$t("master.failue"), //"保存失败",
              //提示的文本
              text: "", //'',
              //类型：警告，还有success,error和info
              type: "warning",
              //显示取消按钮
              showCancelButton: false,
              //确认按钮内文本
              confirmButtonText: _this.$t("master.determine") //'确定'
            });
          }
        })
        .catch(function() {});
    },
    //保存bms设置
    saveBmsVisible(setting) {
      var _this = this;
      //setting 存入 redis
      this.BmsVisibleSetting = setting;
      this.$http
        .post(
          "/service-psinfo/psinfo/saveBmsVisibleSettings?psId=" +
            this.$store.state.psId,
          setting
        )
        .then(function(ret) {
          let data = ret.data;
          if (data.status == 200) {
          } else {
            //swal(data.msg, "保存失败", "warning");
            swal({
              //提示的标题
              title: _this.$t("master.failue"), //"保存失败",
              //提示的文本
              text: "", //'',
              //类型：警告，还有success,error和info
              type: "warning",
              //显示取消按钮
              showCancelButton: false,
              //确认按钮内文本
              confirmButtonText: _this.$t("master.determine") //'确定'
            });
          }
        })
        .catch(function() {});
    },
    //bms默认设置
    bmsSetDefault() {
      var _this = this;
      this.$http
        .get(
          "/service-psinfo/psinfo/setBmsVisibleSettingsDefalut?psId=" +
            this.$store.state.psId,
          {}
        )
        .then(function(ret) {
          let data = ret.data;
          if (data.status == 200) {
            _this.BmsVisibleSetting = data.data;
          } else {
            //swal(data.msg, "请返回重试", "warning");
            swal({
              //提示的标题
              title: _this.$t("master.failue"), //"保存失败",
              //提示的文本
              text: "", //'',
              //类型：警告，还有success,error和info
              type: "warning",
              //显示取消按钮
              showCancelButton: false,
              //确认按钮内文本
              confirmButtonText: _this.$t("master.determine") //'确定'
            });
          }
        })
        .catch(function() {});
    },

    getLanguage(key) {
      var language = {
        deviceName: this.$t("master.deviceName"), //"设备名称",
        dayInTotal: this.$t("master.dayInTotalPac"), //"今日充电",
        dayOutTotal: this.$t("master.dayOutTotalPac"), //"今日放电",
        dayPac: this.$t("master.dayTotalPac"), //"今日发电",
        deviceFlag: this.$t("master.state"), //"状态",
        getdatetime: this.$t("master.getdatetime"), //"采集时间",
        inTotal: this.$t("master.inTotal01"), //"充电量",
        itemp: this.$t("master.temp"), //"温度",
        outTotal: this.$t("master.outTotal01"), //"放电量",
        pac: "AC",
        rfac: this.$t("master.frequency"), //"频率",
        totalPac: this.$t("master.totalPac"), //"累计发电量",
        bcp: this.$t("master.pvPower"), //"光伏发电功率",
        dayPVTotal: this.$t("master.dayTotalPac"), //"本日发电量",
        pvTotal: this.$t("master.totalPac"), //"总累计发电量",
        vpv: this.$t("master.inVoltage"), //"输入电压",
        ipv: this.$t("master.inCurrent"), //"输入电流",
        ppv: this.$t("master.inPower"), //"输入功率",
        soc: this.$t("master.soc"), //"SOC",
        bcp: this.$t("master.inOutPower"), //"充放电功率",
        bv: this.$t("master.voltage"), //"电压",
        bcc: this.$t("master.current"), //"电流",
        btmax: this.$t("master.maxTempa"), //"最高温度",
        btmin: this.$t("master.minTempa") //"最低温度"
      };
      return language[key];
    },
    getClass(key) {
      var tableClass = {
        deviceName: "",
        dayInTotal: "",
        dayOutTotal: "",
        dayPac: "",
        deviceFlag: "",
        getdatetime: "",
        inTotal: "",
        itemp: "",
        outTotal: "",
        pac: "",
        rfac: "",
        totalPac: "",
        bcp: "",
        dayPVTotal: "",
        pvTotal: "",
        vpv: "",
        ppv: "",
        soc: "",
        bcp: "",
        bv: "",
        bcc: "",
        btmax: "",
        btmin: ""
      };
      
    },
    JumpDeviceId(){
      if(this.isMenuParent){
        this.isMenuParent = false; 
        this.isMenuSon=false;
      }
    }
  },
  watch: {
    //pcs 每路的数据刷新绑定
    PcsDetals(newV, oldV) {
      this.setPcsDataAC();
    },
    DcdcDetals(newV, oldV) {
      this.setPvData();
    },
    BmsDetals(newV, oldV) {
      this.setBmsData();
    },
    RefreshIndex(newV, oldV) {
      if(this.oldIndex == null){
        switch (this.checkedDeviceTypeId) {
        case 1:
        case "1":
          //pcs 每路的数据刷新绑定
          this.setPcsDataAC();
          break;
        case 2:
        case "2":
          //dcdc 每路的数据刷新绑定
          this.setPvData();
          break;
        case 3:
        case "3":
          //bMS 每路的数据刷新绑定
          this.setBmsData();
          break;
        default:
          break;
      }
      }
    }
  }
};
</script>

<style>
/* @media screen and (max-width: 1300px) { */
body::-webkit-scrollbar {
  display: none !important;
}

</style>
