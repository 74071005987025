<template>
  <el-tooltip placement="bottom" effect="dark">
    <div slot="content">
      <span>{{ stateName }}</span>
    </div>
    <div>
      <span :class="ilayerClass">
        <i :class="iClass1" :data-fa-transform="transform1"></i>
        <i v-if="isClass" :class="iClass2" :data-fa-transform="transform2"></i>
      </span>
      <span class="sr-only">{{ stateName }}</span>
    </div>
  </el-tooltip>
</template>
<script>
export default {
  props: ["deviceState"],
  data() {
    return {
      stateName: "正常",
      ilayerClass: "fa-layers fa-fw text-grass",
      transform1: "",
      transform2: "shrink-9 up-1.2",
      iClass1: "fad fa-siren-on",
      iClass2: "fad",
      isClass: true
    };
  },
  mounted() {
    this.checkState();
  },
  methods: {
    checkState() {
      switch (this.deviceState) {
        case "0": 
        case 0:
          this.stateName = "正常";
          this.ilayerClass = "fa-layers fa-fw text-grass";
          this.transform2 = "shrink-9 up-1.2";
          this.iClass2 = "fad fa-check";
          this.isClass = true;
          break;
        case "1":
        case 1:
          this.stateName = "离线";
          this.ilayerClass = "fa-layers fa-fw text-bark";
          this.transform2 = "shrink-9 up-1.1";
          this.iClass2 = "fad fa-wifi-slash";
          this.isClass = true;
          break;
        case "3":
        case 3:
          this.stateName = "停机";
          this.ilayerClass = "fa-layers fa-fw text-dust";
          this.transform2 = "shrink-10 up-1.5";
          this.iClass2 = "fad fa-pause";
          this.isClass = true;
          break;
        case "4":
        case 4:
          this.stateName = "故障";
          this.ilayerClass = "fa-layers fa-fw text-tomato";
          this.transform2 = "shrink-9.5 up-1";
          this.iClass2 = "fad fa-tools";
          this.isClass = true;
          break;
        case "31":
        case 31:
          this.stateName = "充电中"; 
          this.ilayerClass = "icon-bolt-in";
          this.iClass1 = "fad fa-bolt";
          this.isClass = false;
          break;
        case "32":
        case 32:
          this.stateName = "放电中";
          this.ilayerClass = "icon-bolt-out";
          this.iClass1 = "fad fa-bolt";
          this.isClass = false;
          break;
        case "33":
        case 33:
          this.stateName = "待机中";
          this.ilayerClass = "icon-bolt-wait";
          this.iClass1 = "fad fa-bolt";
          this.isClass = false;
          break;
        // 空调独有状态
        case "43":
        case 43:
          this.stateName = "制热";  
          this.ilayerClass = "fa-layers fa-fw text-tomato";
          this.transform1 = "right-4.5";
          this.transform2 = "shrink-3.5 left-4.5";
          this.iClass1 = "fad fa-thermometer-three-quarters";
          this.iClass2 = "fad fa-sun";
          this.isClass = true;
          break;
        case "44":
        case 44:
          this.stateName = "制冷";
          this.ilayerClass = "fa-layers fa-fw text-sea";
          this.transform1 = "right-4.5";
          this.transform2 = "shrink-4.8 left-4.5";
          this.iClass1 = "fad fa-thermometer-quarter";
          this.iClass2 = "fad fa-snowflake";
          this.isClass = true;
          break;

        default:
          break;
      }
    }
  },
  watch: {
    deviceState(newV, oldV) {
      // do something
      this.checkState();
    }
  }
};
</script>
<style scoped></style>
