<template>
  <!-- sidebar -->
  <div class="rtl_mode">
    <div id="left-sidebar" class="sidebar ">
      <!-- 默认小菜单时候增加样式 sidebar-mini  -->
      <div class="d-flex flex-column h-100">
        <div class="navbar-brand">
          <a href="home">
            <img src="../../assets/images/SmartOM.svg" alt="SmartOM Logo" class="img-fluid" />
          </a>
        </div>

        <!-- Menu: main ul -->
        <div class="sidebar-scroll menu-list flex-grow-1">
          <div class="user-account">
            <!-- 小左边栏启用 -->
            <el-dropdown trigger="hover" placement="right-start">
              <span class="el-dropdown-link">
                <div class="user_div">
                  <img src="../../assets/images/user.png" class="user-photo" alt="User Profile Picture" />
                </div>
                <span class="UserNameOut ml-1 text-white-55">{{
                  userInfo.userName 
                }}</span>
              </span>
              <el-dropdown-menu slot="dropdown" class="header-new-drop">
                <el-dropdown-item disabled>
                  <span class="UserNameIn">{{ $t("sidebar.hello") }},{{ userInfo.userName }}</span>
                </el-dropdown-item>
                <el-dropdown-item divided @click.native="usersettingInfoShow">
                  <span>
                    <i class="fad fa-user-circle mr-2"></i><span>{{ $t("sidebar.userSetting") }}</span>
                  </span>
                </el-dropdown-item>

                <el-dropdown-item @click.native="registerPsInfoShow">
                  <span>
                    <i class="fad fa-plus-circle mr-2"></i><span>{{ $t("sidebar.bindPs") }}</span>
                  </span>
                </el-dropdown-item>

                <!-- <el-dropdown-item @click.native="authorityInfoShow">
                  <span>
                    <i class="fad fa-shield-check mr-2"></i><span>{{ $t("sidebar.authorization") }}</span>
                  </span>
                </el-dropdown-item> -->

                <el-dropdown-item divided @click.native="contactInfoShow">
                  <span>
                    <i class="fad fa-user-headset mr-2"></i><span>{{ $t("sidebar.ContactTitle") }}</span>
                  </span>
                </el-dropdown-item>

                <el-dropdown-item divided @click.native="signOut">
                  <span>
                    <i class="fad fa-sign-out mr-2"></i><span>{{ $t("sidebar.signOut") }}</span>
                  </span>
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>

          <div class="sidebar-menu-content">

            <div class="index-menu">
              <el-menu router :default-active="$route.path.replace('/','')" class="el-menu-vertical-demo" @open="handleOpen" @close="handleClose" :collapse="isCollapse" :collapse-transition="true" text-color="#bfffff">
                <!-- 有子菜单时启用 -->
                <!-- <el-submenu>
                <template slot="title">
                  <i class="fad fa-user-plus mr-1"></i>
                  <span slot="title">测试菜单</span>
                </template>
                <el-menu-item-group>
                  <el-menu-item>测试子菜单1</el-menu-item>
                  <el-menu-item>测试子菜单2</el-menu-item>
                </el-menu-item-group>
              </el-submenu> -->
                <el-menu-item v-for="item in menuList" :key="item.menuId" :index="item.url">
                  <i :class="menuIcon[item.menuId]"></i>
                  <span slot="title"> {{ $t("sidebar.menu." + item.menuId) }} </span>
                </el-menu-item>
              </el-menu>
            </div>

            <hr>

            <div class="device-menu">
              <el-menu @open="handleOpen" @close="handleClose" :collapse="isCollapse" :collapse-transition="true">
                <el-menu-item v-for="item in quickDeviceType" :key="item.deviceTypeId" v-show="item.isVisible == 1 ? true : false" @click="setParent(item.deviceTypeId)">
                  <i :class="quickIcon[item.deviceTypeId]"></i>
                  <span slot="title"> {{ $t("sidebar.deviceType." + item.deviceTypeId)  }} </span>
                </el-menu-item>
              </el-menu>
            </div>

            <hr>

            <div class="device-menu">
              <el-menu @open="handleOpen" @close="handleClose" :collapse="isCollapse" :collapse-transition="true">
                <el-menu-item @click="openQuick" data-target=".bd-MiniIconsSetting-modal-lg">
                  <i class="fad fa-cog fa-spin mr-1"></i>
                  <span slot="title"> {{ $t("sidebar.leftMenu") }} </span>
                </el-menu-item>
              </el-menu>
            </div>

            <!-- <nav id="left-sidebar-nav" class="sidebar-nav">
            <ul id="main-menu" class="menu-list flex-grow-1 mt-3 metismenu animation-li-delay">
              <li class="m-link active" v-for="item in menuList" :key="item.menuId">
                <el-tooltip class="item" :content="$t('sidebar.menu.' + item.menuId)" placement="right" effect="light">
                  <a :href="item.url"><i :class="menuIcon[item.menuId]"></i><span> {{ $t("sidebar.menu." + item.menuId) }}</span></a>
                </el-tooltip>
              </li>

              <div class="sidebar-mini-6icons">
                <template v-for="item in quickDeviceType">
                  <li class="m-link" :key="item.deviceTypeId" v-if="item.isVisible == 1 ? true : false">
                    <el-tooltip class="item" :content="item.deviceTypeName" placement="right" effect="light">
                      <a href="javascript:void(0);" @click="setParent(item.deviceTypeId)"><i :class="quickIcon[item.deviceTypeId]" class="icon-sty mr-2"></i><span>{{ item.deviceTypeName }}</span></a>
                    </el-tooltip>
                  </li>
                </template>
              </div>
              <div class="setting-mini-icon">
                <li class="m-link">
                  <el-tooltip class="item" :content="$t('sidebar.leftMenu')" placement="right" effect="light">
                    <a href="javascript:void(0);" @click="openQuick" data-target=".bd-MiniIconsSetting-modal-lg"><i class="fad fa-cog fa-spin icon-sty mr-2"></i><span class="sr-only">{{
                        $t("sidebar.leftMenu")
                      }}</span></a>
                  </el-tooltip>
                </li>
              </div>

              <li class="extra_widget">
                <div class="row text-center">
                  <div class="col-12">
                    <p>今日发电配比</p>
                  </div>
                  <div class="col-6 border-right">
                    <h4 class="font-30 text-info mb-0">2,025</h4>
                    <p class="text-info-55">kwh</p>
                    <p>
                      <el-tooltip class="item" content="太阳能供电" placement="bottom" effect="light">
                        <img src="../../assets/images/F4/MrSolar.svg" class="w35" />
                      </el-tooltip>
                      <el-tooltip class="item" content="蓄电池供电" placement="bottom" effect="light">
                        <img src="../../assets/images/F4/MrStorage.svg" class="w40 m-b--5" />
                      </el-tooltip>
                    </p>
                    <label class="mb-0 text-info-55 small">太阳能发电</label>
                    <h4 class="text-info">83%</h4>
                  </div>
                  <div class="col-6">
                    <h4 class="font-30 text-white-55 mb-0">1,254</h4>
                    <p class="text-white-25">kwh</p>
                    <p>
                      <el-tooltip class="item" content="电网供电" placement="bottom" effect="light">
                        <img src="../../assets/images/F4/MrWeb.svg" class="w35 m-t--9" />
                      </el-tooltip>
                    </p>
                    <label class="mb-0 text-white-25 small">电网发电</label>
                    <h4 class="text-white-55">17%</h4>
                  </div>
                </div>
                <div class="form-group mb-0">
                  <label class="d-block mb-0 mt-2">
                    <span class="text-info">
                      <el-tooltip class="item" content="太阳能供电" placement="bottom" effect="light"><img src="../../assets/images/F4/MrSolar.svg" class="w12 m-t--8" /></el-tooltip>43%<i class="fal fa-plus small ml-2 mr-2"></i>
                      <el-tooltip class="item" content="蓄电池供电" placement="bottom" effect="light"><img src="../../assets/images/F4/MrStorage.svg" class="w15 m-t--4" /></el-tooltip>40%
                    </span>
                    <span class="text-white-55 right">17%
                      <el-tooltip class="item" content="电网供电" placement="bottom" effect="light"><img src="../../assets/images/F4/MrWeb-noline.svg" class="w15 m-t--2" /></el-tooltip>
                    </span></label>
                  <div class="progress progress-xxs">
                    <div class="progress-bar progress-bar-info" role="progressbar" aria-valuenow="83" aria-valuemin="0" aria-valuemax="100" style="width: 83%"></div>
                    <div class="progress-bar progress-bar-default" role="progressbar" aria-valuenow="83" aria-valuemin="0" aria-valuemax="100" style="width: 17%"></div>
                  </div>
                </div>
              </li>

              <li class="extra_widget">
                <div class="row">
                  <div class="col-12 text-center mb-1">
                    <p>
                      <span><i class="fad fa-chart-bar"></i> 今日电价</span>
                      <span class="screen price kwh-per">
                        251
                      </span>
                    </p>
                  </div>
                  <div class="col-10 text-left">
                    <div class="row mb-3">
                      <div class="col-2">
                        <el-tooltip class="item" content="太阳能" placement="bottom" effect="light"><img src="../../assets/images/F4/MrSolar.svg" class="w15"></el-tooltip>
                      </div>
                      <div class="col-10 m-t--9">
                        <label class="d-block m-0">
                          <span class="text-success-55">
                            <i class="fal fa-plus"></i> <span class="price">260</span>
                          </span>
                          <span class="right" style="color:rgba(255,255,255,0) !important">
                            <i class="fal fa-minus"></i> <span class="price">0</span>
                          </span>
                        </label>
                        <div class="progress progress-xxs">
                          <div class="progress-bar progress-bar-success" role="progressbar" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100" style="width: 100%;"></div>
                        </div>
                      </div>
                    </div>
                    <div class="row mb-3">
                      <div class="col-2">
                        <el-tooltip class="item" content="蓄电池" placement="bottom" effect="light"><img src="../../assets/images/F4/MrStorage.svg" class="w20"></el-tooltip>
                      </div>
                      <div class="col-10 m-t--9">
                        <label class="d-block m-0">
                          <span class="text-success-55">
                            <i class="fal fa-plus"></i> <span class="price">252</span>
                          </span>
                          <span class="text-danger-55 right">
                            <i class="fal fa-minus"></i> <span class="price">48</span>
                          </span>
                        </label>
                        <div class="progress progress-xxs">
                          <div class="progress-bar progress-bar-success" role="progressbar" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100" style="width: 80%;"></div>
                          <div class="progress-bar progress-bar-danger" role="progressbar" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100" style="width: 20%;"></div>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-2">
                        <el-tooltip class="item" content="负载" placement="bottom" effect="light"><img src="../../assets/images/F4/MrLoad.svg" class="w18"></el-tooltip>
                      </div>
                      <div class="col-10 m-t--9">
                        <label class="d-block m-0">
                          <span style="color:rgba(255,255,255,0) !important">
                            <i class="fal fa-plus"></i> <span class="price">0</span>
                          </span>
                          <span class="text-danger-55 right">
                            <i class="fal fa-minus"></i> <span class="price">220</span>
                          </span>
                        </label>
                        <div class="progress progress-xxs">
                          <div class="progress-bar progress-bar-danger" role="progressbar" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100" style="width: 100%;"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-2 text-right">
                    <p>
                      <el-tooltip class="item" content="电网" placement="bottom" effect="light"><img src="../../assets/images/F4/MrWeb-long.svg" class="w23"></el-tooltip>
                    </p>
                  </div>
                </div>
                <div class="text-center">
                  <label class="d-block mt-2">
                    <h2 class="conin-plus">
                      <span><i class="fad fa-coins text-warning-75 fa-flip-horizontal fa-xs"></i></span>
                      <span class="fa-layers fa-fw mr-1">
                        <i class="fad fa-coin text-warning" data-fa-transform="shrink-6 down-2" data-fa-mask="fad fa-sack"></i>
                        <i class="fal fa-plus" data-fa-transform="shrink-10 down-1"></i>
                      </span>
                      <span class="price text-success-55">370</span>
                    </h2>
                  </label>
                </div>
              </li>
            </ul>
          </nav> -->

          </div>
        </div>
        <!-- Menu: menu collepce btn -->
        <button type="button" class="btn btn-link sidebar-mini-btn" @click="toggleCollapse">
          <span><i class="sidebar-folder-icon"></i></span>
        </button>
      </div>
    </div>

    <!-- 左菜单设置 -->
    <!-- 联系我们弹窗 -->
    <div id="contactInfo" class="modal fade feed-post-modal" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
      <div class="modal-dialog text-center" role="document">
        <img src="../../assets/images/contact/ServiceKangaroo.svg" class="img-fluid" width="35%">
        <div class="modal-content">
          <div class="modal-body">
            <div class="detail-header">
              <div class="media">
                <div class="media-body">
                  <h5>{{ $t("login.ContactUs.ContactTitle") }}</h5>
                  <hr>
                  <p class="mb-2"><strong>{{ $t("login.ContactUs.EmailTitle") }}</strong></p>
                  <p class="mb-1"><small>{{ $t("login.ContactUs.EmailSonTitle1") }}</small></p>
                  <p class="mb-2">
                    <el-tag type="success">
                      <span>sales@taoke-energy.com</span>
                    </el-tag>
                  </p>
                  <p class="mb-1"><small>{{ $t("login.ContactUs.EmailSonTitle2") }}</small></p>
                  <p class="mb-1">
                    <el-tag type="success">
                      <span>support@taoke-energy.com</span>
                    </el-tag>
                  </p>
                  <hr>
                  <p class="mb-2"><strong>{{ $t("login.ContactUs.TelTitle") }}</strong></p>
                  <p class="mb-1">
                    <el-tag type="success">
                      <span class="fa-layers fa-fw fa-2x mr-2 m-b--1">
                        <i class="fad fa-circle-notch fa-spin"></i>
                        <i class="fad fa-phone" data-fa-transform="shrink-8"></i>
                      </span>
                      <span class="x-large">03-5439-6575</span>
                    </el-tag>
                  </p>
                  <p class="mb-0"><small>{{ $t("login.ContactUs.TelDate") }}</small></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 用户设置弹框 -->
    <div id="usersettingInfo" class="modal fade bd-UserSetting-modal-lg" tabindex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <div class="d-flex">
              <div class="icon bg-transparent">
                <img src="../../assets/images/user.png" class="rounded-circle mr-3 w40" alt="" />
              </div>
              <div>
                <h6 class="mb-0">{{ userInfo.userName }}</h6>
                <span>{{ userInfo.userTypeId==2?$t("login.reg.professional"):$t("login.reg.ordinary") }}</span>

              </div>
            </div>
            <button type="button" class="close" data-dismiss="modal" @click="usersettingInfoHide" aria-label="Close">
              <span aria-hidden="true"><i class="fal fa-times"></i></span>
            </button>
          </div>
          <div class="modal-body">
            <el-tabs v-model="activeName">
              <!-- <el-tab-pane label="头像" name="UserPhoto">
                <h6><i class="fad fa-image-polaroid"></i> 头像变更</h6>
                <p>选择一个心仪的头像？</p>
                <label class="fancy-radio custom-color-green"
                  ><input name="gender3" value="Male" type="radio" /><span
                    ><i></i>
                    <span class="icon bg-transparent">
                      <img
                        src="../../assets/images/sm/avatarMale.jpg"
                        class="rounded-circle mr-3 w30"
                        alt=""
                      />
                    </span>
                  </span>
                </label>
                <label class="fancy-radio custom-color-green"
                  ><input name="gender3" value="Female" type="radio" /><span
                    ><i></i>
                    <span class="icon bg-transparent">
                      <img
                        src="../../assets/images/sm/avatarFemale.jpg"
                        class="rounded-circle mr-3 w30"
                        alt=""
                      />
                    </span>
                  </span>
                </label>
                <label class="fancy-radio custom-color-green"
                  ><input name="gender3" value="MrStorage" type="radio" /><span
                    ><i></i>
                    <span class="icon bg-transparent">
                      <img
                        src="../../assets/images/sm/avatar1.jpg"
                        class="rounded-circle mr-3 w30"
                        alt=""
                      />
                    </span>
                  </span>
                </label>
                <label class="fancy-radio custom-color-green"
                  ><input name="gender3" value="MrSolar" type="radio" /><span
                    ><i></i>
                    <span class="icon bg-transparent">
                      <img
                        src="../../assets/images/sm/avatar2.jpg"
                        class="rounded-circle mr-3 w30"
                        alt=""
                      /> </span
                  ></span>
                </label>
                <label class="fancy-radio custom-color-green"
                  ><input name="gender3" value="MrWeb" type="radio" /><span
                    ><i></i>
                    <span class="icon bg-transparent">
                      <img
                        src="../../assets/images/sm/avatar3.jpg"
                        class="rounded-circle mr-3 w30"
                        alt=""
                      /> </span
                  ></span>
                </label>
                <label class="fancy-radio custom-color-green"
                  ><input name="gender3" value="MrLoad" type="radio" /><span
                    ><i></i>
                    <span class="icon bg-transparent">
                      <img
                        src="../../assets/images/sm/avatar4.jpg"
                        class="rounded-circle mr-3 w30"
                        alt=""
                      /> </span
                  ></span>
                </label>
                <hr />
                <p>自定义头像 / 上传头像</p>
                <el-upload
                  class="avatar-uploader"
                  action="https://jsonplaceholder.typicode.com/posts/"
                  :show-file-list="false"
                  :on-success="handleAvatarSuccess"
                  :before-upload="beforeAvatarUpload"
                >
                  <img v-if="imageUrl" :src="imageUrl" class="avatar" />
                  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
              </el-tab-pane> -->
              <el-tab-pane :label="$t('sidebar.userNameEmail')" name="UserName">
                <h6>
                  <i class="fad fa-user-circle"></i>
                  {{ $t("sidebar.updateUserNameEmail") }}
                </h6>
                <div class="form-group c_form_group disabled">
                  <label>
                    {{ $t("sidebar.userId") }}
                  </label>
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text"><i class="fad fa-id-card-alt text-dark-15"></i></span>
                    </div>
                    <input type="name" class="form-control" v-model="userInfo.userCode" v-validate="'required'" name="userId" disabled />
                  </div>
                </div>
                <div class="form-group c_form_group">
                  <label>
                    {{ $t("sidebar.updateOldUserName") }}
                    <span class="float-right text-tomato" v-show="errors.has('updateEmail.userName')"><i class="fad fa-exclamation-circle"></i>
                      <span class="text-tomato small">
                        {{ errors.first("updateEmail.userName") }}
                      </span></span>
                  </label>
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text"><i class="fad fa-user-circle text-dark-15"></i></span>
                    </div>
                    <input type="name" class="form-control" v-model="updateUserCode.userName" v-validate="'required'" name="userName" data-vv-scope="updateEmail" />
                  </div>
                </div>

                <div class="form-group c_form_group">
                  <label>
                    {{ $t("login.reg.contactNumber") }}
                    <span v-show="errors.has('updateEmail.userTelephone')" class="float-right text-tomato"><i class="fad fa-exclamation-circle"></i>
                      <span class="text-tomato small">
                        {{ errors.first("updateEmail.userTelephone") }}
                      </span>
                    </span>
                  </label>
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text"><i class="fad fa-mobile-android-alt text-dark-15"></i></span>
                    </div>
                    <input type="name" class="form-control mobile-phone-number" placeholder="Ex: +00 (000) 000-00-00" v-validate="'telephone'" v-model="updateUserCode.userTelephone" name="userTelephone" data-vv-scope="updateEmail" />
                  </div>
                </div>

                <div class="form-group c_form_group">
                  <label>
                    {{ $t("sidebar.updateOldEmail") }}

                    <span class="float-right text-tomato" v-show="errors.has('updateEmail.userEmail')"><i class="fad fa-exclamation-circle"></i>
                      <span class="text-tomato-55 small">{{
                        errors.first("updateEmail.userEmail")
                      }}</span></span>
                  </label>
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text"><i class="fad fa-envelope text-dark-15"></i></span>
                    </div>
                    <input type="name" v-model.trim="updateUserCode.userEmail" class="form-control" data-vv-scope="updateEmail" v-validate="'required|email'" name="userEmail" placeholder="Ex: example@example.com" />
                  </div>
                </div>
              </el-tab-pane>
              <el-tab-pane :label="$t('sidebar.password')" name="userPassword">
                <h6>
                  <i class="fad fa-key-skeleton"></i>
                  {{ $t("sidebar.updatePassword") }}
                </h6>
                <div class="form-group c_form_group">
                  <label>
                    {{ $t("sidebar.pleaseOldPassword") }}

                    <span class="float-right text-tomato" v-show="errors.has('updatePsw.userPassword')"><i class="fad fa-exclamation-circle"></i>
                      <span class="text-tomato small">
                        {{ errors.first("updatePsw.userPassword") }}
                      </span></span>
                  </label>
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text"><i class="fad fa-key-skeleton text-dark-15"></i></span>
                    </div>
                    <input :type="passwordFlagSide ? 'password' : 'text'" class="form-control" :placeholder="$t('sidebar.pleaseOldPassword')" data-vv-scope="updatePsw" v-validate="'required|min:8|pwd'" v-model="updatePsw.oldPassword" name="userPassword" />
                    <span :class="passwordFlagSide ? 'unsee' : 'see'" @click="passwordSeeSide"></span>
                  </div>
                </div>
                <div class="form-group c_form_group">
                  <label>
                    {{ $t("sidebar.pleaseNewPassword") }}

                    <span v-show="errors.has('updatePsw.password')" class="float-right text-tomato"><i class="fad fa-exclamation-circle"></i>
                      <span class="text-tomato small">
                        {{ errors.first("updatePsw.password") }}
                      </span></span>
                  </label>
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text">
                        <span class="fa-layers fa-fw">
                          <i class="fad fa-key-skeleton text-dark-15"></i>
                          <i class="fad fa-redo-alt" data-fa-transform="shrink-6.5 right-8 down-3"></i>
                        </span>
                      </span>
                    </div>
                    <input :type="passwordFlagSide ? 'password' : 'text'" class="form-control" :placeholder="$t('sidebar.pleaseNewPassword')" data-vv-scope="updatePsw" v-validate="'required|min:8|pwd'" v-model="updatePsw.newPassword" name="password" />
                  </div>
                </div>
                <div class="form-group c_form_group">
                  <label>{{ $t("sidebar.sureNewPassword") }}
                    <span v-show="errors.has('updatePsw.resetPassword')" class="float-right text-tomato"><i class="fad fa-exclamation-circle"></i>
                      <span class="text-tomato small">
                        {{ errors.first("updatePsw.resetPassword") }}
                      </span></span>
                  </label>
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text">
                        <span class="fa-layers fa-fw">
                          <i class="fad fa-key-skeleton text-dark-15"></i>
                          <i class="fad fa-check" data-fa-transform="shrink-4 right-8 down-3"></i>
                        </span>
                      </span>
                    </div>
                    <input :type="passwordFlagSide ? 'password' : 'text'" class="form-control" :placeholder="$t('sidebar.newPasswordAgein')" data-vv-scope="updatePsw" v-validate="'required|confirmed:password'" v-model="updatePsw.resetPsw" name="resetPassword" />
                  </div>
                </div>
              </el-tab-pane>
              <!-- <el-tab-pane label="基本信息" name="UserInfo">
                <h6><i class="fad fa-address-card"></i> 更改基本信息</h6>
                <el-row :gutter="10">
                  <el-col :span="12">
                    <div class="form-group c_form_group">
                      <label>联系人<span class="text-tomato">*</span></label>
                      <div class="input-group">
                        <div class="input-group-prepend">
                          <span class="input-group-text">
                            <i class="fad fa-user-circle text-dark-15"></i>
                          </span>
                        </div>
                        <input
                          class="form-control"
                          type="text"
                          value="渡边和也"
                        />
                      </div>
                    </div>
                  </el-col>
                  <el-col :span="12">
                    <div class="form-group c_form_group">
                      <label
                        >联系人电话<span class="text-tomato">*</span></label
                      >
                      <div class="input-group">
                        <div class="input-group-prepend">
                          <span class="input-group-text">
                            <i
                              class="fad fa-mobile-android-alt text-dark-15"
                            ></i>
                          </span>
                        </div>
                        <input
                          class="form-control"
                          type="text"
                          value="13925698428"
                        />
                      </div>
                    </div>
                  </el-col>
                  <el-col :span="24">
                    <div class="form-group c_form_group">
                      <label>地址</label>
                      <div class="input-group">
                        <div class="input-group-prepend">
                          <span class="input-group-text">
                            <i class="fad fa-map-marker-alt text-dark-15"></i>
                          </span>
                        </div>
                        <input
                          type="text"
                          class="form-control"
                          value="比企郡小川町大字青山字城北125"
                        />
                      </div>
                    </div>
                  </el-col>
                  <el-col :span="12">
                    <div class="form-group c_form_group">
                      <label>邮编</label>
                      <div class="input-group">
                        <div class="input-group-prepend">
                          <span class="input-group-text">
                            <i class="fad fa-location text-dark-15"></i>
                          </span>
                        </div>
                        <input class="form-control" value="91403" type="text" />
                      </div>
                    </div>
                  </el-col>
                  <el-col :span="12">
                    <div class="form-group c_form_group">
                      <label>传真</label>
                      <div class="input-group">
                        <div class="input-group-prepend">
                          <span class="input-group-text">
                            <i class="fad fa-print text-dark-15"></i>
                          </span>
                        </div>
                        <input
                          class="form-control"
                          value="818-978-7102"
                          type="text"
                        />
                      </div>
                    </div>
                  </el-col>
                  <el-col :span="24">
                    <div class="form-group c_form_group">
                      <label>网址</label>
                      <div class="input-group">
                        <div class="input-group-prepend">
                          <span class="input-group-text">
                            <i class="fad fa-globe-europe text-dark-15"></i>
                          </span>
                        </div>
                        <input
                          type="text"
                          class="form-control"
                          value="http://"
                        />
                      </div>
                    </div>
                  </el-col>
                </el-row>
              </el-tab-pane> -->
            </el-tabs>

            <!-- <ul class="nav nav-tabs">
              <li class="nav-item">
                <a class="nav-link active show" data-toggle="tab" href="#Avatar">头像</a>
              </li>
              <li class="nav-item active show">
                <a class="nav-link" data-toggle="tab" href="#UserName">用户名 / 邮箱</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" data-toggle="tab" href="#Password">密码</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" data-toggle="tab" href="#Information">基本信息</a>
              </li>
            </ul> -->
            <!-- <div class="tab-content">
              <div class="tab-pane show vivify fadeIn active" id="Avatar">
                <h6><i class="fad fa-image-polaroid"></i> 头像变更</h6>
                <p>选择一个心仪的头像？</p>
                <label class="fancy-radio custom-color-green"><input name="gender3" value="Male" type="radio" /><span><i></i>
                    <span class="icon bg-transparent">
                      <img src="../../assets/images/sm/avatarMale.jpg" class="rounded-circle mr-3 w30" alt="" />
                    </span>
                  </span>
                </label>
                <label class="fancy-radio custom-color-green"><input name="gender3" value="Female" type="radio" /><span><i></i>
                    <span class="icon bg-transparent">
                      <img src="../../assets/images/sm/avatarFemale.jpg" class="rounded-circle mr-3 w30" alt="" />
                    </span>
                  </span>
                </label>
                <label class="fancy-radio custom-color-green"><input name="gender3" value="MrStorage" type="radio" /><span><i></i>
                    <span class="icon bg-transparent">
                      <img src="../../assets/images/sm/avatar1.jpg" class="rounded-circle mr-3 w30" alt="" />
                    </span>
                  </span>
                </label>
                <label class="fancy-radio custom-color-green"><input name="gender3" value="MrSolar" type="radio" /><span><i></i>
                    <span class="icon bg-transparent">
                      <img src="../../assets/images/sm/avatar2.jpg" class="rounded-circle mr-3 w30" alt="" /> </span></span>
                </label>
                <label class="fancy-radio custom-color-green"><input name="gender3" value="MrWeb" type="radio" /><span><i></i>
                    <span class="icon bg-transparent">
                      <img src="../../assets/images/sm/avatar3.jpg" class="rounded-circle mr-3 w30" alt="" /> </span></span>
                </label>
                <label class="fancy-radio custom-color-green"><input name="gender3" value="MrLoad" type="radio" /><span><i></i>
                    <span class="icon bg-transparent">
                      <img src="../../assets/images/sm/avatar4.jpg" class="rounded-circle mr-3 w30" alt="" /> </span></span>
                </label>
                <hr />
                <p>自定义头像 / 上传头像</p>
                <el-upload class="avatar-uploader" action="https://jsonplaceholder.typicode.com/posts/" :show-file-list="false" :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload">
                  <img v-if="imageUrl" :src="imageUrl" class="avatar" />
                  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
              </div>
              <div class="tab-pane show vivify fadeIn active" id="UserName">
                <h6><i class="fad fa-user-circle"></i> 更改用户名和邮箱</h6>
                <div class="form-group c_form_group">
                  <label>
                    请修改原用户名
                    <span class="float-right text-tomato"><i class="fad fa-exclamation-circle"></i>
                      <span class="text-tomato small">用户名不能为空</span></span>
                  </label>
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text"><i class="fad fa-user-circle text-dark-15"></i></span>
                    </div>
                    <input type="name" class="form-control" value="Mr. Kangroo" />
                  </div>
                </div>
                <div class="form-group c_form_group">
                  <label>
                    请修改原邮箱地址
                    <span class="float-right text-tomato"><i class="fad fa-exclamation-circle"></i>
                      <span class="text-tomato small">邮箱地址不能为空</span></span>
                  </label>
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text"><i class="fad fa-user-circle text-dark-15"></i></span>
                    </div>
                    <input type="name" class="form-control" value="Mr.Kangroo@email.com" />
                  </div>
                </div>
              </div>
              <div class="tab-pane vivify fadeIn" id="Password">
                <h6><i class="fad fa-key-skeleton"></i> 更改密码</h6>
                <div class="form-group c_form_group">
                  <label>
                    请输入原密码
                    <span class="float-right text-tomato"><i class="fad fa-exclamation-circle"></i>
                      <span class="text-tomato small">密码必须为6位</span></span>
                  </label>
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text"><i class="fad fa-key-skeleton text-dark-15"></i></span>
                    </div>
                    <input type="password" class="form-control" placeholder="请输入原密码" />
                  </div>
                </div>
                <div class="form-group c_form_group">
                  <label>
                    请输入新密码
                    <span class="float-right text-tomato"><i class="fad fa-exclamation-circle"></i>
                      <span class="text-tomato small">密码必须为6位</span></span>
                  </label>
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text">
                        <span class="fa-layers fa-fw">
                          <i class="fad fa-key-skeleton text-dark-15"></i>
                          <i class="fad fa-redo-alt" data-fa-transform="shrink-6.5 right-8 down-3"></i>
                        </span>
                      </span>
                    </div>
                    <input type="password" class="form-control" placeholder="请输入新密码" />
                  </div>
                </div>
                <div class="form-group c_form_group">
                  <label>请确认新密码
                    <span class="float-right text-tomato"><i class="fad fa-exclamation-circle"></i>
                      <span class="text-tomato small">密码必须为6位</span></span>
                  </label>
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text">
                        <span class="fa-layers fa-fw">
                          <i class="fad fa-key-skeleton text-dark-15"></i>
                          <i class="fad fa-check" data-fa-transform="shrink-4 right-8 down-3"></i>
                        </span>
                      </span>
                    </div>
                    <input type="password" class="form-control" placeholder="请再次输入新密码" />
                  </div>
                </div>
              </div>
              <div class="tab-pane vivify fadeIn" id="Information">
                <h6><i class="fad fa-address-card"></i> 更改基本信息</h6>
                <form>
                  <div class="row">
                    <div class="col-md-4 col-sm-12">
                      <div class="form-group c_form_group">
                        <label>电站名称<span class="text-tomato">*</span></label>
                        <input class="form-control" type="text" value="渡边和也发电站" />
                      </div>
                    </div>
                    <div class="col-md-4 col-sm-12">
                      <div class="form-group c_form_group">
                        <label>联系人</label>
                        <input class="form-control" type="text" value="渡边和也" />
                      </div>
                    </div>
                    <div class="col-md-4 col-sm-12">
                      <div class="form-group c_form_group">
                        <label>联系人电话<span class="text-tomato">*</span></label>
                        <input class="form-control" type="text" value="13925698428" />
                      </div>
                    </div>
                    <div class="col-sm-12">
                      <div class="form-group c_form_group">
                        <label>地址</label>
                        <textarea class="form-control" placeholder="比企郡小川町大字青山字城北125" aria-label="With textarea"></textarea>
                      </div>
                    </div>
                    <div class="col-md-6 col-sm-12">
                      <div class="form-group c_form_group">
                        <label>邮箱<span class="text-tomato">*</span></label>
                        <div class="input-group">
                          <div class="input-group-prepend">
                            <span class="input-group-text"><i class="icon-envelope"></i></span>
                          </div>
                          <input type="text" class="form-control" value="MrKangaroo@example.com" />
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6 col-sm-12">
                      <div class="form-group c_form_group">
                        <label>网址</label>
                        <div class="input-group">
                          <div class="input-group-prepend">
                            <span class="input-group-text"><i class="icon-globe"></i></span>
                          </div>
                          <input type="text" class="form-control" placeholder="http://" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-6 col-md-6 col-lg-3">
                      <div class="form-group c_form_group">
                        <label>国家</label>
                        <select class="form-control">
                          <option value="JP">Japan</option>
                          <option value="CN">China</option>
                          <option value="SG">Singapore</option>
                        </select>
                      </div>
                    </div>
                    <div class="col-sm-6 col-md-6 col-lg-3">
                      <div class="form-group c_form_group">
                        <label>市</label>
                        <select class="form-control">
                          <option>1</option>
                          <option>2</option>
                          <option>3</option>
                        </select>
                      </div>
                    </div>
                    <div class="col-sm-6 col-md-6 col-lg-3">
                      <div class="form-group c_form_group">
                        <label>城市</label>
                        <input class="form-control" value="123" type="text" />
                      </div>
                    </div>
                    <div class="col-sm-6 col-md-6 col-lg-3">
                      <div class="form-group c_form_group">
                        <label>邮编</label>
                        <input class="form-control" value="91403" type="text" />
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-6">
                      <div class="form-group c_form_group">
                        <label>电话</label>
                        <input class="form-control" value="818-978-7102" type="text" />
                      </div>
                    </div>
                    <div class="col-sm-6">
                      <div class="form-group c_form_group">
                        <label>传真</label>
                        <input class="form-control" value="818-978-7102" type="text" />
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div> -->
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-default" data-dismiss="modal" @click="usersettingInfoHide">
              {{ $t("sidebar.close") }}
            </button>
            <button type="button" :disabled="
                errors.has('updateEmail.userEmail') ||
                  errors.has('updateEmail.userName') ||
                  errors.has('updateEmail.userTelephone')
              " @click="saveClick()" class="btn btn-primary theme-bg gradient">
              {{ $t("sidebar.save") }}
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- 电站绑定弹窗 -->
    <div id="registerPsInfo" class="modal fade bd-Register-modal-lg" tabindex="-1" role="dialog" data-backdrop="static" data-keyboard="false" aria-labelledby="myLargeModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title h4" id="myLargeModalLabel">
              <span class="fa-layers fa-fw fa-2x">
                <i class="fad fa-cube"></i>
                <i class="fad fa-plus-circle" data-fa-transform="shrink-7.5 right-10.5"></i>
              </span>
              <span>{{ $t("sidebar.bindPs") }}</span>
            </h5>
            <button type="button" class="close" data-dismiss="modal" @click="registerPsInfoHide" aria-label="Close">
              <span aria-hidden="true"><i class="fal fa-times"></i></span>
            </button>
          </div>
          <form>
            <div class="modal-body text-left">
              <el-alert v-if="bindError" :title="bindInfoMesg" type="success" show-icon :closable="false" class="mb-2" />
              <el-alert v-if="!bindError" :title="bindMes" type="error" show-icon :closable="false" class="mb-2" />
              <!-- <div v-if="bindError" class="alert alert-success" role="alert">
                <i class="fad fa-comment-smile"></i>
                <span>{{ bindInfoMesg }}</span>
              </div> -->
              <!-- <div v-if="!bindError" class="alert alert-danger" role="alert">
                <i class="fad fa-comment-exclamation"></i>
                <span>{{ bindMes }}</span>
              </div> -->
              <div id="UserName">
                <div class="form-group c_form_group">
                  <label>
                    {{ $t("sidebar.pmuSn") }}:
                    <span v-show="errors.has('pmuSn')" class="float-right text-tomato"><i class="fad fa-exclamation-circle"></i>
                      <span class="text-tomato small">
                        {{ errors.first("pmuSn") }}
                      </span></span>
                  </label>
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text"><i class="fad fa-barcode-scan text-dark-15"></i></span>
                    </div>
                    <input type="text" v-validate="'required|pmusn'" name="pmuSn" class="form-control email" v-model.trim="registerPsInfoForm.pmuSn" :placeholder="$t('sidebar.pleasePmuSn')" />
                  </div>
                </div>
              </div>
            </div>
          </form>
          <!-- :disabled="" -->
          <div class="modal-footer">
            <button type="button" class="btn btn-default" @click="registerPsInfoHide" v-if="bindCloseBtn" data-dismiss="modal">
              {{ $t("sidebar.close") }}
            </button>
            <button type="button" @click="bindPsInfo" class="btn btn-primary theme-bg gradient js-sweetalert" data-type="success">
              {{ $t("sidebar.bind") }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { setCookie, delCookie } from "../../utils/cookie";
export default {
  data () {

    return {
      isCollapse: false,

      passwordFlagSide: true,
      activeName: "UserName", //用户设置标签默认

      imageUrl: "", //头像上传
      registerPsInfoForm: {
        pmuSn: "",
        userId: window.sessionStorage.getItem("userId")
      },
      bindMes: "",
      menuList: [],
      /**菜单id对应的class样式图标  */
      menuIcon: {
        "21111288YT14KP94": "fad fa-home", //电站首页
        "2112314RMTX7WB41": "fad fa-charging-station m-l-0-5", //电站报表
        "2112313RMTX7WB41": "fad fa-analytics m-l-1", //设备数据分析
        "211112BD36S35D40": "fad fa-boxes-alt", //设备报表
        "2112117RMTX7WBC2": "fad fa-siren-on m-l--1-4", //告警历史
        "2133314RMTX7WB4E": "fad fa-list-alt m-l-0-5 m-r-1-7", //报表中心
        "2133314RMFX7WB6E": "fad fa-warehouse small m-l-0-5 m-r-1-7", //电站信息 
      },
      userInfo: {
        userName: ""
      },
      quickDeviceType: [],
      /**快捷设备类型对应的class样式图标 */
      quickIcon: {
        "1": "fad fa-archive", //PCS
        "3": "fad fa-car-battery", //蓄電池
        "2": "fad fad fa-tablet-rugged", //DCDC
        "5": "fad fad fa-tachometer-alt-fast", //电表
        "4": "fad fa-snow-blowing", //空调
        "6": "fad fa-fire-extinguisher", //消防
        "7": "fad fa-fire-extinguisher", //集装箱
        "8": "fad fa-archive", //PLC
      },
      isShow: true,
      bindCloseBtn: false,
      bindError: true,
      bindInfoMesg: "-",
      tabType: 0, //0 修改密码  1.修改邮箱  2.修改用户信息
      updatePsw: {
        newPassword: "",
        oldPassword: "",
        resetPsw: "",
        userId: window.sessionStorage.getItem("userId")
      },
      updateUserCode: {
        userName: "",
        userEmail: "",
        userTelephone: "",
        userId: window.sessionStorage.getItem("userId")

      },
      updateUserInfo: {
        userName: "",
        userEmail: "",
        userId: window.sessionStorage.getItem("userId")
      }
    };
  },
  watch: {
    bindShow: {
      handler (bindShow) {
        if (bindShow) {
          // $("#registerPsInfo").modal("show");
          // this.bindCloseBtn = false;
          // this.bindInfoMesg = this.$t("sidebar.bindTips");
          // $('#contactInfo').modal("show");
          // $('#usersettingInfo').modal("show");
        }
      }
    },
    '$store.state.psId': function (val) {
      //你需要执行的代码
      if (val == null || val == "-1") {
        this.bindCloseBtn = false;
        this.bindInfoMesg = this.$t("sidebar.bindTips");
        $("#registerPsInfo").modal("show");
      } else {
        $("#registerPsInfo").modal("hide");
      }
    },

    activeName: {
      handler (activeName) {
        switch (activeName) {
          case "UserName":
            //修改用户邮箱
            //还原用户邮箱
            this.updateUserCode.userEmail = this.userInfo.userEmail;
            this.updateUserCode.userName = this.userInfo.userName;
            break;
          case "userPassword":
            //修改密码
            break;
          case "UserInfo":
            //修改用户信息
            break;
          default:
            break;
        }
      }
    }
  },
  props: ["bindShow"],
  mounted () {
    this.getUserAccountInfo();
    this.getMenus();
    var _this = this;
    $(".sidebar-mini-btn").on("click", function () {
      $(".sidebar").toggleClass("sidebar-mini");
      $(".navbar-left").toggleClass("sidebar-mini-nav");
      $(".sidebar-mini").removeClass("open");
      if ($("#left-sidebar").hasClass("sidebar-mini")) {
        _this.$store.state.mainBig = true
      } else {
        _this.$store.state.mainBig = false
      }

      $("#navbar-content").toggleClass("nav-content-bigger");
      $("#main-content").toggleClass("main-content-bigger");
    });

    // if(this.$store.state.psId==null||this.$store.state.psId=='-1'){
    //   this.bindCloseBtn = false;
    //   this.bindInfoMesg = this.$t("sidebar.bindTips");
    //   $("#registerPsInfo").modal("show");
    // }else{
    //   $("#registerPsInfo").modal("hide");
    // }

  },
  methods: {
    toggleCollapse () {
      this.isCollapse = !this.isCollapse
    },

    handleOpen (key, keyPath) {
      console.log(key, keyPath);
    },
    handleClose (key, keyPath) {
      console.log(key, keyPath);
    },

    openQuick () {
      if (this.bindShow) {
        swal({
          title: this.$t("sidebar.psBind"),
          text: this.$t("sidebar.bindTips"),
          type: "error",
          confirmButtonText: this.$t("sidebar.msg.confirm")
        });

        return false;
      }
      $("#ps_st3").modal("show");
    },
    /** 上传头像 */
    handleAvatarSuccess (res, file) {
      this.imageUrl = URL.createObjectURL(file.raw);
    },
    beforeAvatarUpload (file) {
      const isJPG = file.type === "image/jpeg";
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPG) {
        this.$message.error("上传头像图片只能是 JPG 格式!");
      }
      if (!isLt2M) {
        this.$message.error("上传头像图片大小不能超过 2MB!");
      }
      return isJPG && isLt2M;
    },

    /** 权限管理跳转 */
    authorityInfoShow: function () {
      this.$router.push("/authority");
    },

    /** 登出 */
    signOut: function () {
      let sigoOutVue = this;
      this.$http
        .get(
          "/service-user/user/logout?userId=" +
          window.sessionStorage.getItem("userId"),
          {}
        )
        .then(function (ret) {
          let data = ret.data;
          if (data.status == 200) {
            window.sessionStorage.removeItem("token");
            window.sessionStorage.removeItem("userId");
            window.sessionStorage.removeItem("selPsId");
            delCookie("token");
            delCookie("userId");
            sigoOutVue.$router.push("/login");
          } else {
            swal({
              //提示的标题
              title: _this.$t("interfaceMsg." + res.status),
              //提示的文本
              text: _this.$t("sidebar.msg.return"),
              //类型：警告，还有success,error和info
              type: "error",
              //显示取消按钮
              showCancelButton: false,
              //确认按钮内文本
              confirmButtonText: _this.$t("sidebar.msg.confirm") //'确定'
            });
          }
        })
        .catch(function () { });
    },

    /**绑定电站 */
    registerPsInfoShow () {
      $("#registerPsInfo").modal("show");
      this.registerPsInfoForm.pmuSn = "";
      this.bindCloseBtn = true;
      this.bindError = true;
      this.bindInfoMesg = this.$t("sidebar.pleasePmuSnTit");
    },
    registerPsInfoHide () {
      $("#registerPsInfo").modal("hide"); //手动关闭verifyModal
      $(".modal-backdrop").remove();
    },
    /**用户快捷Email */
    contactInfoShow () {
      $("#contactInfo").modal("show");
    },
    /* contactInfoHide(){
      $("#contactInfo").modal('hide');  //手动关闭verifyModal
      $('.modal-backdrop').remove();
    }, */
    /**用户信息设置 */
    usersettingInfoShow () {
      //默認用户邮箱
      this.updateUserCode.userEmail = this.userInfo.userEmail;
      this.updateUserCode.userName = this.userInfo.userName;
      this.updateUserCode.userTelephone=this.userInfo.userTelephone;
      $("#usersettingInfo").modal("show");
    },
    usersettingInfoHide () {
      $("#usersettingInfo").modal("hide"); //手动关闭verifyModal
      $(".modal-backdrop").remove();
    },
    /**将快捷设备类型传给父组件 */
    setParentQuickDeviceType () {
      //子组件中添加自定义事件，父组件调用时在引用子组件时添加自定义方法，将快捷设备类
      this.$emit("quickDeviceType", this.quickDeviceType);
    },
    toggleSidebar (bool) { },
    /**选中点击设备类型图标时传入此设备类型id */
    setParent: function (deviceTypeId) {
      //子组件中添加自定义事件，父组件调用时在引用子组件时添加自定义方法
      this.$emit("changeParent", deviceTypeId);
    },
    loadingPsInfo () {
      this.$emit("loadingPsInfo");
    },

    //获取用户信息
    getUserAccountInfo () {
      let userVue = this;
      this.$http
        .get(
          "/service-user/user/getAccountInfo?userId=" +
          window.sessionStorage.getItem("userId"),
          {}
        )
        .then(function (ret) {
          let data = ret.data;
          if (data.status == 200) {
            userVue.userInfo = data.data;
            userVue.updateUserCode.userName = userVue.userInfo.userName;
            userVue.updateUserCode.userEmail = userVue.userInfo.userEmail;
            userVue.updateUserCode.userTelephone = userVue.userInfo.userTelephone;
          } else {
            swal({
              //提示的标题
              title: _this.$t("interfaceMsg." + res.status),
              //提示的文本
              text: _this.$t("sidebar.msg.return"),
              //类型：警告，还有success,error和info
              type: "error",
              //显示取消按钮
              showCancelButton: false,
              //确认按钮内文本
              confirmButtonText: _this.$t("sidebar.msg.confirm") //'确定'
            });
          }
        })
        .catch(function () { });
    },
    bindPsInfo () {
      var _this = this;
      try {
        _this.$validator.validateAll().then(async result => {
          if (result) {
            //http://192.168.3.158:7070/api/service-psinfo/psinfo/addUserPsRole?deviceId
            const { data: res } = await _this.$http.post(
              "/service-psinfo/psinfo/addUserPsRole?deviceId",
              _this.registerPsInfoForm
            );
            if (res.status == 200) {
              $("#registerPsInfo").modal("hide"); //手动关闭verifyModal
              $(".modal-backdrop").remove();
              _this.loadingPsInfo();
              _this.bindError = true;
              _this.registerPsInfoForm.pmuSn = "";

              swal({
                //提示的标题
                title: _this.$t("sidebar.msg.bindSuccess"), //"保存成功",
                //提示的文本
                text: _this.$t("sidebar.msg.bindTips"), //'',
                //类型：警告，还有success,error和info
                type: "success",
                //显示取消按钮
                showCancelButton: false,
                //确认按钮内文本
                confirmButtonText: _this.$t("sidebar.msg.confirm") //'确定'
              });
              _this.$router.go(0);//绑定成功刷新页面
            } else {
              _this.bindMes = _this.$t("interfaceMsg." + res.status);
              _this.bindError = false;
            }
          }
        });
      } catch (err) { }
    },

    /**获取快捷设备类型信息 */
    getQuickDeviceType () {
      let menuVue = this;
      let url = "";
      if (this.$store.state.psId != null && this.$store.state.psId != "")
        url =
          "/service-psinfo/psinfo/getDeviceTypeByPsId?psId=" +
          this.$store.state.psId;
      else
        url =
          "/service-psinfo/psinfo/getDeviceTypeByUserId?userId=" +
          window.sessionStorage.getItem("userId");
      this.$http
        .get(url, {})
        .then(function (ret) {
          let data = ret.data;
          if (data.status == 200) {
            menuVue.quickDeviceType = data.data;

            menuVue.setParentQuickDeviceType();
          } else {
            swal({
              //提示的标题
              title: _this.$t("interfaceMsg." + res.status),
              //提示的文本
              text: _this.$t("sidebar.msg.return"),
              //类型：警告，还有success,error和info
              type: "error",
              //显示取消按钮
              showCancelButton: false,
              //确认按钮内文本
              confirmButtonText: _this.$t("sidebar.msg.confirm") //'确定'
            });
          }
        })
        .catch(function () { });
    },
    /**获取用户菜单信息 */
    getMenus () {
      let menuVue = this;
      this.$http
        .get(
          "/service-user/menu/getMenusByRoleId?userId=" +
          window.sessionStorage.getItem("userId"),
          {}
        )
        .then(function (ret) {
          let data = ret.data;
          if (data.status == 200) {
            menuVue.menuList = data.data;
          } else {
            swal({
              //提示的标题
              title: _this.$t("interfaceMsg." + res.status),
              //提示的文本
              text: _this.$t("sidebar.msg.return"),
              //类型：警告，还有success,error和info
              type: "error",
              //显示取消按钮
              showCancelButton: false,
              //确认按钮内文本
              confirmButtonText: _this.$t("sidebar.msg.confirm") //'确定'
            });

            menuVue.isSubmit = false;
          }
        })
        .catch(function () {
          menuVue.isSubmit = false;
        });
    },
    //修改密码
    updatePassword () {
      var _this = this;
      try {
        this.$validator.validateAll("updatePsw").then(async result => {
          if (result) {
            const { data: res } = await this.$http.post(
              "/service-user/user/updatePwd",
              _this.updatePsw
            );
            if (res.status == 200) {
              swal({
                //提示的标题
                title: _this.$t("sidebar.msg.updateSuccess"), //"保存成功",
                //提示的文本
                text: "", //'',
                //类型：警告，还有success,error和info
                type: "success",
                //显示取消按钮
                showCancelButton: false,
                //确认按钮内文本
                confirmButtonText: _this.$t("sidebar.msg.confirm") //'确定'
              });
              $('#usersettingInfo').modal("hide");
            } else {
              swal({
                //提示的标题
                title: _this.$t("interfaceMsg." + res.status),
                //提示的文本
                text: _this.$t("sidebar.msg.updateFailure"),
                //类型：警告，还有success,error和info
                type: "error",
                //显示取消按钮
                showCancelButton: false,
                //确认按钮内文本
                confirmButtonText: _this.$t("sidebar.msg.confirm") //'确定'
              });
            }
          }
        });
      } catch (err) { }
    },
    //修改用户名和邮箱
    updateEmail () {
      var _this = this;
      try {
        this.$validator.validateAll("updateEmail").then(async result => {
          if (result) {
            const { data: res } = await this.$http.post(
              "/service-user/user/updateUserInfo",
              _this.updateUserCode
            );
            if (res.status == 200) {
              swal({
                //提示的标题
                title: _this.$t("sidebar.msg.updateSuccess"), //"保存成功",
                //提示的文本
                text: "", //'',
                //类型：警告，还有success,error和info
                type: "success",
                //显示取消按钮
                showCancelButton: false,
                //确认按钮内文本
                confirmButtonText: _this.$t("sidebar.msg.confirm") //'确定'
              });
              $('#usersettingInfo').modal("hide");
              this.getUserAccountInfo();
            } else {
              swal({
                //提示的标题
                title: _this.$t("interfaceMsg." + res.status), //'',
                //提示的文本
                text: _this.$t("sidebar.msg.updateFailure"),
                //类型：警告，还有success,error和info
                type: "error",
                //显示取消按钮
                showCancelButton: false,
                //确认按钮内文本
                confirmButtonText: _this.$t("sidebar.msg.confirm") //'确定'
              });
            }
          }
        });
      } catch (err) { }
    },
    //修改用户基础信息
    updateUserBase () { },
    //保存事件
    saveClick () {
      switch (this.activeName) {
        case "UserName":
          //修改用户邮箱
          this.updateEmail();
          break;
        case "userPassword":
          //修改密码
          this.updatePassword();
          break;
        case "UserInfo":
          this.getUserAccountInfo();
          //修改用户信息
          break;
        default:
          break;
      }
    },
    tabClick () {
      switch (this.activeName) {
        case "UserName":
          //修改用户邮箱
          //还原用户邮箱
          _this.updateUserCode.userEmail = _this.userInfo.userEmail;
          _this.updateUserCode.userName = _this.userInfo.userName;
          break;
        case "userPassword":
          //修改密码
          break;
        case "UserInfo":
          //修改用户信息
          break;
        default:
          break;
      }
    },
    passwordSeeSide () {
      var tempFlag = this.passwordFlagSide;
      this.passwordFlagSide = !tempFlag;
    }

  }
};
</script>

<style>
.el-menu__collapse-transition {
  transition: 0.3s height ease-in-out, 0.3s width ease-in-out,
    0.3s padding-top ease-in-out, 0.3s padding-bottom ease-in-out,
    0.3s padding-left ease-in-out, 0.3s padding-right ease-in-out,
    0.3s margin-top ease-in-out, 0.3s margin-bottom ease-in-out,
    0.3s margin-left ease-in-out, 0.3s margin-right ease-in-out !important;
}

.el-menu {
  padding: 0;
  width: 100% !important;
  border-right: 0 !important;
  background-color: rgba(24, 49, 83, 0) !important;
}
.el-menu-item {
  color: #bfffff !important;
  height: 2rem !important;
  line-height: 2rem !important;
}
.el-menu-item:hover {
  color: aqua !important;
  background-color: #112649 !important;
}

.el-menu-item:focus {
  background-color: #112649 !important;
}

.device-menu .el-menu-item {
  color: #9a9a9a !important;
}

.el-submenu .el-submenu__title {
  padding: 0;
  margin: 0;
  height: 2rem !important;
  line-height: 2rem !important;
}
.el-submenu .el-submenu__title:hover,
.el-submenu .el-submenu__title:focus {
  background-color: #0c1b34 !important;
}
.sidebar hr {
  margin-left: 1.25rem;
  margin-right: 1.25rem;
  border-top: 1px dotted var(--white-15);
}
.el-menu .el-menu-item,
.el-menu .el-submenu,
.el-menu .el-submenu .el-menu-item,
.el-menu--popup {
  min-width: 80px !important;
}

.el-menu:not(.el-menu--collapse) {
  width: 167px;
}
</style>
