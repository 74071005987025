<template>

  <div class="sixbuttons-group"
       :class="cls">

    <div class="ml-auto">
      <el-row>
        <!-- <el-button v-if="false"
                   type="aurora"
                   size="small"
                   :class="{'is-active':warningsAllType}"
                   @click="subWaningsKey"
                   :plain="!warningsAllType">全部报警</el-button> -->
        <template v-for="item in warningsKey">
          <el-tooltip :key="item.failureTypeId"
                      class="item"
                      :content="item.failureTypeName"
                      placement="bottom"
                      effect="dark">
                      <!-- :type="tomato" -->
            <el-button size="small"
                       icon="fad fa-tools"
                       :class="{'is-active':selFailureTypeId == item.failureTypeId}"
                       @click="subWaningsKey(item.failureTypeId)"
                       round><span class="ml-1">{{item.failureTypeName}}</span>
            </el-button>
          </el-tooltip>
        </template>
      </el-row>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      languageId: "2",
      selFailureTypeId:1,//默认选中项
      setFailureTypeId: 3,
      SirenTypeName: {
        "1": "Fault",//故障
      },
      SirenTypeIcon: {
        "1": "fad fa-tools",//故障
      },
      SirenTypeId: null,
      warningsKey: [],
    }
  },
  props: ['SelFailureTypeId','LanguageId', 'cls',],
  watch: {
    SelFailureTypeId: {
      handler (SelFailureTypeId) {
        this.selFailureTypeId = SelFailureTypeId
      }
    },
    LanguageId: {
      handler (LanguageId) {
        this.languageId = LanguageId;
        this.getFailureType();
      }
    }
  },
  created () {

  },
  methods: {
    subWaningsKey (failureTypeId) {
      this.selFailureTypeId = failureTypeId
      this.$emit("subWaningsKey", this.selFailureTypeId);
      //window.sessionStorage.setItem("warningsAllType",this.warningsAllType)
    },
    //报警类型列表
    getFailureType () {
      var _this = this;
      _this.$http.get('/service-base/failureType/getFailureTypes').then(function (ret) {
        if (ret.status == 200 && ret.data.data != null) {
          _this.warningsKey = _this.getFailureLanguage(ret.data.data)
        }
      })
    },
    getFailureLanguage (data) {
      let warningsKey = []
      for (let i = 0; i < data.length; i++) {
        if (data[i].failureTypeId <= this.setFailureTypeId) {//设置需要显示的报警类型列表
          let obj = {}
          obj["failureTypeId"] = data[i].failureTypeId;
          switch (this.languageId) {
            case 1:
              obj["failureTypeName"] = data[i].failureTypeNameCn;
              break;
            case 2:
              obj["failureTypeName"] = data[i].failureTypeNameJp;
              break;
            case 3:
              obj["failureTypeName"] = data[i].failureTypeNameEn;
              break;
          }
          warningsKey[i] = obj
        }
      }
      return warningsKey;
    },
  }


}
</script>

<style>
.sixbuttons-group {
  position: relative;
  bottom: -0.125rem;
}
</style>