<template>
  <div class="modal-content">
    <div class="modal-header">
      <h5 class="modal-title h4" id="myLargeModalLabel">
        <span class="fa-layers fa-fw fa-2x">
          <i :class="img"></i>
          <i class="fad fa-cog fa-spin" data-fa-transform="shrink-6 right-10"></i>
        </span>
        <span>{{ title }}
          <!-- 设置 -->
          {{ $t("visibleSetting.setUp") }}
        </span>
      </h5>
      <button type="button" class="close" data-dismiss="modal" aria-label="Close">
        <span aria-hidden="true">×</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="col-lg-12 col-md-12 text-center">
        <!-- 请至少选择{{ min }}个参数，至多选择{{ max }}个参数。 -->
        <el-alert :title='$t("visibleSetting.minGroup")+min+$t("visibleSetting.numParams")+max+$t("visibleSetting.numParams")' :type="messageState ? 'success' : 'error'" show-icon center :closable="false" class="mb-3" />

        <!-- <div class="alert " :class="messageState ? 'alert-success' : 'alert-danger'" role="alert">
          <i class="fad fa-comment-exclamation"></i>

          {{ $t("visibleSetting.minGroup") }}{{ min
          }}{{ $t("visibleSetting.numParams")
          }}{{ $t("visibleSetting.maxgroup") }}{{ max
          }}{{ $t("visibleSetting.numParams") }}
        </div> -->

        <label @click="checkClick($event)" class="fancy-checkbox" v-for="(value, key, index) in settingParams" :key="index">
          <input @change="changeClick" style="pointer-events:none" type="checkbox" name="checkbox" :value="key" v-model="checked" :disabled="checked.length > max - 1 && checked.indexOf(key) === -1" number />
          <span>{{ $t(keyName[key]) }}</span>
        </label>
      </div>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-default" @click="defaultSetting">
        <!-- 恢复默认设置 -->
        {{ $t("visibleSetting.recovery") }}
      </button>
      <button type="button" class="btn btn-primary theme-bg gradient js-sweetalert " data-dismiss="modal" data-type="success" @click="save()" :disabled="!btnState">
        <!-- 保存设置 -->
        {{ $t("visibleSetting.save") }}
      </button>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    title: String, //title标题
    params: Object, //params设置显示的参数
    keyName: Object, //对应的字段名
    min: Number, //最少显示
    max: Number, //最多显示
    img: String
  },
  data() {
    return {
      settingParams: {},
      checked: [],
      checkClass: "alert-success",
      messageState: true,
      btnState: true
    };
  },
  mounted() {
    this.settingParams = this.params;
    this.bindSetting();
  },
  methods: {
    //绑定默认值
    bindSetting() {
      var _this = this;
      _this.settingParams = _this.params;
      _this.checked = [];
      $.each(_this.settingParams, function(i, value) {
        if (value) {
          _this.checked.push(i);
        }
      });
    },
    //选中事件
    checkClick($event) {
      this.messageState = false;
    },
    changeClick() {
      if (this.checked.length <= this.max) {
        this.messageState = true;
      } else {
        this.messageState = false;
      }
    },
    //保存设置
    save() {
      var tempFlag = true;
      var _this = this;
      //最后选中的
      $.each(_this.settingParams, function(i, value) {
        _this.settingParams[i] = false;
        $.each(_this.checked, function(x, checked) {
          if (checked == i) {
            tempFlag = false;
            _this.settingParams[i] = true;
          }
        });
      });
      this.$emit("saveClick", this.settingParams);
    },
    //恢复默认值
    defaultSetting() {
      this.$emit("setDetault");
    }
  },
  watch: {
    params(newV, oldV) {
      this.bindSetting();
    },
    checked(newV, oldV) {
      if (newV.length < this.min || newV.length > this.max) {
        this.btnState = false;
        this.messageState = false;
      } else {
        this.btnState = true;
        this.messageState = true;
      }
    }
  }
};
</script>
<style scoped></style>
