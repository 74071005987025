<template>

  <div :class="cls" v-loading="loading">

    <el-select @change="changePsId" v-model="psName" :filterable="inInput" class="w-100 m-0">
      <span class="fa-layers fa-fw prefix-icon" slot="prefix">
        <i class="fad fa-search"></i>
        <i class="fad fa-house" data-fa-transform="shrink-11 left-1.5 up-1.3"></i>
      </span>

      <el-option v-if="allType" key="all" value="all" :label="$t('deviceTypeGroup.psall')"></el-option>
      <template v-for="(item) in psList">
        <el-option :key="item.psId" :value="item.psName">{{item.psName}}</el-option>
      </template>
    </el-select>

    <!-- <div class="input-group-prepend bg-white-nofont">
      <label class="input-group-text" for="inputGroupSelect01">
        <span class="fa-layers fa-fw">
          <i class="fad fa-search"></i>
          <i class="fad fa-house" data-fa-transform="shrink-11 left-1.5 up-1.3"></i>
        </span>
      </label>
    </div> -->
    <!-- <select class="custom-select bg-white-nofont" @change="changePsId" v-model="$store.state.psId">
      <template v-for="(item) in psList">
        <option :key="item.psId" :value="item.psId">{{item.psName}}</option>
      </template>
    </select> -->

  </div>
</template>
<script>


export default {
  name: "selectPsId",
  data () {
    return {
      loading: false,
      psIdStr: "",
      //选中的电站id
      psId: null,
      psName: null,
      //选中的电站 并网时间
      builddate: null,
      //电站列表
      psList: [],
      minDate: null,
      psListDateTime: null

    }
  },
  props: ['selectPsName','cls', 'allType', 'storeStatus', 'inInput'],
  // props:['psList','cls'],
  watch: {
    selectPsName: {
      handler (selectPsName) {
        this.psName = selectPsName;
      }
    }
  },
  mounted () {
      this.getPsInfos();
  },
  methods: {
    //电站切换
    changePsId () {
      // let builddate=null;
      if (this.psName == "all") {
        //psid所有
        this.psListDateTime = null;
        this.psIdStr = "";
        for (let i = 0; i < this.psList.length; i++) {
          if (this.psList[i]["psId"] != "") {
            this.psIdStr = this.psList[i]["psId"] + "," + this.psIdStr
          }
          //console.info(this.psList[i]["psBuilddate"])
          if (this.psList[i]["psBuilddate"] != null) {
            if (this.minDate == null) {
              this.minDate = this.psList[i]["psBuilddate"];
            }
            this.minDate = this.timeCompare(this.minDate, this.psList[i]["psBuilddate"]);
          }
        }
        if (this.allType) {
            window.sessionStorage.setItem("selPsId","")
        }
        //console.info("minDate", this.minDate)
        this.$emit("psIdChange", this.minDate, this.psIdStr, this.psName);
        return;
      }

      for (let i = 0; i < this.psList.length; i++) {
        if (this.psList[i]["psName"] == this.psName) {
          this.buildDate = (this.psList[i]["psBuilddate"].split(' '))[0];
          this.psName = this.psList[i]["psName"];
          this.psListDateTime = this.psList[i]["psLasttime"];
          this.$store.state.psId = this.psList[i]["psId"];
           this.$store.state.psTypeId = this.psList[i]["psTypeId"];
           //alert("2222"+this.$store.state.psId)
           window.sessionStorage.setItem("selPsId",this.$store.state.psId)
          break;
        }
      }
      // builddate=(builddate.split(' '))[0];
      // this.$emit("psIdChange", builddate,this.$store.state.psId);

      if (this.buildDate != null) {
        //let tempBuilddate=(this.buildDate.split(' '))[0];
        this.$emit("psIdChange", this.buildDate, this.$store.state.psId, this.psName,this.psListDateTime);
      }


    },

    //获取电站信息
  
    async getPsInfos () {//psList
      var _this = this;
      try {
        this.loading = true;
        const userId = window.sessionStorage.getItem("userId");
        //电站列表
        const { data: res } = await this.$http.get('/service-psinfo/psinfo/getPsInfoByUserId?userId=' + userId, {});
        if (res.status == 200) {
          //电站别称列表
           const { data: psres }  = await this.$http.get('/service-psinfo/psinfo/getRidesPsInfoAliasName?userId=' + userId, {});
          //console.info("psAliasNameList",psres.data)
          if(psres.data != null){
              for(let i = 0;i < res.data.length;i++){
                for(let j = 0;j < psres.data.length;j++){
                    if(res.data[i].psId == psres.data[j].psId && (psres.data[j].aliasName != "" && psres.data[j].aliasName != null)){
                      res.data[i].psName =  psres.data[j].aliasName
                    }
                }
              }
          }
          
          this.psList = res.data;
          if (!(typeof this.psList === "undefined") && this.psList.length > 0) {
            let selPsId =  window.sessionStorage.getItem("selPsId");
            if(selPsId != undefined && selPsId != null && selPsId != ""){
                this.psId = selPsId;
                for(var i=0;i<this.psList.length;i++){
                   if(selPsId == this.psList[i]["psId"]){
                         this.psName = this.psList[i]["psName"]
                         this.psListDateTime = this.psList[i]["psLasttime"];
                         if(this.psList[i]["psBuilddate"] != null){
                            this.buildDate = this.psList[i]["psBuilddate"];
                            this.buildDate = (this.buildDate.split(' '))[0];
                         }
                   }
                }
            }
            else{
                 this.psId = this.psList[0]["psId"];
                 this.psListDateTime = this.psList[0]["psLasttime"];
                 if (!_this.storeStatus) {
                  this.$store.state.psId = this.psId;
                }

                if (this.psList[0]["psBuilddate"] != null) {
                  this.psName = this.psList[0]["psName"]
                  this.buildDate = this.psList[0]["psBuilddate"];
                  this.buildDate = (this.buildDate.split(' '))[0];
                  if (!_this.storeStatus) {
                    this.$store.state.buildDate = this.buildDate;
                  }
                  if (this.allType) {
                    this.psName = "all";
                    this.psListDateTime = null;
                  }

                  
                }
               
            }
             
               this.loading = false;
            //this.getQuickDeviceType();
            //this.init();
            // this.$refs.groupDeviceType.psId=this.psId;
          }
          else {
            this.$store.state.psId ="-1";
            this.loading = false;
          }
          this.changePsId();

        }
        else {
          this.loading = false;
        }
      }
      catch (err) {
        this.loading = false;
      }

    },
    timeCompare (date1, date2) {
      var oDate1 = new Date(date1);
      var oDate2 = new Date(date2);
      if (oDate1.getTime() > oDate2.getTime()) {
        return date2;
        //console.log('第一个小');
      } else {
        //console.log('第二个小');
        return date1;
      }
    }
  }
}
</script>